<template>
  <div class="test-list__actions">
    <edit-test-button
      class="mr-half"
      :stream="stream"
      :group="group"
      :initial-test="test"
      :token="token"
      :tests="tests"
    />
    <delete-test-button
      class="mr-half"
      :stream="stream"
      :scheduled-test="test"
      :selected-test="findScheduledTest(tests, test)"
      :token="token"
    />
    <button class="button button--small button--primary button--cockpit button--cockpit-with-icon-only" @click="openScheduledTest">
      <i class="icon-share-2"></i>
    </button>
  </div>
</template>

<script>
import EditTestButton from './EditTestButton'
import DeleteTestButton from './DeleteTestButton'
import AbsoluteLinkService from '../AbsoluteLinkService'
import ContentPathResolver from '../ContentPathResolver'

export default {
  props: {
    stream: Object,
    group: Object,
    test: Object,
    token: String,
    tests: Array,
    streamCode: String,
    editionUrl: String
  },
  components: {
    EditTestButton,
    DeleteTestButton
  },
  methods: {
    findScheduledTest (availableTests, scheduledTest) {
      return availableTests.find(
        availableTest =>
          availableTest.contentPath.every(test => scheduledTest.contentPath.includes(test)) &&
          scheduledTest.contentPath.every(test => availableTest.contentPath.includes(test))
      )
    },
    openScheduledTest () {
      const absoluteLinkService = new AbsoluteLinkService(this.editionUrl)
      const contentPathResolver = new ContentPathResolver(this.test.contentPath)

      const sequenceLink = absoluteLinkService.editionLink(
        'edition_content_sequence_introduction',
        {
          streamCode: this.streamCode,
          contentPath: contentPathResolver.sequencePath()
        }
      )

      window.open(sequenceLink, '_blank')
    }
  }
}
</script>
