var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: _vm.classes,
      attrs: { "data-test": _vm.dataTest },
    },
    [
      _c(
        "div",
        {
          ref: "trigger",
          staticClass: "dropdown__trigger",
          on: { click: _vm.toggle },
        },
        [
          _vm.$slots["title"]
            ? [_vm._t("title")]
            : _c("span", {
                staticClass: "dropdown__trigger-label mr-1",
                domProps: { innerHTML: _vm._s(_vm.title) },
              }),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-tm-arrow-down dropdown__trigger-arrow",
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.open
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              ref: "dropdownMenu",
              staticClass: "dropdown__menu",
              attrs: { role: "menu", "body-scroll-lock-ignore": "" },
            },
            [
              _c(
                "div",
                { ref: "dropdownContent", staticClass: "dropdown__content" },
                [
                  _vm.$slots["content"]
                    ? [_vm._t("content")]
                    : _vm._l(_vm.options, function (option, index) {
                        return [
                          option.divider !== true
                            ? _c("a", {
                                staticClass: "dropdown__item",
                                class: {
                                  "dropdown__item--selected":
                                    _vm.isSelected(option),
                                },
                                attrs: {
                                  "data-test":
                                    _vm.dataTest + "-option-" + index,
                                },
                                domProps: {
                                  innerHTML: _vm._s(option[_vm.labelAttribute]),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectOption(option)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          option.divider === true
                            ? _c("div", { staticClass: "dropdown__divider" }, [
                                _c(
                                  "span",
                                  { staticClass: "dropdown__divider--label" },
                                  [
                                    option.icon
                                      ? _c("i", {
                                          staticClass: "mr-1",
                                          class: option.icon,
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(option[_vm.labelAttribute]) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }