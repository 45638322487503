<template>
  <div>
    <card
      :container-class="containerClass"
      :trigger-class="triggerClass"
      :completed="initiallyCompleted"
      :index="index"
      @click="onClick"
    >
      <slot name="card"></slot>
    </card>

    <modal
      ref="modal"
      v-if="opened"
      :title="title"
      :index="index"
      :meta="meta"
      :meta-leerjaar-label="metaLeerjaarLabel"
      :view-event-url="viewEventUrl"
      @close="close"
    >
      <slot name="content"></slot>
    </modal>
  </div>
</template>

<script>
import Card from '../card/Card'
import Modal from './Modal'

import mixins from '../../util/mixins'

const COMPLETE_ROUTE = 'cockpit_activity_complete'
const INCOMPLETE_ROUTE = 'cockpit_activity_incomplete'

export default {
  mixins: [mixins],
  data: function () {
    const url = window.location.href.split('?')[0]

    return {
      viewEventUrl: `${url}?viewCard=${this.contentPath}`,
      opened: false,
      added: this.initiallyAdded
    }
  },

  components: {
    Card,
    Modal
  },

  props: {
    href: String,
    isExternalLink: {
      type: Boolean,
      default: false
    },
    showAdditionalControls: {
      type: Boolean,
      default: false
    },
    contentPath: String,
    cleanupInstantly: Boolean,
    initiallyAdded: Boolean,
    initiallyCompleted: Boolean,
    token: String,
    title: String,
    image: String,
    index: String,
    containerClass: {
      type: String,
      default: ''
    },
    triggerClass: {
      type: String,
      default: ''
    },
    meta: Object,
    metaLeerjaarLabel: String,
    addedToMyActivitiesLabel: String,
    completedStatusLabelChecked: String,
    completedStatusLabelUnchecked: String
  },

  computed: {
    leerjaar () {
      if (this.meta === undefined) {
        return ''
      }
      return (Array.isArray(this.meta.leerjaar) && this.meta.leerjaar.length > 1) ? `${this.meta.leerjaar[0]} & ${this.meta.leerjaar[1]}` : this.meta.leerjaar[0]
    }
  },

  methods: {
    close () {
      this.opened = false
    },

    async onClick () {
      this.opened = true
    }
  }
}
</script>
