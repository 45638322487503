import RhvAdvice from './RhvAdvice'
import ScoreCategories from './ScoreCategories'

const SCORE_RHV_MAPPING = {
  'poor': 'R',
  'unsatisfactory': 'R',
  'good': 'H',
  'perfect': 'V'
}

export default class RhvAdvices {
  /**
   * @param {RhvAdvice[]} advices
   * @param {RhvAdvice} emptyAdvice
   * @param {ScoreCategories} scoreCategories
   */
  constructor(advices, emptyAdvice, scoreCategories) {
    this.advices = advices
    this.emptyAdvice = emptyAdvice
    this.scoreCategories = scoreCategories
  }

  getAdvices() {
    return this.advices
  }

  getAdvice(identifier) {
    if ('-' === identifier) {
      return this.emptyAdvice
    }
    return this.advices.find(advice => advice.identifier === identifier)
  }

  getAdvicesForLearningGoalType(type) {
    if ('repetition' === type) {
      return this.advices.filter(advice => 'R' === advice.identifier)
    }
    if ('prior-knowledge' === type) {
      return []
    }
    return this.advices
  }

  getAdviceForScoreAndLearningGoalType(score, type) {
    const advices = this.getAdvicesForLearningGoalType(type)
    const adviceIdentifier = SCORE_RHV_MAPPING[this.scoreCategories.getScoreCategory(score).name]
    const advice = advices.find(advice => advice.identifier === adviceIdentifier)
    return undefined === advice ? null : advice
  }

  static create(scoreCategories, translator) {
    const data = [
      {identifier: 'R', name: translator('cockpit.test.feedback.remediate')},
      {identifier: 'H', name: translator('cockpit.test.feedback.repeat')},
      {identifier: 'V', name: translator('cockpit.test.feedback.enrich')}
    ]

    return new RhvAdvices(
      data.map(advice => RhvAdvice.fromData(advice)),
      RhvAdvice.fromData({identifier: '-', name: ''}),
      scoreCategories
    )
  }
}
