<template>
  <div class="dropdown" :class="{ 'dropdown--open': open, 'dropdown--loading': loading, 'dropdown--disabled': disabled }">
    <div ref="trigger" class="dropdown__trigger" @click="toggle">
      <span class="dropdown__trigger-label mr-1" v-html="title"/>
      <i class="icon-tm-arrow-down dropdown__trigger-arrow"/>
    </div>
    <div ref="dropdownMenu" class="dropdown__menu" role="menu" v-if="open" v-on-clickaway="close" body-scroll-lock-ignore>
      <div ref="dropdownContent" class="dropdown__content">
        <template v-if="$slots['content']">
          <slot name="content"/>
        </template>
        <template v-else v-for="option in options">
          <!-- Divider -->
          <div v-if="option.divider" class="dropdown__divider">
            <span class="dropdown__divider--label">
              <i v-if="option.icon" :class="option.icon" class="mr-1"/>{{ option[labelAttribute] }}
            </span>
          </div>

          <!-- Anchor/link -->
          <a v-else
             class="dropdown__item"
             :class="{ 'dropdown__item--selected': keyAttribute ? option[keyAttribute] === value : option === value }"
             v-html="option[labelAttribute]"
             @click="selectOption(option)"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway'
  import { DropdownPopper } from './dropdown/DropdownPopper'

  export default {
    mixins: [clickaway],
    dropdownPopper: null,
    data: function () {
      return {
        open: false,
      }
    },
    props: {
      value: {},
      options: Array,
      disabled: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      labelAttribute: {
        type: String,
        default: 'name'
      },
      titleAttribute: {
        type: String,
        default: null
      },
      keyAttribute: {
        type: String,
        default: null
      },
    },
    created () {
      this.$options.dropdownPopper = new DropdownPopper()
    },
    beforeDestroy () {
      this.$options.dropdownPopper.destroy()
    },
    computed: {
      title () {
        if (this.titleAttribute) {
          return this.value[this.titleAttribute]
        }
        if (this.keyAttribute) {
          for (const option of this.options) {
            if (option === this.value) {
              return option[this.labelAttribute]
            }
          }
          return ''
        }

        return this.value ? this.value[this.labelAttribute] : ''
      }
    },
    methods: {
      close () {
        this.open = false
        this.$options.dropdownPopper.destroy()
      },
      toggle () {
        if (this.disabled) return;
        this.open = !this.open
        if (this.open) {
          this.createPopper()
          return
        }
        this.close()
      },
      selectOption (option, event) {
        this.$emit('input', option)
        this.open = false
      },
      async createPopper () {
        await this.$nextTick()
        this.$options.dropdownPopper.createPopper(
          this.$refs.dropdownContent,
          this.$refs.trigger,
          this.$refs.dropdownMenu
        )
      }
    }
  }
</script>
