'use strict'

import UserSettingService from './UserSettingService'
import Routing from '../../util/routing'

export default class TourSeenSetting {
  constructor() {
    this.setUrl = Routing.generate('user_setting_set_tour_seen')
    this.value = []
    this._token = null
  }

  init(value, token) {
    this.value = value
    this._token = token
  }

  persist() {
    UserSettingService.set(this.setUrl, {value: this.value.join(',')}, this._token)
  }

  isSeen(id) {
    return -1 !== this.value.indexOf(id) || this.allSeen()
  }

  allSeen() {
    return -1 !== this.value.indexOf('all')
  }

  setSeen(id) {
    const idx = this.value.indexOf(id)
    if (-1 === idx) {
      this.value.push(id)
      this.persist()
    }
  }

  unsetSeen(id) {
    const idx = this.value.indexOf(id)
    if (-1 !== idx) {
      this.value.splice(idx, 1)
      this.persist()
    }
  }

  setSeenAll() {
    this.setSeen('all')
  }

  unsetSeenAll() {
    this.unsetSeen('all')
  }
}
