<template>
  <div class="form-modal">
    <div class="form-modal__background" @click="close"></div>
    <div class="form-modal__container" data-test="test-form-modal">
      <button @click.prevent="close" class="form-modal__close"></button>
      <h2
        class="labeled-title form-modal__title"
        data-test="test-form-modal-title"
      >
        <span class="labeled-title__text">
          {{ $t(isNewTest ? 'cockpit.test.modal.title.assign' : 'cockpit.test.modal.title.edit') }}
        </span>
      </h2>
      <form>
        <div class="form-modal__errors" v-if="validationErrors.length > 0">
          <div class="notification notification--warning mb-1"
               v-for="validationError in validationErrors">
            <div class="notification__label"><i class="icon-alert"></i></div>
            <div class="notification__text">{{ validationError }}</div>
          </div>
        </div>
        <div class="form-modal__body">
          <div class="form-group form-group--readonly mb-3">
            <label class="form-group__label text-bold" for="group_id">{{ $t('generic.term.group') }}</label>
            <input id="group_id" type="text" class="form-group__field form-group__field--readonly" readonly
                   :value="group.name">
          </div>

          <div class="form-group mb-3">
            <label class="form-group__label text-bold">{{ $t('generic.term.test') }}</label>
            <select
              class="form-group__field form-group__field"
              v-model="test.contentPath"
              data-test="test-form-modal-test-dropdown"
            >
              <option v-for="availableTest in tests" v-bind:value="availableTest.contentPath">
                {{ availableTest.title }}
              </option>
            </select>
          </div>

          <div class="form-group form-group--readonly mb-0">
            <div class="form-group__field--nolabel">
              <div class="ml-1 form-group__userselect-all">
                <input
                  id="form-select-all-users"
                  type="checkbox"
                  :checked="test.users.length === group.members.length"
                  @click="toggleSelectAllUsers"
                  data-test="test-form-modal-select-all-users"
                />
                <label for="form-select-all-users">{{ $t('cockpit.test.modal.select_all_students') }}</label>
              </div>
            </div>
          </div>

          <div class="form-group mb-1">
            <label class="form-group__label text-bold">{{ $t('generic.term.students') }}</label>
            <div id="form-select-users" class="form-group__field form-group__userselect">
              <div v-for="user in group.members">
                <input
                  type="checkbox"
                  :id="`user_${user.userReference}`"
                  v-model="test.users"
                  :value="user.userReference" />
                <label :for="`user_${user.userReference}`">{{ user.fullName }}</label>
              </div>
            </div>
          </div>

          <div class="form-group form-group--readonly mb-3">
            <div class="form-group__field--nolabel">
              <span class="ml-1">{{ $t('cockpit.test.modal.students_selected', {count: test.users.length}) }}</span>
            </div>
          </div>

          <div class="form-group mb-3">
            <label class="form-group__label text-bold" for="start">{{ $t('cockpit.test.modal.start') }}</label>
            <date-time-input input-class="form-group__field" :position-top="true" v-model="test.start" id="start" />
          </div>

          <div class="form-group mb-3">
            <label class="form-group__label text-bold" for="end">{{ $t('cockpit.test.modal.end') }}</label>
            <date-time-input input-class="form-group__field" :position-top="true" v-model="test.end" id="end" />
          </div>

          <div class="form-modal__actions">
            <button
              @click.prevent="submitTest"
              type="submit"
              class="button button--cockpit button--primary"
              :disabled="loading"
              data-test="test-form-modal-button-submit"
            >
              {{ $t(isNewTest ? 'cockpit.test.modal.assign' : 'cockpit.test.modal.save') }}
            </button>
            <button
              @click.prevent="close"
              class="button button--link button--cockpit"
              data-test="test-form-modal-button-cancel"
            >
              {{
                $t('generic.term.cancel')
              }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import DateTimeInput from '../components/DateTimeInput'
import TestService from './TestService'
import {ACTION_EVENT, EventBus} from "../common/EventBus";
import {ACTION_TEST_ASSIGNED} from "../common/ThasActionEventActionType";

export default {
  components: {
    DateTimeInput
  },

  props: {
    initialTest: Object,
    testService: TestService,
    group: Object,
    tests: Array
  },

  data: function () {
    return {
      originalTest: this.initialTest,
      test: {
        stream: this.initialTest.stream,
        users: [...this.initialTest.users],
        contentPath: [...this.initialTest.contentPath],
        start: this.initialTest.start,
        end: this.initialTest.end,
      },
      validationErrors: [],
      loading: false
    }
  },

  computed: {
    valid() {
      return this.contentPathIsValid &&
        this.usersAreValid &&
        this.datesAreValid &&
        this.startDateIsBeforeEndDate &&
        this.endDateIsInTheFuture
    },
    contentPathIsValid() {
      return this.test.contentPath.length > 0
    },
    usersAreValid() {
      return this.test.users.length > 0
    },
    datesAreValid() {
      return dayjs(this.test.start).isValid() && dayjs(this.test.end).isValid()
    },
    startDateIsBeforeEndDate() {
      return dayjs(this.test.start).isBefore(dayjs(this.test.end))
    },
    endDateIsInTheFuture() {
      return dayjs(this.test.end).isAfter(dayjs())
    },
    isNewTest() {
      return this.originalTest.contentPath.length === 0
    },
  },

  methods: {
    toggleSelectAllUsers() {
      if (this.test.users.length === this.group.members.length) {
        this.test.users = []
      } else {
        this.group.members.forEach(user => {
          if (-1 === this.test.users.indexOf(user.userReference)) {
            this.test.users.push(user.userReference)
          }
        })
      }
    },

    close() {
      if (this.loading) {
        return
      }

      this.$emit('close')
    },

    async submitTest() {
      this.validationErrors = []
      if (!this.valid) {
        if (!this.contentPathIsValid) {
          this.validationErrors.push(this.$t('cockpit.test.error.select_test'))
        }
        if (!this.usersAreValid) {
          this.validationErrors.push(this.$t('cockpit.test.error.select_at_least_1_student'))
        }
        if (!this.datesAreValid) {
          this.validationErrors.push(this.$t('cockpit.test.error.invalid_dates'))
        } else if (!this.startDateIsBeforeEndDate) {
          this.validationErrors.push(this.$t('cockpit.test.error.start_past_end'))
        } else if (!this.endDateIsInTheFuture) {
          this.validationErrors.push(this.$t('cockpit.test.error.enddate_not_in_future'))
        }
        return
      }

      this.loading = true

      try {
        EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_TEST_ASSIGNED, {
          'stream': this.test.stream.code,
          'numberOfStudents': this.test.users.length,
          'testContentPath': this.test.contentPath,
          'start': this.test.start,
          'end': this.test.end
        })
        this.isNewTest ? await this.testService.assignTest(this.test) : await this.testService.updateTest(Object.assign({}, this.test, {original: this.originalTest}))
        document.location.reload()
      } catch (error) {
        this.loading = false

        if (error.response !== undefined && error.response.status === 400) {
          const errors = error.response.data.errors
          this.validationErrors = Object.keys(errors).map((key) => {
            return errors[key]
          })
        } else {
          console.warn(error)
        }
      }
    }
  }
}
</script>
