<template>
  <tr :data-test="'resend-form-row-'+componentIndex">
    <td>{{ test.title }}</td>
    <td>{{ test.resultCount }}</td>
    <td>
      <button
        class="button button--small button--primary button--cockpit"
        :class="{'is-loading': loading}"
        :disabled="disabled"
        @click="resend"
        :data-test="'resend-form-resend-button-'+componentIndex"
      >
        <i class="icon-send"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import {ACTION_EVENT, EventBus} from "../common/EventBus";
import {ACTION_DIGITAL_TEST_RESENT} from "../common/ThasActionEventActionType";

export default {
  props: {
    test: Object,
    testService: Object,
    componentIndex: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      loading: false,
      disabled: 0 === this.test.resultCount
    }
  },

  methods: {
    async resend() {
      EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_DIGITAL_TEST_RESENT, {
        'resultCount': this.test.resultCount,
        'testContentPath': this.test.contentPath,
        'title': this.test.title
      });
      this.loading = true

      try {
        await this.testService.resendResults(this.test.contentPath)
        this.disabled = true
      } catch (error) {
      }

      this.loading = false
    }
  }
}
</script>
