<template>
    <span class="date-time-input">
      <span v-if="pickDate" :class="{'mr-3': pickTime}">
        <datepicker
          :id="id"
          :input-class="dateInputClass"
          :calendar-class="calendarClass"
          format="dd-MM-yyyy"
          :disabled-dates="disabledDates"
          v-model="date"
          @input="onInput"
        ></datepicker>
      </span>
      <span v-if="pickTime">
        <input type="text" :id="id + '_time'" class="date-time-input__time" :class="inputClass" v-model="time" @input="onInput">
        <small>HH:MM</small>
      </span>
    </span>
</template>

<script>
  import dayjs from 'dayjs'
  import customParseFormat from 'dayjs/plugin/customParseFormat'
  import { formatDayjs } from '../util/date-format'
  import Datepicker from 'vuejs-datepicker'

  const DATE_FORMAT = 'DD-MM-YYYY'
  const TIME_FORMAT = 'HH:mm'
  const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT

  dayjs.extend(customParseFormat)

  export default {
    components: {Datepicker},
    data () {
      const value = dayjs(this.value)
      return {
        date: value.isValid() ? value.toDate() : new Date(),
        time: value.isValid() ? value.format(TIME_FORMAT) : '',
        disabledDates: this.allowFutureDates ? {} : {from: new Date()}
      }
    },

    props: {
      value: String,
      id: String,
      inputClass: String,
      positionTop: {
        type: Boolean,
        default: false
      },
      pickDate: {
        type: Boolean,
        default: true
      },
      pickTime: {
        type: Boolean,
        default: true
      },
      allowFutureDates: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      calendarClass () {
        return 'date-time-input__calendar' + (this.positionTop ? ' date-time-input__calendar--top ' : '')
      },
      dateInputClass () {
        return 'date-time-input__date ' + this.inputClass
      }
    },

    watch: {
      value (newValue) {
        const value = dayjs(this.value)
        if (value.isValid()) {
          this.date = value.toDate()
          this.time = value.format(TIME_FORMAT)
        }
      }
    },

    methods: {
      onInput (event) {
        const value = dayjs(this.date).format(DATE_FORMAT) + ' ' + this.time

        const dateTime = dayjs(value, DATE_TIME_FORMAT)
        if (dateTime.isValid() && value.length === DATE_TIME_FORMAT.length) {
          this.$emit('input', formatDayjs(dateTime))
          return
        }
        this.$emit('input', '')
      }
    }
  }
</script>
