<template>
  <div class="learn-lines pt-2">
    <header class="learn-lines__header">
      <dropdown
        class="dropdown--minimalistic dropdown--primary"
        :options="domains"
        :predefined-option="this.activeDomain"
        :trigger-only=false
        labelAttribute="title"
        :key="'activeDomain--' + (this.activeDomain ? this.activeDomain.id : '')"
        v-on:option-selected="setActiveDomain"
      ></dropdown>

      <dropdown
        class="dropdown--minimalistic dropdown--secondary"
        data-test="learn-lines-secondary-dropdown"
        :options="subDomains"
        :trigger-only=false
        :predefined-option="activeSubDomain"
        labelAttribute="title"
        :key="'activeSubDomain--' + (this.activeSubDomain ? this.activeSubDomain.id : '')"
        v-on:option-selected="setActiveSubdomain"
      ></dropdown>
    </header>
    <section class="learn-lines__content">
      <div class="learn-lines__blocks-grid">
        <div class="learn-lines__block-wrapper" v-for="block in blocks">
          <span class="learn-lines__block-title" v-html="block.aggregatedTitle"></span>
          <ul class="learn-lines__block-item">
            <li
              v-for="treeNode in blockLessons(lessons, block)"
              class="lesson-navigation__item learn-lines__lesson-item"
              :class="isActive(treeNode) ? 'lesson-navigation__item--active' : treeNode.lesson.completed ? 'lesson-navigation__item--completed' : ''"
            >
              <a
                :data-test="'learn-lines-block-item-' + treeNode.lesson.number"
                class="lesson-navigation__item-link lesson-navigation__item-link-day"
                @click="navigateToLesson(treeNode.lesson.contentPath)"
              >
                {{ treeNode.lesson.number }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import Dropdown from '../Dropdown.vue'
  import ContentPath from '../../util/ContentPath'
  import CockpitRouteService from '../../CockpitRouteService'

  function _getLessons (domain) {
    const nestedLessons = domain.children.map(x => _getLessons(x))
    return domain.viewModels.concat(nestedLessons.flat())
  }

  export default {
    created () {
      this.domains = this.learnLineRoot.children.map(child => child.children).flat()
      if (this.domains[0]) {
        this.setActiveDomain(this.domains[0])
      }
    },
    data () {
      return {
        domains: [],
        activeContentPath: new ContentPath(this.contentPath),
        activeDomain: null,
        activeSubDomain: null,
      }
    },
    computed: {
      subDomains () {
        return this.activeDomain ? this.activeDomain.children : []
      },
      lessons () {
        const treeLessons = this.activeSubDomain ? _getLessons(this.activeSubDomain) : []

        const filtered = []
        const contentPaths = {}

        treeLessons.forEach(treeNode => {
          const contentPath = new ContentPath(treeNode.lesson.contentPath).toString()
          if (contentPaths[contentPath] === true) {
            return
          }

          filtered.push(treeNode)
          contentPaths[contentPath] = true
        })

        return filtered.sort((a, b) => {
          if (parseInt(a.week.position) !== parseInt(b.week.position) && a.block.title === b.block.title) {
            return a.week.position - b.week.position
          }
          return a.lesson.number.localeCompare(b.lesson.number, 'nl-NL', {numeric: true})
        })
      },
      blocks () {
        return this.groupLessonsByBlocks(this.lessons)
      }
    },
    components: {
      Dropdown
    },
    props: {
      learnLineRoot: Object,
      contentPath: Array,
      blockTitle: String,
      routeService: CockpitRouteService
    },
    methods: {
      blockLessons (lessons, block) {
        return lessons.filter(treeNode => {
          return this.blockHasContentPath(treeNode.block, block.contentPath)
        })
      },
      isActive (treeNode) {
        return new ContentPath(treeNode.lesson.contentPath).equals(this.activeContentPath)
      },
      setActiveDomain (domain) {
        this.activeDomain = domain
        this.setActiveSubdomain(domain.children[0])
      },
      setActiveSubdomain (option) {
        this.activeSubDomain = option
      },
      blockHasContentPath(block, contentPath) {
        return contentPath.equals(new ContentPath(block.contentPath))
      },
      groupLessonsByBlocks (lessons) {
        if (!lessons) {
          return []
        }

        let blocks = []
        const contentPaths = {}
        lessons.forEach(lesson => {
          const contentPath = new ContentPath(lesson.block.contentPath)
          const contentPathKey = contentPath.toString()
          if (contentPaths[contentPathKey] === true) {
            return
          }
          contentPaths[contentPathKey] = true

          blocks.push({
            contentPath: contentPath,
            title: lesson.block.title,
            number: lesson.block.number,
            aggregatedTitle: lesson.block.aggregatedTitle
          })
        })

        blocks.sort((blockA, blockB) => {
          return blockA.aggregatedTitle.localeCompare(blockB.aggregatedTitle, 'nl', {numeric: true})
        })

        return blocks
      },
      navigateToLesson (contentPath) {
        document.location = this.routeService.lessonLink(new ContentPath(contentPath))
      }
    }
  }
</script>
