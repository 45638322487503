<template>
  <table class="table-rhv">
    <thead>
    <tr>
      <th></th>
      <th style="width:20%" v-for="level in systemLevels">{{ level }}</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(learningGoalAdvices, learningGoal) in rhvAdvices">
      <td>{{ learningGoalAdvices.title }}</td>
      <template v-for="(level, letter) in systemLevels">
        <td class="table-rhv__cell">
          <ul>
            <li v-for="userReferenceAndSource in learningGoalAdvices.advices[letter]" v-html="getFullName(userReferenceAndSource, learningGoal, learningGoalAdvices.advices)"></li>
          </ul>
          <p v-if="learningGoalAdvices.advices[letter]" class="table-rhv__user-count">{{ learningGoalAdvices.advices[letter].length }}x</p>
        </td>
      </template>
    </tr>
    </tbody>
  </table>
</template>

<script>
import LearningGoalTestResultsStore from '../result/LearningGoalTestResultsStore'

export default {
  props: {
    store: LearningGoalTestResultsStore
  },
  data () {
    let data = this.store.getRhvAdviceData()
    data.systemLevels = {
      R: this.$t('cockpit.test.feedback.remediate'),
      H: this.$t('cockpit.test.feedback.repeat'),
      V: this.$t('cockpit.test.feedback.enrich'),
    }

    return data
  },
  methods: {
    getFullName (userReferenceAndSource, learningGoal, advices) {
      const parts = userReferenceAndSource.split('|')
      const userReference = parts[0]
      const name = this.users[userReference].fullName
      const additions = []
      if ('any' !== parts[1] && '' !== parts[1]) {
        additions.push(parts[1])
      }
      if (this.getAdviceCount(advices, userReference, parts[1]) > 1) {
        additions.push(this.$t('cockpit.test.' + parts[2]))
      }
      if (additions.length > 0) {
        return name + ' (' + additions.join(', ') + ')'
      }
      return name
    },
    getAdviceCount (advices, userReference, referenceLevel) {
      let count = 0
      for (const keys of Object.values(advices)) {
        for (const key of keys) {
          const parts = key.split('|')
          if (userReference === parts[0] && referenceLevel === parts[1]) {
            count++
          }
        }
      }
      return count
    }
  }
}
</script>
