var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "has-loading-bar": _vm.isLoading } },
    [
      _vm.error ? _c("warning", { attrs: { message: _vm.error } }) : _vm._e(),
      _vm._v(" "),
      _vm.successMessage
        ? _c("success", { attrs: { message: _vm.successMessage } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "StickyTable",
        {
          staticClass: "table table--full-width cockpit-results--hover",
          attrs: { "sticky-heads": 1, "sticky-columns": _vm.stickyColumns },
        },
        [
          _c("thead", [
            _c(
              "tr",
              { staticClass: "cockpit-results--heading" },
              [
                _vm.streamHasAssignmentsExtra && _vm.hasAssignmentsExtra
                  ? _c("th", [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          disabled: _vm.myContentAllCheckboxDisabled,
                        },
                        domProps: { checked: _vm.myContentAllChecked },
                        on: { change: _vm.checkAllMyContent },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("th"),
                _vm._v(" "),
                _vm.streamHasAssignmentsExtra
                  ? _c("th", { staticClass: "whitespace-nowrap" }, [
                      _vm._v(
                        _vm._s(_vm.$t("cockpit.my_content.education_level"))
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.lessons, function (lesson) {
                  return _c(
                    "th",
                    { staticClass: "text-align-center whitespace-nowrap" },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("cockpit.lesson.title")) +
                          " " +
                          _vm._s(lesson.number) +
                          " " +
                          _vm._s(lesson.title)
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.streamHasAssignmentsExtra
                  ? _c(
                      "th",
                      { staticClass: "text-align-center whitespace-nowrap" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cockpit.my_content.total_my_content"))
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.results, function (result) {
              return _c("learning-goal-result", {
                key: result.student.userReference,
                attrs: {
                  result: result,
                  lessons: _vm.lessons,
                  "learning-goals": _vm.learningGoals,
                  "learning-goal-path":
                    _vm.learningGoalWithLessons.learningGoalPath,
                  "show-education-level-marker": _vm.showEducationLevelMarker,
                  "has-assignments-extra": _vm.hasAssignmentsExtra,
                  "stream-has-assignments-extra": _vm.streamHasAssignmentsExtra,
                  "assign-my-content-checked": _vm.assignMyContent[
                    result.student.userReference
                  ]
                    ? _vm.assignMyContent[result.student.userReference].checked
                    : false,
                  "assign-item-reference": _vm.assignMyContent[
                    result.student.userReference
                  ]
                    ? _vm.assignMyContent[result.student.userReference]
                        .itemReference
                    : null,
                  "number-of-open-my-content": _vm.myContentGroupedByUser[
                    result.student.userReference
                  ]
                    ? _vm.myContentGroupedByUser[result.student.userReference]
                        .numberOfOpenMyContent
                    : 0,
                  "assigned-content": _vm.myContentGroupedByUser[
                    result.student.userReference
                  ]
                    ? _vm.myContentGroupedByUser[result.student.userReference]
                        .contentReferences
                    : {},
                  "stream-code": _vm.streamCode,
                  "group-id": _vm.groupId,
                  "edition-url": _vm.editionUrl,
                },
                on: {
                  "education-level-selected": function ($event) {
                    return _vm.onEducationLevelSelected(result, $event)
                  },
                  "education-level-deselected": function ($event) {
                    return _vm.onEducationLevelDeselected(result)
                  },
                  "education-level-unchecked": function ($event) {
                    return _vm.onEducationLevelUnchecked(result)
                  },
                  "education-level-checked": function ($event) {
                    return _vm.onEducationLevelChecked(result)
                  },
                },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.streamHasAssignmentsExtra && _vm.hasAssignmentsExtra
        ? _c(
            "button",
            {
              staticClass: "button button--primary button--cockpit",
              attrs: { disabled: _vm.numberOfStudents === 0 || _vm.isLoading },
              on: { click: _vm.submitassignMyContent },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$transChoice(
                      "cockpit.my_content.assign_to_students",
                      this.numberOfStudents,
                      { numberOfStudents: this.numberOfStudents }
                    )
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }