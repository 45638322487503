var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        "show-close-button": true,
        "close-button-inside": true,
        title: _vm.$t("cockpit.test.feedback.overview"),
        index: 1,
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "print-content" },
        [
          _c("rhv-overview", {
            staticClass: "mb-3",
            attrs: { store: _vm.store },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flexbox flex" }, [
        _c("p", { staticClass: "flex-grow-1" }, [
          _vm._v(_vm._s(_vm.$t("cockpit.test.feedback.print_description"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("print-page-content-button", {
              staticClass: "button--small button--primary",
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }