/** Action property of Action type Event */
export const ACTION_EVENT_LESSON_CHECKED = 'LessonChecked'
export const ACTION_EVENT_LESSON_UNCHECKED = 'LessonUnchecked'

export const ACTION_ASSIGN_MY_CONTENT = 'AssignMyContent'

export const ACTION_TEST_ASSIGNED = 'TestAssigned'

export const ACTION_ACTIVITY_CHECKED = 'ActivityChecked';
export const ACTION_ACTIVITY_UNCHECKED = 'ActivityUnchecked'

export const ACTION_ACTIVITY_ADDED = 'ActivityAdded';
export const ACTION_ACTIVITY_REMOVED = 'ActivityRemoved'

export const ACTION_DIGITAL_TEST_RESENT = 'DigitalTestResent'
export const ACTION_FOLIO_TEST_RESENT = 'FolioTestResent'

export const ACTION_MY_CONTENT_ARCHIVED = 'MyContentArchived'
export const ACTION_MY_CONTENT_RESTORED = 'MyContentRestored'
export const ACTION_MY_CONTENT_DELETED = 'MyContentDeleted'
export const ACTION_MY_CONTENT_ASSIGNED = 'MyContentAssigned'
export const ACTION_MY_CONTENT_WITHDRAWN = 'MyContentWithdrawn'

