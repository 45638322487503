var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "advice-selector" }, [
    _c(
      "div",
      { staticClass: "button-group button-group--cockpit" },
      _vm._l(_vm.myOptions, function (option) {
        return _c("option-selector-button", {
          key: option.key,
          attrs: {
            option: option,
            options: _vm.myOptions,
            disabled: _vm.disabled,
            "button-style": _vm.buttonStyle,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.clickButton(option)
            },
          },
        })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "advice-selector-reset" }, [
      _vm.withReset && _vm.showReset
        ? _c(
            "button",
            {
              staticClass: "button button--tiny ml-1",
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.clickReset },
            },
            [_c("i", { staticClass: "icon-replay" })]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }