var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cockpit-results pr-4 w-100" },
    [
      _c(
        "StickyTable",
        {
          staticClass: "table table--full-width cockpit-results--hover",
          attrs: { "sticky-heads": _vm.stickyHeads, "sticky-columns": 2 },
        },
        [
          _c("thead", [
            _vm.store.hasMultipleTestsPerBlock()
              ? _c(
                  "tr",
                  {
                    staticClass:
                      "cockpit-results--heading-groups align-center vertical-align-middle",
                  },
                  [
                    _c("th"),
                    _vm._v(" "),
                    _c("th"),
                    _vm._v(" "),
                    _vm.educationLevelSelectorStore.hasEducationLevels()
                      ? [_c("th"), _vm._v(" "), _c("th")]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th"),
                    _vm._v(" "),
                    _vm._l(_vm.store.getBlockHeaders(), function (header) {
                      return [
                        header.hasDivider
                          ? _c("th", {
                              staticClass: "horizontal-divider--left",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        header.hasDivider
                          ? _c("th", {
                              staticClass: "horizontal-divider--right",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "cockpit-results--heading-group",
                            attrs: { colspan: header.span },
                          },
                          [
                            _c("span", {
                              class: header.classes,
                              domProps: { innerHTML: _vm._s(header.label) },
                            }),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              {
                staticClass:
                  "cockpit-results--heading align-center vertical-align-bottom",
              },
              [
                _vm.educationLevelSelectorStore.hasEducationLevels()
                  ? _c("th", { staticClass: "text-align-left" }, [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          disabled:
                            !_vm.educationLevelSelectorStore.hasEducationLevelChanges(),
                        },
                        domProps: {
                          checked:
                            _vm.educationLevelSelectorStore.allEducationLevelChangesChecked(),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.educationLevelSelectorStore.setAllStoreEducationLevel(
                              $event.target.checked
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("th"),
                _vm._v(" "),
                _vm.educationLevelSelectorStore.hasEducationLevels()
                  ? [
                      _c("th", {
                        staticClass: "whitespace-nowrap",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "cockpit.progress.results.current_education_level"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("th", {
                        staticClass: "whitespace-nowrap",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "cockpit.progress.results.new_education_level"
                            )
                          ),
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("th", {
                  staticClass: "whitespace-nowrap",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("cockpit.progress.results.last_two_block_average")
                    ),
                  },
                }),
                _vm._v(" "),
                _vm._l(_vm.store.getTestHeaders(), function (header) {
                  return [
                    header.hasDivider
                      ? _c("th", { staticClass: "horizontal-divider--left" })
                      : _vm._e(),
                    _vm._v(" "),
                    header.hasDivider
                      ? _c("th", { staticClass: "horizontal-divider--right" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th", {
                      class: header.classes,
                      domProps: { innerHTML: _vm._s(header.label) },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.users, function (user) {
                return [
                  _c("all-test-results-user", {
                    attrs: {
                      user: user,
                      store: _vm.store,
                      "education-level-selector-store":
                        _vm.educationLevelSelectorStore,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.educationLevelSelectorStore.hasEducationLevels()
        ? _c("button", {
            staticClass: "button button--primary button--cockpit",
            attrs: {
              disabled:
                _vm.educationLevelSelectorStore.isLoading ||
                0 ===
                  _vm.educationLevelSelectorStore.storeEducationLevelChangesCount(),
            },
            domProps: {
              innerHTML: _vm._s(
                _vm.$transChoice(
                  "cockpit.set_educationlevel_for_number_of_students",
                  _vm.educationLevelSelectorStore.storeEducationLevelChangesCount(),
                  {
                    numberOfStudents:
                      _vm.educationLevelSelectorStore.storeEducationLevelChangesCount(),
                  }
                )
              ),
            },
            on: {
              click: function ($event) {
                return _vm.educationLevelSelectorStore.saveEducationLevelChanges()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }