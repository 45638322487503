var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "has-loading-bar": _vm.isLoading } },
    [
      _vm.error ? _c("warning", { attrs: { message: _vm.error } }) : _vm._e(),
      _vm._v(" "),
      _vm.successMessage
        ? _c("success", { attrs: { message: _vm.successMessage } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "StickyTable",
        {
          staticClass: "table table--full-width cockpit-results--hover",
          attrs: { "sticky-heads": 2, "sticky-columns": 1 },
        },
        [
          _c("thead", [
            _c("tr", [
              _c("th", { staticClass: "no-border-right" }),
              _vm._v(" "),
              _c("th", { attrs: { colspan: "6" } }, [
                _c(
                  "div",
                  { staticClass: "cockpit-results--toggles mb-1" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "cockpit.my_content.show_archived_learninggoals"
                          )
                        ) +
                        "\n          "
                    ),
                    _c("toggle-button", {
                      attrs: {
                        id: "show-archived",
                        "label-on": _vm.$t("generic.term.yes"),
                        "label-off": _vm.$t("generic.term.no"),
                        checked: _vm.store.showArchived,
                      },
                      on: { change: _vm.toggleShowArchived },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "no-border-bottom" }),
            ]),
            _vm._v(" "),
            _c(
              "tr",
              { staticClass: "cockpit-results--heading vertical-align-bottom" },
              [
                _c("th"),
                _vm._v(" "),
                _c("th", {
                  staticStyle: { width: "100%" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("generic.term.extra_practice_with_goal")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("th", {
                  staticClass: "whitespace-nowrap text-align-center",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("cockpit.my_content.education_level")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("th", {
                  staticClass: "whitespace-nowrap text-align-center",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("generic.term.progress")),
                  },
                }),
                _vm._v(" "),
                _c("th", {
                  staticClass: "whitespace-nowrap text-align-center",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("generic.term.average")),
                  },
                }),
                _vm._v(" "),
                _c("th", {
                  staticClass: "whitespace-nowrap",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("cockpit.my_content.visible_for_student")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("th", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("generic.term.actions")),
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.store.filteredResults, function (result, resultIndex) {
                return [
                  _vm._l(result.itemResults, function (itemResult, index) {
                    return _c("my-content-result", {
                      key: itemResult.id,
                      attrs: {
                        user: result.user,
                        "first-row": index === 0,
                        "last-row": index === result.itemResults.length - 1,
                        "item-result": itemResult,
                        "my-content-token": _vm.myContentToken,
                        "stream-code": _vm.streamCode,
                        "edition-url": _vm.editionUrl,
                        result: result,
                        store: _vm.store,
                      },
                    })
                  }),
                  _vm._v(" "),
                  result.itemResults.length === 0
                    ? _c("assign-my-content", { attrs: { user: result.user } })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }