<template>
  <div>
    <button class="button button--small button--primary button--cockpit button--cockpit-with-icon-only" @click="showGrid">
      <i class="icon-plus"></i>
    </button>
    <enter-test-results-grid
      :group="group"
      v-if="showEntryGrid"
      v-on:close="showEntryGrid = false"
      :stream="stream"
      :token="token"
      :store="store"
    />
  </div>
</template>

<script>
import EnterTestResultsGrid from './EnterTestResultsGrid'
import FolioResultsStore from './folio-results/FolioResultsStore'

export default {
  components: { EnterTestResultsGrid },
  props: {
    group: Object,
    stream: Object,
    token: String,
    store: FolioResultsStore
  },
  data () {
    return {
      showEntryGrid: false
    }
  },
  methods: {
    showGrid () {
      this.showEntryGrid = true
    }
  }
}
</script>
