export default class ContentPathResolver {
  constructor (contentPath) {
    if (typeof contentPath === 'string' || contentPath instanceof String) {
      this._identifiers = contentPath.split('/')
    } else {
      this._identifiers = contentPath
    }
  }

  paragraphPath () {
    return this._until(2)
  }

  sequencePath () {
    return this._until(3)
  }

  itemId () {
    return this._position(2)
  }

  questionId () {
    return this._position(3)
  }

  _until(until) {
    if (this._identifiers.length < until) {
      return null
    }
    return this._identifiers.slice(0, until).join('/')
  }

  _position(index) {
    if (this._identifiers.length <= index) {
      return null
    }
    return this._identifiers[index]
  }
}
