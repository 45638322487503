export default class RhvAdvice {
  /**
   * @param {String} identifier
   * @param {String} name
   */
  constructor(identifier, name) {
    this.identifier = identifier
    this.name = name
  }

  equals(educationLevel) {
    return this.identifier === educationLevel.identifier
  }

  isEmpty() {
    return '-' === this.identifier || '' === this.identifier
  }

  static fromData(data) {
    return new RhvAdvice(data.identifier, data.name)
  }
}
