var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        ref: "button",
        staticClass: "button button--small button--primary button--cockpit",
        attrs: { disabled: _vm.isDisabled || _vm.isLoading },
        on: {
          click: [
            _vm.open,
            function ($event) {
              if (!$event.shiftKey) return null
              return _vm.openDebug.apply(null, arguments)
            },
          ],
        },
      },
      [_c("i", { staticClass: "icon-send" })]
    ),
    _vm._v(" "),
    _vm.showModal
      ? _c(
          "div",
          { staticClass: "form-modal", class: { "is-loading": _vm.isLoading } },
          [
            _c("div", {
              staticClass: "form-modal__background",
              on: { click: _vm.close },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-modal__container" }, [
              _c("button", {
                staticClass: "form-modal__close",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.close.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("h2", { staticClass: "labeled-title form-modal__title" }, [
                _c("span", { staticClass: "labeled-title__text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("cockpit.test.resend_folio_tests.modal.title")
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-modal__body" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "cockpit.test.resend_folio_tests.modal.description"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", [_vm._v(_vm._s(_vm.test.subject))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "cockpit.test.resend_folio_tests.modal.result_count"
                        )
                      ) +
                        ": " +
                        _vm._s(_vm.store.resultCount)
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.store.resultCountNoSubmittedAt > 0
                    ? _c(
                        "div",
                        { staticClass: "form-modal__select_date" },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$transChoice(
                                  "cockpit.test.resend_folio_tests.modal.missing_submitted_at",
                                  _vm.store.resultCountNoSubmittedAt,
                                  { count: _vm.store.resultCountNoSubmittedAt }
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("date-time-input", {
                            staticClass: "mt-1",
                            attrs: {
                              "input-class": "form-group__field",
                              "pick-time": false,
                              value: _vm.submittedAt,
                              id: "submitted_at",
                              "position-top": true,
                              "allow-future-dates": false,
                            },
                            on: { input: _vm.setSubmittedAt },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.resendFailed
                    ? _c("warning", {
                        attrs: {
                          message: _vm.$t(
                            "cockpit.test.resend_folio_tests.modal.failed"
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-modal__actions" }, [
                    _vm.showViewUwlr
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "button button--cockpit button--primary",
                            attrs: {
                              href: _vm.folioTestService.getViewUwlrUrl(
                                _vm.test.id
                              ),
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("cockpit.test.view_uwlr.button")
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button button--cockpit button--primary",
                        attrs: { type: "submit", disabled: _vm.isLoading },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.performReplay.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("cockpit.test.resend_folio_tests.button")
                          ) + "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button button--cockpit button--secondary button--cockpit-with-icon-only",
                        attrs: { disabled: _vm.isLoading },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.close.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("generic.term.close")) + "\n          "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }