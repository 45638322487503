<template>
  <tr v-if="user" class="cockpit-results--no-hover" :class="{'td--border--top': user}">
    <td class="whitespace-nowrap student-name">{{ user ? user.fullName : '' }}</td>
    <td colspan="6" class="">&nbsp;</td>
  </tr>
</template>

<script>
export default {
  props: {
    user: Object,
  },
}
</script>
