var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "cockpit-content__lesson-navigation lesson-navigation-wrapper",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container container-fluid lesson-navigation-wrapper__inner py-3",
        },
        [
          _c("week-overview-toggle"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "lesson-navigation__inner lesson-navigation__inner--collapsed",
            },
            [
              _c("active-week", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.CockpitStore.state.collapseNavigation,
                    expression: "CockpitStore.state.collapseNavigation",
                  },
                ],
                attrs: {
                  blocks: _vm.blocks,
                  "select-week": _vm.selectWeek,
                  "route-service": _vm.routeService,
                },
              }),
              _vm._v(" "),
              _c(
                "tabs",
                {
                  attrs: {
                    "page-wrapper-class":
                      "tab-pages--cockpit-content-lesson-navigation",
                    "tabs-additional-class": "tabs--cockpit",
                    bordered: true,
                    identifier: "level1",
                    "first-level-index": "0",
                  },
                },
                [
                  _c(
                    "tab",
                    {
                      attrs: {
                        name: _vm.$t(
                          "cockpit.lesson.navigation.tabs.tab1_title"
                        ),
                        active: true,
                        uid: 0,
                      },
                    },
                    [
                      _c("week-overview", {
                        attrs: {
                          blocks: _vm.blocks,
                          "select-week": _vm.selectWeek,
                          "route-service": _vm.routeService,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showLearnLines
                    ? _c(
                        "tab",
                        {
                          attrs: {
                            name: _vm.$t(
                              "cockpit.lesson.navigation.tabs.tab2_title"
                            ),
                            uid: 1,
                          },
                        },
                        [
                          _c("learn-lines", {
                            attrs: {
                              "learn-line-root": _vm.learnLineRoot,
                              "content-path": _vm.contentPath,
                              "route-service": _vm.routeService,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }