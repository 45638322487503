var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "tr",
        {
          staticClass: "cockpit-results--no-hover",
          class: { "td--border--top": _vm.user },
        },
        [
          _c("td", { staticClass: "whitespace-nowrap student-name" }, [
            _vm._v(_vm._s(_vm.user ? _vm.user.fullName : "")),
          ]),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "6" } }, [_vm._v(" ")]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }