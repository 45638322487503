import '@babel/polyfill'
import 'array-flat-polyfill'
import '../../style/style.scss'

import * as Sentry from "@sentry/vue";
import Vue from 'vue'
import Tabs from './components/tabs/Tabs.vue'
import Tab from './components/tabs/Tab.vue'
import GuidedTour from './components/tour/GuidedTour.vue'
import GroupDropdown from './components/GroupDropdown.vue'
import { setupContentElements } from './util/setup-content-elements'
import { elementReady } from './util/es6-element-ready'
import Dropdown from './components/Dropdown.vue'
import LessonNavigation from './components/LessonNavigation'
import LearnLinesNavigation from './components/learn-lines/LearnLinesNavigation'
import WeekOverviewToggle from './components/weekoverview/WeekOverviewToggle'
import WeekOverview from './components/weekoverview/WeekOverview'
import ActiveWeek from './components/weekoverview/ActiveWeek'
import LearnLines from './components/learn-lines/LearnLines'
import ActivityCardModal from './components/modal/ActivityCardModal'
import CardModal from './components/modal/CardModal'
import Checkmark from './components/Checkmark'
import ProgressBar from './components/ProgressBar'
import TestActionButtons from './test/TestActionButtons'
import FolioTestActions from './test/folio-results/FolioTestActions'
import ScheduledTestActionButtons from './test/ScheduledTestActionButtons'
import ResultGrid from './test/folio-results/ResultGrid'
import { store as CockpitStore } from './CockpitStore'
import NavbarManager from './components/Navbar.js'
import { ThasServiceFactory } from './thas/ThasService'
import setupMaintenanceAnnouncement from './util/setup-maintenance-announcement'
import LearningGoalsForBlockCard from './progress/LearningGoalsForBlockCard'
import LearningGoalsForBlockLink from './progress/LearningGoalsForBlockLink'
import LearningGoalsForBlockButton from './progress/LearningGoalsForBlockButton'
import LearningGoalResults from './progress/result/LearningGoalResults'
import LearningGoalSelection from "./progress/LearningGoalSelection";
import TestResults from './progress/result/TestResults'
import AllTestResults from './progress/result/AllTestResults'
import PriorKnowledgeResults from './progress/result/PriorKnowledgeResults'
import LearningGoalTestResults from './progress/result/LearningGoalTestResults'
import RhvPrintModal from './progress/rhv/RhvPrintModal'
import TreeSelect from './components/TreeSelect'
import MyContentResults from './progress/result/my-content/MyContentResults'
import DropdownGroupStream from './components/dropdown/DropdownGroupStream';

import VueTranslator from './common/VueTranslator'
import VueConfirmDialog from 'vue-confirm-dialog'
import { GoogleAnalyticsServiceFactory } from "./GoogleAnalyticsService";

require('./util/polyfills.js')

window.jQuery = window.$ = require('../../scripts/lib/jquery-2.1.1')

Vue.use(VueTranslator)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

if (window.sentryConf !== undefined && 'dev' !== window.sentryConf.environment) {
  Sentry.init({
    Vue,
    dsn: "https://ae07c6a2e94b40d9940d705511d1cfac@o1127103.ingest.sentry.io/4504791849107456",
    environment: window.sentryConf.environment,
    trackComponents: true,
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const cockpitApp = document.getElementById('cockpit-app')

  if (null === cockpitApp) {
    setupContentElements(document)
  } else {
    new Vue({ // eslint-disable-line no-new
      data: {
        sharedState: {
          cockpit: CockpitStore.state
        }
      },

      el: cockpitApp,

      created() {
        elementReady('.printable-content').then(() => {
          setTimeout(() => {
            window.print()
          }, 2000)
        })
      },
      mounted() {
        setupContentElements(document)
      },
      components: {
        GuidedTour,
        GroupDropdown,
        Dropdown,
        LessonNavigation,
        LearnLinesNavigation,
        WeekOverview,
        WeekOverviewToggle,
        ActiveWeek,
        ActivityCardModal,
        CardModal,
        LearnLines,
        Tabs,
        Tab,
        Checkmark,
        ProgressBar,
        TestActionButtons,
        ScheduledTestActionButtons,
        FolioTestActions,
        ResultGrid,
        LearningGoalsForBlockCard,
        LearningGoalsForBlockButton,
        LearningGoalsForBlockLink,
        LearningGoalResults,
        LearningGoalSelection,
        TestResults,
        AllTestResults,
        PriorKnowledgeResults,
        LearningGoalTestResults,
        RhvPrintModal,
        TreeSelect,
        MyContentResults,
        DropdownGroupStream,
        NavbarManager
      },

      methods: {
        onStreamSelected(selectedOption) {
          this.redirect(selectedOption)
        },
        onGroupSelected(selectedOption) {
          this.redirect(selectedOption)
        },
        redirect(selectedOption) {
          document.location = selectedOption.path
        }
      }
    })
  }

  if (window.thasConfiguration !== undefined) {
    ThasServiceFactory.createThasService(window.thasConfiguration)
  }

  document.addEventListener('DOMContentLoaded', function () {
    if (window.googleAnalyticsConfiguration !== undefined) {
      GoogleAnalyticsServiceFactory.createGoogleAnalyticsService(window.googleAnalyticsConfiguration)
    }
  })

  if (window.navbarManager === undefined) {
    window.navbarManager = new NavbarManager();
  }

  setupMaintenanceAnnouncement()
})
