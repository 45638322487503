<template>
  <div class="tour__shortcut-button icon-help" :style="buttonStyle" @click.prevent="click"></div>
</template>

<script>
  export default {
    data() {
      return {
        left: null,
        top: null
      }
    },
    props: {
      step: {
        type: Object,
        default: {}
      },
      currentId: null,
      disabled: false
    },
    methods: {
      click() {

        this.$emit('click-shortcut', this.step.id)
      },
      updatePosition() {
        if (null === this.element) {
          return '';
        }
        const rect = this.element.getBoundingClientRect()
        this.left = rect.left + (rect.width / 2) - 10
        this.top = rect.top + Math.min(100, rect.height / 2) - 10
      }
    },
    computed: {
      element() {
        if (null === this.step.attachTo) {
          return null;
        }
        if ('string' === typeof this.step.attachTo.element) {
          return document.querySelector(this.step.attachTo.element)
        }
        return this.step.attachTo.element
      },
      buttonStyle() {
        let style = 'left: ' + this.left + 'px; top: ' + this.top + 'px';
        style += (this.disabled || this.currentId === this.step.id ? ';display:none' : '');
        return style;
      }
    },
    mounted() {
      this.updatePosition()
      window.addEventListener('resize', this.updatePosition);
      document.body.appendChild(this.$el)
    },
    beforeDestroy() {
      if (this.$el.parentNode === document.body) {
        document.body.removeChild(this.$el)
      }
      window.removeEventListener('resize', this.updatePosition);
    }
  }
</script>
