<template>
  <table ref="table" :class="classes">
    <slot />
  </table>
</template>

<script>
export default {
  props: {
    stickyHeads: {
      type: Number,
      default: 1,
    },
    stickyColumns: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      // Observer to detect when the table is resized
      // to recalculate the sticky values
      observer: new ResizeObserver(this.initSticky),
    };
  },

  mounted() {
    this.observer.observe(this.$refs.table);
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  computed: {
    classes() {
      return {
        'sticky-table': true,
        [`sticky-table-heads-${this.stickyHeads}`]: this.stickyHeads > 0,
        [`sticky-table-columns-${this.stickyColumns}`]: this.stickyColumns > 0,
      };
    }
  },

  methods: {
    initSticky() {
      // Set top values for sticky heading rows
      this.setStickyStyleValues({
        total: this.stickyHeads,
        elementSelector: 'thead tr',
        styleProperty: 'top'
      });

      // Set left values for sticky columns
      this.setStickyStyleValues({
        total: this.stickyColumns,
        elementSelector: 'tr :is(th, td)',
        styleProperty: 'left'
      });
    },

    /**
     * Set the top or left values for the sticky elements.
     *
     * @param {Number} total Total number of sticky items
     * @param {String} elementSelector CSS selector for the elements to make sticky
     * @param {String} styleProperty CSS property to set the value on
     */
    setStickyStyleValues({ total, elementSelector, styleProperty }) {
      if (total < 2) return;

      for (let item = 2; item <= total; item++) {
        const elements = this.$refs.table.querySelectorAll(`${elementSelector}:nth-child(${item})`)
        elements.forEach(element => element.style[styleProperty] = this.calculateValue({ item, direction: styleProperty }));
      }
    },

    /**
     * Calculate the top or left value for a sticky element.
     * For heads, the value is the height of the previous thead rows.
     * For columns, the value is the width of the previous columns.
     *
     * @param {Number} item The item to calculate the value for
     * @param {String} direction Directional property to calculate the value for
     */
    calculateValue({ item, direction }) {
      let value = 0;
      for (let i = 1; i < item; i++) {
        const cell = this.$refs.table.querySelector(`thead tr th:nth-child(${i})`);
        value += direction === 'top' ? cell.offsetHeight : cell.offsetWidth;
      }
      return `${value}px`;
    },
  }
}
</script>
