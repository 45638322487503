var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("select-dropdown", {
    staticClass: "dropdown--minimalistic",
    attrs: {
      options: _vm.statusOptions,
      "key-attribute": "key",
      "label-attribute": "value",
      "title-attribute": "title",
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }