var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _vm.streamHasAssignmentsExtra && _vm.hasAssignmentsExtra
        ? _c("td", [
            _c("input", {
              attrs: {
                type: "checkbox",
                disabled: _vm.selectedEducationLevel === null,
              },
              domProps: { checked: _vm.assignMyContentChecked },
              on: { change: _vm.onCheckbox },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "whitespace-nowrap" }, [
        _vm._v(_vm._s(_vm.result.student.fullName)),
      ]),
      _vm._v(" "),
      _vm.streamHasAssignmentsExtra && _vm.hasAssignmentsExtra
        ? _c(
            "td",
            { staticClass: "cockpit-results__education-level-selector" },
            [
              _c("my-content-education-level-selector", {
                attrs: {
                  "education-levels": _vm.educationLevels,
                  "initial-education-level": _vm.initialEducationLevel,
                  "selected-education-level": _vm.selectedEducationLevel,
                  "with-reset": true,
                },
                on: {
                  "select-education-level": _vm.selectEducationLevel,
                  "reset-education-level": _vm.resetEducationLevel,
                },
              }),
            ],
            1
          )
        : _vm.streamHasAssignmentsExtra
        ? _c("td", [_c("i", [_vm._v("Geen extra oefening beschikbaar.")])])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.lessons, function (lesson) {
        return _c("td", [
          _c(
            "div",
            { staticClass: "flexbox flex-justify-content--center" },
            [
              _vm.shouldShowEducationLevelMarker(lesson)
                ? _c("span", { staticClass: "mr-half" }, [
                    _vm._v(_vm._s(_vm.getEducationLevelMarker(lesson))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("score", {
                attrs: {
                  score: _vm.getScore(lesson),
                  link: _vm.getLink(lesson),
                },
              }),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _vm.streamHasAssignmentsExtra
        ? _c("td", { staticClass: "text-align-center" }, [
            _vm._v(_vm._s(_vm.numberOfOpenMyContent)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }