<template>
  <tr :class="noBorder ? 'no-bottom-border' : ''">
    <td class="whitespace-nowrap" v-html="showUsername ? user.fullName : ''"></td>
    <td v-if="store.showEducationLevelColumn" v-html="store.getLevelsForTestType(user.userReference, type, referenceLevel).join(', ')"></td>
    <td :class="empty ? 'text-align-center' : ''" v-html="empty ? '-' : $t('cockpit.test.' + type)"></td>
    <td v-if="store.hasReferenceLevels" v-html="referenceLevel"></td>
    <template v-for="(learningGoal, index) in store.learningGoals">
      <template v-if="index > 0">
        <td class="horizontal-divider--left"></td>
        <td class="horizontal-divider--right"></td>
      </template>
      <td v-if="!empty && store.showRhv && hasPathScore(learningGoal.contentPath)" class="text-align-left">
        <option-selector
          v-if="pathsWithRhvAdvice.indexOf(learningGoal.contentPath) >= 0"
          :key="'rhv' + user.userReference + '-' + learningGoal.contentPath + '-' + type"
          :options="store.getRhvAdviceOptions(user.userReference, learningGoal, type, referenceLevel)"
          :can-deselect="true"
          :disabled="store.isLoadingFor(user.userReference, learningGoal.contentPath, type)"
          :context="{userReference: user.userReference, contentReference: store.contentReference, learningGoalId: learningGoal.contentPath, source: type, referenceLevel: referenceLevel}"
          @select="selectAdvice"
          @reset="resetAdvice"
        ></option-selector>
      </td>
      <td v-else-if="store.showRhv"></td>
      <td v-for="(score, testType) in store.getScoresForLearningGoalAndTestType(user.userReference, learningGoal, type, referenceLevel)">
        <score
          :key="'rhv' + user.userReference + '-' + learningGoal.contentPath + '-' + type + '-' + index"
          :score="score"
          :empty-value="store.getEmptyValue(score, user.userReference, learningGoal, type, testType, referenceLevel)"
          :link="store.getScoreLink(score, user.userReference, learningGoal, type, testType, referenceLevel)"
        />
      </td>
    </template>
  </tr>
</template>

<script>
import Score from '../../components/Score'
import OptionSelector from '../../components/OptionSelector'
import LearningGoalTestResultsStore from './LearningGoalTestResultsStore'

export default {
  components: {Score, OptionSelector},
  props: {
    user: Object,
    showUsername: Boolean,
    noBorder: Boolean,
    type: String,
    referenceLevel: String,
    store: LearningGoalTestResultsStore
  },
  data() {
    return {
      empty: '' === this.type,
      pathsWithScores: this.store.getPathsWithScores(this.user.userReference, this.type, this.referenceLevel, null),
      pathsWithRhvAdvice: this.store.getPathsWithScores(this.user.userReference, this.type, this.referenceLevel, 'exam')
    }
  },
  methods: {
    hasPathScore(path) {
      return this.pathsWithScores.indexOf(path) >= 0
    },
    async selectAdvice(context, option, options) {
      await this.store.updateAdvice(context, option, options)
      this.$emit('adviceUpdated')
    },
    async resetAdvice(context, option, options) {
      await this.store.updateAdvice(context, option, options)
      this.$emit('adviceUpdated')
    }
  }
}
</script>
