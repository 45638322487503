<template>
  <div>
    <button
      class="button button--small button--primary button--cockpit button--cockpit-with-icon-only"
      @click="editTest"
      data-test="edit-test-button"
    >
      <i class="icon-pencil"></i>
    </button>
    <test-form
      :initial-test="editableTest"
      :group="this.group"
      :test-service="this.testService"
      v-if="editableTest !== null"
      v-on:close="editableTest = null"
      :tests="tests"
    />
  </div>
</template>

<script>
import TestForm from './TestForm'
import TestService from './TestService'

export default {
  props: {
    group: Object,
    stream: Object,
    initialTest: Object,
    token: String,
    tests: Array,
  },
  components: {TestForm},
  data() {
    return {
      editing: false,
      test: Object.assign(this.initialTest, {
        stream: this.stream,
        start: this.initialTest.start,
        end: this.initialTest.end
      }),
      editableTest: null,
      testService: new TestService(
        this.stream.code,
        this.token
      ),
    }
  },
  methods: {
    editTest() {
      this.editableTest = Object.assign({}, this.test)
    }
  }
}
</script>

<style scoped>

</style>
