'use strict'

import request, { post } from '../../util/request'

export default class UserSettingService {
  static async get (url) {
    try {
      const response = await request(url)
      return response.data
    } catch (e) {
      throw new Error(e.response.data)
    }
  }

  static async set (url, values, token) {
    return post(url, Object.assign({ _token: token }, values))
  }
}
