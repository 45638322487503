import EducationLevel from './EducationLevel'

export default class UserEducationLevels {
  /**
   * @param {EducationLevel} defaultEducationLevel
   * @param {Object.<string, EducationLevel>} contentPathEducationLevels
   */
  constructor(defaultEducationLevel, contentPathEducationLevels) {
    this.defaultEducationLevel = defaultEducationLevel
    this.contentPathEducationLevels = contentPathEducationLevels
  }

  getEducationLevel(contentPath, returnDefault) {
    for (const educationLevelContentPath in this.contentPathEducationLevels) {
      if (0 === contentPath.indexOf(educationLevelContentPath)) {
        return this.contentPathEducationLevels[educationLevelContentPath]
      }
    }

    if (false === returnDefault) {
      return null
    }

    return this.defaultEducationLevel
  }

  static fromData(data) {
    return new UserEducationLevels(
      EducationLevel.fromData(data.defaultEducationLevel),
      Object.entries(data.educationLevels).reduce((carry, [contentPath, educationLevelData]) => {
        carry[contentPath] = EducationLevel.fromData(educationLevelData)
        return carry
      }, {})
    )
  }
}
