<template>
  <div>
    <div class="card topic__content" @click="open = true">
      <slot name="trigger"></slot>
    </div>
    <learning-goals-for-block-modal v-if="open" :stream-code="streamCode" :blocks="blocks" :block-content-path="blockContentPath" @close="open = false">
    </learning-goals-for-block-modal>
  </div>
</template>

<script>
import LearningGoalsForBlockModal from './LearningGoalsForBlockModal'
export default {
  components: { LearningGoalsForBlockModal },
  props: {
    streamCode: String,
    blockContentPath: String,
    blocks: Array
  },
  data() {
    return {
      open: false
    }
  }
}
</script>

