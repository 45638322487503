<template>
  <custom-button type="tertiary" v-bind="$props" @click="$emit('click', $event)"><slot v-if="$slots.default"/></custom-button>
</template>

<script>

import { default as CustomButton, CustomButtonProps } from './Button'

export default {
  components: {
    CustomButton
  },
  props: CustomButtonProps
}
</script>
