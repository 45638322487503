export default class EducationLevel {
  /**
   * @param {String} identifier
   * @param {String} name
   * @param {String} marker
   * @param {Number} order
   * @param {Number} scoreFrom
   * @param {Number} scoreTo
   */
  constructor(identifier, name, marker, order, scoreFrom, scoreTo) {
    this.identifier = identifier
    this.name = name
    this.marker = marker
    this.order = order
    this.scoreFrom = scoreFrom
    this.scoreTo = scoreTo
  }

  equals(educationLevel) {
    return undefined !== educationLevel && null !== educationLevel && this.identifier === educationLevel.identifier
  }

  includePriorKnowledge () {
    return 'Plus' === this.identifier
  }

  static fromData(data) {
    return new EducationLevel(data.identifier, data.name, data.marker, data.order, data.scoreFrom, data.scoreTo)
  }

  static createNone() {
    return new EducationLevel('no-education-level', 'No Education Level', '', 999, 0, 0)
  }
}
