var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "button button--small button--primary button--cockpit button--cockpit-with-icon-only",
          attrs: { "data-test": "edit-test-button" },
          on: { click: _vm.editTest },
        },
        [_c("i", { staticClass: "icon-pencil" })]
      ),
      _vm._v(" "),
      _vm.editableTest !== null
        ? _c("test-form", {
            attrs: {
              "initial-test": _vm.editableTest,
              group: this.group,
              "test-service": this.testService,
              tests: _vm.tests,
            },
            on: {
              close: function ($event) {
                _vm.editableTest = null
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }