var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", {
    staticClass:
      "button-group__button button-group__button--outline button button--tiny",
    class: _vm.buttonClass,
    domProps: { innerHTML: _vm._s(_vm.educationLevel.name) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }