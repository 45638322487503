'use strict'

import UsersEducationLevels from '../../util/UsersEducationLevels'

const NOW_DATE = (new Date()).toISOString().split('T')[0] + ' 00:00:00'

const _testWithoutPriorKnowledge = test => {
  let response = JSON.parse(JSON.stringify(test))

  response.questions = response.questions.reduce((carry, question) => {
    const value = _questionWithoutPriorKnowledge(question, test.learningGoals)
    if (null !== value) {
      carry.push(value)
    }
    return carry
  }, [])

  return response
}

const _questionWithoutPriorKnowledge = (question, learningGoals) => {
  if ('words' === question.entryType.type) {
    const orgWordCount = Object.keys(question.entryType.data.words).length

    question.entryType = _wordsEntryTypeWithoutPriorKnowledge(question.entryType, learningGoals)

    if (null !== question.entryType) {
      const newWordCount = Object.keys(question.entryType.data.words).length  - question.entryType.data.disabledWords.length
      if (newWordCount !== orgWordCount) {
        question.points = Math.round(question.points / orgWordCount * newWordCount)
        question.weight = Math.round(question.weight / orgWordCount * newWordCount)
      }
    }

    return question
  }

  if (0 === question.learningGoals.length) {
    return question
  }

  let learningGoalTypes = question.learningGoals.reduce((carry, learningGoalId) => {
    if (learningGoals.hasOwnProperty(learningGoalId)) {
      carry[learningGoals[learningGoalId].type] = learningGoals[learningGoalId].type
    }
    return carry
  }, {})

  learningGoalTypes = Object.keys(learningGoalTypes)
  if (1 === learningGoalTypes.length && 'prior-knowledge' === learningGoalTypes[0]) {
    question.entryType = null
  }

  return question
}

const _wordsEntryTypeWithoutPriorKnowledge = (entryType, learningGoals) => {
  entryType.data.disabledWords = []
  Object.entries(entryType.data.learningGoals).forEach(([wordKey, learningGoalId]) => {
    if (learningGoals.hasOwnProperty(learningGoalId) && 'prior-knowledge' === learningGoals[learningGoalId].type) {
      if (entryType.data.words.hasOwnProperty(wordKey)) {
        entryType.data.disabledWords.push(wordKey)
        //delete entryType.data.words[wordKey]
      }
    }
  })

  const newWordCount = Object.keys(entryType.data.words).length - entryType.data.disabledWords.length
  if (0 === newWordCount) {
    return null
  }

  return entryType
}

const _mapQuestions = questions => {
  return questions.reduce((carry, question) => {
    carry[question.id] = question
    return carry
  }, {})
}

const _mapSubmittedAt = results => {
  const values = results.reduce((carry, result) => {
    const userReference = result.userReference.reference
    if (!carry.hasOwnProperty(userReference) || null === carry[userReference]) {
      carry[userReference] = result.submittedAt
    }
    return carry
  }, {})

  return values
}

const _updateScoresFromPercentage = (results, questions) => {
  results.forEach(result => {
    if (null !== result.scorePercentage && questions.hasOwnProperty(result.questionId)) {
      const question = questions[result.questionId]
      result.score = Math.round(question.points / 100 * result.scorePercentage)
    }
  })
  return results
}

export default class FolioResultsStore {
  constructor (test, group, results, educationLevels, choiceTranslator) {
    this.loading = false
    this.test = test
    this.questions = _mapQuestions(this.test.questions)
    this.testWithoutPriorKnowledge = _testWithoutPriorKnowledge(this.test)
    this.questionCount = this.test.questions.length
    this.questionCountWithoutPriorKnowledge = this.testWithoutPriorKnowledge.questions.filter(question => null !== question.entryType).length
    this.group = group
    this.results = _updateScoresFromPercentage(results, this.questions)
    this.submittedAt = _mapSubmittedAt(this.results)
    this.submittedAtIsDefault = {}
    this.resultCount = 0
    this.resultCountNoSubmittedAt = 0
    this.resultCountWarning = ''
    this.educationLevels = UsersEducationLevels.fromData(educationLevels)
    this.userIncludePriorKnowledge = {}
    this.validResults = {}
    this.choiceTranslator = choiceTranslator
    this.defaultSubmittedAt = null
    this.updateSubmittedAt()
    this.initStored()
    this.updateResultCount()
  }

  getNowDate() {
    return NOW_DATE
  }

  getDefaultSubmittedAt() {
    if (null === this.defaultSubmittedAt) {
      return NOW_DATE
    }
    return this.defaultSubmittedAt
  }

  getUserIndex(userReference) {
    for (const index in this.group.members) {
      if (userReference === this.group.members[index].userReference) {
        return index
      }
    }
    return null
  }

  isDefaultSubmittedAt(userReference) {
    if (!this.submittedAtIsDefault.hasOwnProperty(userReference)) {
      return false
    }

    return this.submittedAtIsDefault[userReference]
  }

  getSubmittedAt(userReference) {
    if (!this.submittedAt.hasOwnProperty(userReference)) {
      return null
    }

    return this.submittedAt[userReference]
  }

  getStoredSubmittedAt(userReference) {
    if (!this.storedSubmittedAt.hasOwnProperty(userReference)) {
      return null
    }

    return this.storedSubmittedAt[userReference]
  }

  setSubmittedAt(userReference, submittedAt, isDefault) {
    this.defaultSubmittedAt = submittedAt
    this.submittedAt[userReference] = submittedAt
    this.submittedAtIsDefault[userReference] = true === isDefault
    this.updateSubmittedAt()
    this.setChanged(userReference, this.userResultsHaveChanges(userReference))
  }

  updateSubmittedAt() {
    for (const result of this.results) {
      result.submittedAt = this.getSubmittedAt(result.userReference.reference)
    }
  }

  setEmptySubmittedAt(submittedAt) {
    for (const result of this.results) {
      if (null === this.getStoredSubmittedAt(result.userReference.reference)) {
        result.submittedAt = submittedAt
        this.submittedAt[result.userReference.reference] = submittedAt
        this.storedSubmittedAt[result.userReference.reference] = submittedAt
      }
    }
  }

  getQuestionsForUser (user) {
    return this.getQuestionsForUserReference(user.userReference)
  }

  getQuestionsForUserReference (userReference) {
    if (this.includePriorKnowledge(userReference)) {
      return this.test.questions
    }

    return this.testWithoutPriorKnowledge.questions
  }

  updateEducationLevels(educationLevels) {
    this.educationLevels.update(educationLevels)
    Object.keys(educationLevels).forEach(userReference => {
      this.includePriorKnowledge(userReference, true)
    })
  }

  includePriorKnowledge (userReference, refresh) {
    if (!this.userIncludePriorKnowledge.hasOwnProperty(userReference) || true === refresh) {
      const userEducationLevel = this.educationLevels.getUserEducationLevel(userReference, this.test.contentPath)
      this.userIncludePriorKnowledge[userReference] = userEducationLevel.includePriorKnowledge()
    }
    return this.userIncludePriorKnowledge[userReference]
  }

  initStored() {
    this.changed = {}
    this.storedPoints = {}
    this.storedData = {}
    this.storedSubmittedAt = {}
    for (const result of this.results) {
      this.setStoredPoints(result.userReference.reference, result.questionId, result.score)
      this.setStoredData(result.userReference.reference, result.questionId, result.data)
      this.storedSubmittedAt[result.userReference.reference] = result.submittedAt
      this.setChanged(result.userReference.reference, false)
    }
  }

  hasStoredSubmittedAt (userReference) {
    if (this.storedSubmittedAt.hasOwnProperty(userReference)) {
      return null !== this.storedSubmittedAt[userReference]
    }
    return false
  }

  canSave (userReference) {
    return this.userResultsAreValid(userReference)
  }

  hasChanges (userReference) {
    return true === this.changed[userReference]
  }

  setChanged(userReference, changed) {
    this.changed[userReference] = changed
  }

  updateStored (userReference) {
    for (let result of this.getResultsForUserReference(userReference, false)) {
      this.setStored(result.userReference.reference, result.questionId, result.score, result.data, result.submittedAt)
    }
    this.setChanged(userReference, false)
    this.updateResultCount()
  }

  updateResultCount() {
    let count = 0
    let incomplete = 0
    let noSubmittedAt = 0

    this.group.members.forEach(user => {
      const icon = this.getStoredResultIconForUserReference(user.userReference)
      if ('check' === icon) {
        count++
        if (!this.hasStoredSubmittedAt(user.userReference)) {
          noSubmittedAt++
        }
      }
      if ('alert' === icon) {
        incomplete++
      }
    })

    this.resultCount = count
    this.resultCountNoSubmittedAt = noSubmittedAt
    this.resultCountWarning = this.getResultCountWarning(incomplete)
  }

  getResultCountWarning(incomplete) {
    if (incomplete > 0) {
      return this.choiceTranslator('cockpit.test.folio_result.incomplete_warning', incomplete, {count: incomplete})
    }
    return ''
  }

  setStored (userReference, questionId, points, data, submittedAt) {
    this.setStoredPoints(userReference, questionId, points)
    this.setStoredData(userReference, questionId, data)
    this.submittedAtIsDefault[userReference] = false
    this.storedSubmittedAt[userReference] = submittedAt
  }

  setStoredPoints (userReference, questionId, points) {
    if (!this.storedPoints.hasOwnProperty(userReference)) {
      this.storedPoints[userReference] = {}
    }
    if (null === points) {
      if (this.storedPoints[userReference].hasOwnProperty(questionId)) {
        delete (this.storedPoints[userReference][questionId])
      }
      return
    }
    this.storedPoints[userReference][questionId] = points
  }

  setStoredData (userReference, questionId, data) {
    if (!this.storedData.hasOwnProperty(userReference)) {
      this.storedData[userReference] = {}
    }
    this.storedData[userReference][questionId] = data
  }

  resultIsValid (questionId, userReference) {
    const key = questionId + '___' + userReference
    if (this.validResults.hasOwnProperty(key)) {
      return this.validResults[key]
    }
    return true
  }

  userResultsAreValid (userReference) {
    const keys = Object.keys(this.validResults).filter(key => {
      return (key.indexOf('___' + userReference) >= 0 && false === this.validResults[key])
    })
    return 0 === keys.length
  }

  updateScore (questionId, userReference, score, data) {
    for (let result of this.results) {
      if (result.questionId === questionId && result.userReference.reference === userReference) {

        result.score = score
        if (undefined !== data) {
          result.data = data
        }

        const points = this.getQuestionMaxPoints(questionId)

        /*
         * the score can be invalid if:
         * 1) if the score itself is 'null' because of deletion/reset
         * 2) if the score is not a number or the score is bigger than the points itself
         */
        const invalid = score !== null && (String(score).match(/^(|\d)+$/) === null || score > points)

        this.validResults[questionId + '___' + userReference] = !invalid

        this.results = [...this.results.filter(x => x !== null)]

        this.setChanged(userReference, this.userResultsHaveChanges(userReference))
        return
      }
    }

    // add a new result
    this.results = [...this.results, {
      questionId,
      testId: this.test.id,
      userReference: {reference: userReference},
      score,
      data: undefined === data ? null : data
    }]

    this.setChanged(userReference, true)
  }

  hasCompleteStoredResultForUserReference (userReference) {
    if (!this.storedPoints.hasOwnProperty(userReference)) {
      return false
    }
    const count = this.includePriorKnowledge(userReference) ? this.questionCount : this.questionCountWithoutPriorKnowledge
    return Object.keys(this.storedPoints[userReference]).length >= count
  }

  getStoredResultIconForUserReference (userReference) {
    if (!this.storedPoints.hasOwnProperty(userReference)) {
      return ''
    }

    const resultCount = Object.keys(this.storedPoints[userReference]).length
    if (0 === resultCount) {
      return ''
    }

    const count = this.includePriorKnowledge(userReference) ? this.questionCount : this.questionCountWithoutPriorKnowledge
    if (resultCount < count) {
      return 'alert'
    }

    return 'check'
  }

  getResultsForUserReference (userReference, forSave) {
    return this.results.filter(result => {
      if (result.userReference.reference === userReference) {
        if (forSave && !this.resultHasSubmittedAt(result)) {
          result.submittedAt = this.getSubmittedAt(result.userReference.reference)
        }
        return true
      }

      return false
    })
  }

  resultHasSubmittedAt (result) {
    return !(null === result.submittedAt || undefined === result.submittedAt || '' === result.submittedAt)
  }

  getUserReferencesWithResults () {
    const userReferences = []

    this.results.forEach(result => {
      if (userReferences.indexOf(result.userReference.reference) < 0) {
        userReferences.push(result.userReference.reference)
      }
    })

    return userReferences
  }

  resetResultDataForUserReference (userReference) {
    for (let result of this.results) {
      if (result.userReference.reference === userReference) {
        result.data = null
      }
    }

    this.results = [...this.results.filter(result => result.data !== null)]
    this.storedPoints[userReference] = {}

    delete this.submittedAt[userReference]
    delete this.submittedAtIsDefault[userReference]
    delete this.storedSubmittedAt[userReference]
  }

  userResultsHaveChanges (userReference) {
    if (this.submittedAt[userReference] !== this.storedSubmittedAt[userReference]) {
      return true
    }
    if (this.userScoresHaveChanges(userReference)) {
      return true
    }

    return false
  }

  userScoresHaveChanges(userReference) {
    for (let result of this.getResultsForUserReference(userReference, false)) {
      if (!this.storedPoints.hasOwnProperty(userReference) || !this.storedPoints[userReference].hasOwnProperty(result.questionId)) {
        return true
      }
      const storedScore = Number(this.storedPoints[userReference][result.questionId])

      if (storedScore !== Number(result.score)) {
        return true
      }
      if (this.dataHasChanges(this.storedData[userReference][result.questionId], result.data)) {
        return true
      }
    }
    return false
  }

  dataHasChanges (source, compare) {
    if (null === source || null === compare || source === undefined || compare === undefined) {
      return source !== compare
    }

    const sourceKeys = Object.keys(source)
    const compareKeys = Object.keys(compare)

    sourceKeys.sort()
    compareKeys.sort()

    if (sourceKeys.join('|') !== compareKeys.join('|')) {
      return true
    }

    for (const index in sourceKeys) {
      const key = sourceKeys[index]
      if (source[key] !== compare[key]) {
        return true
      }
    }

    return false
  }

  getStoredPoints (questionId, userReference) {
    if (this.storedPoints.hasOwnProperty(userReference) && this.storedPoints[userReference].hasOwnProperty(questionId)) {
      return this.storedPoints[userReference][questionId]
    }
    return null
  }

  getPoints (questionId, userReference) {
    const result = this.getResult(questionId, userReference)

    if (result !== undefined) {
      return result.score
    }

    return null
  }

  getResultData (questionId, userReference) {
    const result = this.getResult(questionId, userReference)

    if (result !== undefined) {
      return result.data
    }

    return null
  }

  getResult (questionId, userReference) {
    return this.results.find(obj => {
      return obj.questionId === questionId && obj.userReference.reference === userReference
    })
  }

  getQuestionMaxPoints (questionId) {
    for (let question of this.test.questions) {
      if (question.id === questionId) {
        return question.points
      }
    }
    return -1;
  }
}
