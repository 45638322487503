<template>
  <modal
    :show-close-button="true"
    :close-button-inside="true"
    @close="$emit('close')"
    :title="$t('cockpit.test.feedback.overview')"
    :index="1"
  >
    <div class="print-content">
      <rhv-overview class="mb-3" :store="store"></rhv-overview>
    </div>
    <div class="flexbox flex">
      <p class="flex-grow-1">{{ $t('cockpit.test.feedback.print_description') }}</p>
      <div><print-page-content-button class="button--small button--primary"></print-page-content-button></div>
    </div>
  </modal>
</template>

<script>
import RhvOverview from './RhvOverview'
import Modal from '../../components/modal/Modal'
import PrintPageContentButton from '../../components/PrintPageContentButton'
import LearningGoalTestResultsStore from '../result/LearningGoalTestResultsStore'

export default {
  components: { Modal, RhvOverview, PrintPageContentButton },
  props: {
    store: LearningGoalTestResultsStore
  }
}
</script>
