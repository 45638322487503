var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-modal", class: { "is-loading": _vm.loading } },
    [
      _c("div", {
        staticClass: "form-modal__background",
        on: { click: _vm.close },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-modal__container",
          attrs: { "data-test": "resend-form-modal" },
        },
        [
          _c("button", {
            staticClass: "form-modal__close",
            attrs: { "data-test": "resend-form-modal-close" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.close.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "h2",
            {
              staticClass: "labeled-title form-modal__title mb-2",
              attrs: { "data-test": "resend-form-modal-title" },
            },
            [
              _c("span", { staticClass: "labeled-title__text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("cockpit.test.resend_digital_tests.modal.title")
                  )
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mx-6" }, [
            _vm._v(
              _vm._s(
                _vm.$t("cockpit.test.resend_digital_tests.modal.description")
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-modal__body" }, [
            _vm.tests.length > 0
              ? _c(
                  "table",
                  { staticClass: "table table--cockpit table--full-width" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.tests, function (test, index) {
                        return _c("resend-test", {
                          key: test.contentPath[test.contentPath.length - 1],
                          attrs: {
                            test: test,
                            "test-service": _vm.testService,
                            "component-index": index,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-modal__actions" }, [
              _c(
                "button",
                {
                  staticClass: "button button--cockpit button--primary",
                  attrs: { type: "submit", disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("generic.term.close")) + "\n        ")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Toets")]),
        _vm._v(" "),
        _c("th", [_vm._v("Aantal ingeleverde toetsen")]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }