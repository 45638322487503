var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cockpit-results pr-4" },
    [
      _c(
        "div",
        { staticClass: "mb-3 d-flex between-3" },
        [
          _c("dropdown", {
            key: "dropdown-blocks",
            staticClass: "dropdown--blue mr-4",
            attrs: { options: _vm.blockOptions },
          }),
          _vm._v(" "),
          _vm.store.learningGoalTypes.length > 1
            ? _c("option-selector", {
                attrs: {
                  options: _vm.store.learningGoalTypes,
                  "can-deselect": false,
                  "with-reset": false,
                  "button-style": "button--small button--sans-serif",
                },
                on: { select: _vm.selectLearningGoalType },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "StickyTable",
        {
          staticClass: "table table--full-width cockpit-results--hover",
          attrs: {
            "sticky-heads": 2,
            "sticky-columns": _vm.store.stickyColumns,
            "data-test": "learning-goal-test-results-table",
          },
        },
        [
          _c("thead", [
            _c(
              "tr",
              {
                staticClass:
                  "cockpit-results--heading align-center vertical-align-top",
              },
              [
                _c("th"),
                _vm._v(" "),
                _vm.store.showEducationLevelColumn ? _c("th") : _vm._e(),
                _vm._v(" "),
                _c("th"),
                _vm._v(" "),
                _vm.store.hasReferenceLevels ? _c("th") : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.store.getGroupHeaders(), function (header, index) {
                  return [
                    index > 0
                      ? [
                          _c("th", { staticClass: "horizontal-divider--left" }),
                          _vm._v(" "),
                          _c("th", {
                            staticClass: "horizontal-divider--right",
                          }),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th", {
                      attrs: { colspan: header.span },
                      domProps: { innerHTML: _vm._s(header.label) },
                    }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "tr",
              {
                staticClass:
                  "cockpit-results--heading align-center vertical-align-middle",
              },
              [
                _c("th"),
                _vm._v(" "),
                _vm.store.showEducationLevelColumn
                  ? _c("th", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("generic.term.testlevel")),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("th", {
                  staticClass: "text-align-left",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("generic.term.source")),
                  },
                }),
                _vm._v(" "),
                _vm.store.hasReferenceLevels
                  ? _c("th", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("generic.term.reference_level")
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.store.getHeaders(), function (header) {
                  return _c("th", {
                    class: header.class,
                    domProps: { innerHTML: _vm._s(header.label) },
                  })
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.users, function (user) {
                return [
                  _vm.store.userHasResults(user.userReference)
                    ? _vm._l(
                        _vm.store.getResultTypes(user.userReference),
                        function (resultType, index) {
                          return _c("learning-goal-test-results-user", {
                            key:
                              user.userReference +
                              "-" +
                              resultType.digitalOrFolio +
                              "-" +
                              resultType.referenceLevel,
                            attrs: {
                              user: user,
                              "show-username": 0 === index,
                              "no-border":
                                0 === index &&
                                _vm.store.getResultTypes(user.userReference)
                                  .length > 1,
                              type: resultType.digitalOrFolio,
                              store: _vm.store,
                              "reference-level": resultType.referenceLevel,
                            },
                            on: {
                              adviceUpdated: _vm.updatePrintSheetRhvAdvices,
                            },
                          })
                        }
                      )
                    : _c("learning-goal-test-results-user", {
                        key: user.userReference + "-no-result",
                        attrs: {
                          user: user,
                          "show-username": true,
                          "no-border": false,
                          type: "",
                          store: _vm.store,
                        },
                      }),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.store.showRhv
        ? _c("div", { staticClass: "button-container" }, [
            _c(
              "a",
              {
                staticClass:
                  "button button--small button--primary button--cockpit",
                class: {
                  "button--disabled": !_vm.store.showRhv || !_vm.hasRhvAdvices,
                },
                attrs: {
                  href: "#",
                  disabled: !_vm.store.showRhv || !_vm.hasRhvAdvices,
                  "data-test":
                    "learning-goal-test-results-print-overview-button",
                },
                on: {
                  click: function ($event) {
                    _vm.showPrintModal = true
                  },
                },
              },
              [
                _c("i", { staticClass: "icon-printer" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("cockpit.test.feedback.overview")) +
                    "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "button-with-message-container" }, [
              _c(
                "form",
                {
                  attrs: {
                    method: "POST",
                    target: "_blank",
                    action: _vm.store.getPrintRhvSheetsUrl(),
                  },
                },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: "data" },
                    domProps: {
                      value: JSON.stringify(_vm.printSheetRhvAdvices),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "button button--cockpit button--small button--primary",
                      class: { "button--disabled": "" !== _vm.disabledReason },
                      attrs: {
                        type: "submit",
                        disabled: "" !== _vm.disabledReason,
                        "data-test":
                          "learning-goal-test-results-print-sheets-button",
                      },
                    },
                    [
                      _c("span", [
                        _c("i", { staticClass: "icon-printer" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("cockpit.test.feedback.print_sheets"))
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              "" !== _vm.disabledReason
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(
                          "cockpit.test.feedback.print_sheets_disabled_" +
                            _vm.disabledReason
                        )
                      ),
                    },
                  })
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.store.rhvEnabled && _vm.showPrintModal
        ? _c("rhv-print-modal", {
            attrs: { store: _vm.store },
            on: {
              close: function ($event) {
                _vm.showPrintModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }