import Routing from './util/routing'

const SECURE_D_SUFFIX = '/secure/d'

export default class AbsoluteLinkService {
  constructor (editionUrl) {
    this._editionUrl = editionUrl
  }

  editionLink (routeName, parameters) {
    return this._editionUrl + SECURE_D_SUFFIX + Routing.generate(routeName, parameters)
  }
}
