const COMPACT_ROUTE_MIN_SCORE = 90

export default class CompactRouteAdvice {
  /**
   * @param {String} identifier
   * @param {String} name
   */
  constructor(identifier, name) {
    this.identifier = identifier
    this.name = name
  }

  static forScores(scores, translator) {
    if (0 === scores.length) {
      return new CompactRouteAdvice('none', '')
    }

    for (const score of scores) {
      if (score < COMPACT_ROUTE_MIN_SCORE) {
        return new CompactRouteAdvice('none', '')
      }
    }

    return new CompactRouteAdvice('compact', translator('cockpit.progress.results.compact_route'))
  }
}
