<template>
  <div class="form-modal form-modal--enter-test-results">
    <div class="form-modal__background" @click="close"></div>

    <div class="form-modal__container form-modal__container--wider" :class="{'has-loading-bar': store.loading}">

      <button @click.prevent="close" class="form-modal__close"></button>

      <h2 class="labeled-title form-modal__title form-modal__title--full-width mb-2">
        <span class="labeled-title__text">{{ store.test.subject }}</span>
      </h2>

      <p>Geef in dit overzicht per opgave aan wat de leerling goed gedaan heeft.<br />Voor leerlingen die de toets digitaal hebben gemaakt hoef je hier niets te doen.</p>

      <result-entry-grid
        :store="store"
        :stream="stream"
        :token="token"
        :group="group"
      />

    </div>
  </div>
</template>

<script>

import ResultEntryGrid from './ResultEntryGrid'
import FolioResultsStore from './folio-results/FolioResultsStore'

export default {
  components: { ResultEntryGrid },
  props: {
    group: Object,
    stream: Object,
    token: String,
    store: FolioResultsStore
  },

  data: function () {
    return {
      users: this.group.members
     }
  },

  methods: {
    close () {
      if (this.loading) {
        return
      }
      this.$emit('close')
    },
  }
}
</script>
