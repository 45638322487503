<template>
  <a v-if="steps.length > 0 && !disabled" @click.prevent="click">
    <span>
      <span class="icon-help"></span>
      <shortcut-button
        v-for="step in steps"
        v-if="visible && !disabled && null !== step.attachTo"
        v-bind:disabled="disabled"
        v-bind:currentId="currentId"
        v-bind:key="step.id"
        v-bind:step="step"
        @click-shortcut="clickShortcut"
      ></shortcut-button>
    </span>
    <media-modal v-if="vmsId" :vms-id="vmsId" :redesign="redesign" @close="continueAfterVideo"></media-modal>
  </a>
</template>

<script>
  import Shepherd from 'shepherd.js';
  import Breakpoints from '../../util/breakpoints';
  import MediaModal from '../modal/MediaModal';
  import TourSeenSetting from '../settings/TourSeenSetting'
  import ShortcutButton from './ShortcutButton'

  const SeenSetting = new TourSeenSetting()
  const defaultTourData = {
    priority: 999999,
    target: null,
    title: '',
    position: 'bottom',
    class: '',
    vmsId: null,
    videoLink: null
  }

  export default {
    components: {
      MediaModal,
      ShortcutButton
    },

    tour: null,
    firstUnseen: null,
    terms: {},
    data() {
      return {
        hasSteps: false,
        visible: false,
        disabled: false,
        currentId: null,
        steps: [],
        handleEvents: true,
        vmsId: null
      }
    },
    props: {
      autoStart: Boolean,
      seenSettings: Array,
      seenSettingsToken: String,
      redesign: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      visible: function (visible) {
        if(!visible) {
          this.$options.tour.hide()
        }
      }
    },

    mounted() {
      window.addEventListener('resize', this.onResize)
      this.onResize()

      SeenSetting.init(this.seenSettings, this.seenSettingsToken)

      this.steps = this.getSteps();
      if (this.autoStart && null !== this.$options.firstUnseen) {
        this.start(false)
      }
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },

    methods: {
      click() {
        this.start(true)
      },
      onResize() {
        this.disabled = Breakpoints.isMobile(window.innerWidth)
        if (this.disabled && this.visible) {
          this.visible = false
          return
        }
        this.setArrowPosition()
      },
      checkFirstUnseen(stepData) {
        if (null === this.$options.firstUnseen && !SeenSetting.isSeen(stepData.id)) {
          this.$options.firstUnseen = stepData.id
        }
      },
      fillTerms(data) {
        if (data.hasOwnProperty('next')) {
          this.$options.terms.next = data.next
          this.$options.terms.previous = data.previous
          this.$options.terms.showVideo = data.showVideo
          this.$options.terms.seenAll = data.seenAll
        }
      },
      step(element, data) {
        const isFloat = 'float' === data.position

        if (!isFloat && null !== data.target && null === document.querySelector(data.target)) {
          return null
        }

        const attachTo = isFloat ? null : {
          element: data.target || element,
          on: data.position
        }

        const classes = data.class + (isFloat ? ' shepherd-element--is-float' : '')
        const content = this.stepContent(
          data.content || '',
          data.id,
          this.$options.terms.seenAll
        );

        let stepData = {
          id: data.id,
          title: data.title,
          text: content,
          attachTo: attachTo,
          classes: classes,
          buttons: this.buttons(data.vmsId, data.videoLink)
        }

        const offset = 16 + (data.distance !== undefined ? data.distance : 0);
        if (data.position === 'left') {
          stepData.tetherOptions = {
            offset: `0px ${offset}px`,
          }
        }
        else if (data.position === 'right') {
          stepData.tetherOptions = {
            offset: `0px -${offset}px`,
          }
        }
        else if (data.position === 'top') {
          stepData.tetherOptions = {
            offset: `${offset}px 0px`,
          }
        }
        else if (data.position === 'bottom') {
          stepData.tetherOptions = {
            offset: `-${offset}px 0px`,
          }
        }
        return stepData
      },
      tourData(element) {
        const data = {...defaultTourData, ...JSON.parse(element.dataset.tour)};
        data.priority = Number(data.priority)
        if (data.hasOwnProperty('distance')) {
          data.distance = Number(data.distance)
        }
        return data;
      },
      stepContent(content, id, seenAllText) {
        if (!this.autoStart) {
          return content;
        }

        const checkboxId = id + '-seen-all-checkbox';
        return content +
          `<p><input id="${checkboxId}" class="tour__seen-all-checkbox" type="checkbox" />
          <label class="tour__seen-all-label" for="${checkboxId}">${seenAllText}</label>
          </p>`
      },
      buttons(vmsId, videoLink) {
        let buttons = []

        buttons.push({
          text: this.$options.terms.previous,
          classes: 'button button--primary tour__button tour__button--previous',
          action: () => {
            this.back()
          }
        })

        if (null !== vmsId) {
          buttons.push({
            text: this.$options.terms.showVideo,
            classes: 'button button--light tour__button tour__button--video',
            secondary: true,
            action: () => {
              this.showVmsVideo(vmsId)
            }
          })
        }

        if (null !== videoLink) {
          buttons.push({
            text: this.$options.terms.showVideo,
            classes: 'button button--light tour__button tour__button--video',
            secondary: true,
            action: () => {
              this.openVideoLink(videoLink)
            }
          })
        }

        buttons.push({
          text: this.$options.terms.next,
          classes: 'button button--primary tour__button tour__button tour__button--next',
          action: () => {
            return this.next()
          }
        })

        return buttons;
      },
      start(fromClick) {
        if (null === this.$options.tour) {
          this.steps = this.getSteps()
          this.buildTour()

          this.$options.tour.on('cancel', () => {
            this.cancel()
          });
        } else {
          const stepsUpdate = this.getStepsUpdate()
          if (null !== stepsUpdate) {
            this.handleEvents = false
            this.steps.forEach(step => {
              this.removeStepFromTour(step)
            })
            this.steps = stepsUpdate
            this.steps.forEach(step => {
              this.addStepToTour(step)
            })
            this.handleEvents = true
          }
        }

        this.analyticsEvent((true === fromClick) ? 'clickstart' : 'autostart', null)
        this.onResize()

        if (false === fromClick && null !== this.$options.firstUnseen) {
          this.$options.tour.show(this.$options.firstUnseen, true)
          this.$options.firstUnseen = null
          return
        }
        if (null !== this.currentId) {
          if (!this.hasStep(this.currentId)) {
            this.currentId = null
          } else {
            this.$options.tour.show(this.currentId, true)
            return
          }
        }
        this.$options.tour.start()
      },
      buildTour() {
        this.$options.tour = new Shepherd.Tour({
          useModalOverlay: true,
          includeStyles: false,
          defaultStepOptions: {
            classes: '',
            scrollTo: false,
            cancelIcon: {
              enabled: true
            }
          }
        })

        this.steps.forEach(step => {
          this.addStepToTour(step)
        })
      },
      addStepToTour(step) {
        const stepObject = this.$options.tour.addStep(step)
        stepObject.on('show', () => {
          if (this.handleEvents) {
            this.onShowStep()
          }
        });
      },
      removeStepFromTour(step) {
        this.$options.tour.removeStep(step.id)
      },
      getStepsUpdate() {
        const currentStepIds = this.stepIds(this.steps)
        const updatedSteps = this.getSteps();
        const updatedStepIds = this.stepIds(updatedSteps)
        if (currentStepIds.join('|') === updatedStepIds.join('|')) {
          return null
        }
        return updatedSteps
      },
      stepIds(steps) {
        const ids = []
        steps.forEach(step => {
          ids.push(step.id)
        })
        return ids
      },
      hasStep(id) {
        for (const step of this.steps) {
          if (id === step.id) {
            return true
          }
        }
        return false
      },
      getSteps() {
        const elements = Array.from(document.querySelectorAll('[data-tour]')).filter(dt => dt.dataset.tour !== '[]').sort((a, b) => {
          const aData = this.tourData(a)
          const bData = this.tourData(b)
          return aData.priority > bData.priority ? 1 : aData.priority < bData.priority ? -1 : 0
        })

        if (0 === elements.length) {
          return []
        }

        const steps = []
        elements.forEach(element => {
          const data = this.tourData(element)
          this.fillTerms(data)
          const stepData = this.step(element, data)
          if (null !== stepData) {
            steps.push(stepData)
            this.checkFirstUnseen(stepData)
          }
        })

        // Remove previous from first step and next from last step
        steps[0].buttons.shift();
        steps[steps.length - 1].buttons.pop();

        return steps;
      },
      analyticsEvent(action, label) {
        if (null === label) {
          label = ''
        }

        gtag('event', action, {
          event_category: 'Tour',
          event_label: label
        });
      },
      clickShortcut(stepId) {
        this.$options.tour.show(stepId, true)
      },
      back() {
        this.analyticsEvent('previous', null)
        this.$options.tour.back()
      },
      next() {
        this.analyticsEvent('next', null)
        this.$options.tour.next()
      },
      cancel() {
        this.visible = false
        this.analyticsEvent('close', null)
      },
      onShowStep() {
        this.setArrowPosition()

        const step = this.$options.tour.getCurrentStep()

        SeenSetting.setSeen(step.id)
        this.currentId = step.id
        this.visible = true

        if (this.autoStart) {
          const checkboxId = step.id + '-seen-all-checkbox'
          const checkboxElement = document.getElementById(checkboxId)
          checkboxElement.checked = SeenSetting.allSeen()
          checkboxElement.removeEventListener('click', event => {
            this.setSeenAll(event)
          });
          checkboxElement.addEventListener('click', event => {
            this.setSeenAll(event)
          });
        }
      },
      setArrowPosition() {
        if (null === this.$options.tour) {
          return
        }

        const step = this.$options.tour.getCurrentStep()

        if (step && step.el && step.target && 'bottom' === step.options.attachTo.on) {
          const elRect = step.el.getBoundingClientRect()
          const targetRect = step.target.getBoundingClientRect()
          const diffX = (targetRect.left + (targetRect.width / 2)) - (elRect.left + (elRect.width / 2))
          const arrowLeft = (elRect.width / 2) + diffX;
          const arrow = step.el.querySelector('div.shepherd-arrow');
          arrow.style.left = Math.round(arrowLeft) + 'px';
        }
      },
      setSeenAll(event) {
        const checked = event.currentTarget.checked
        checked ? SeenSetting.setSeenAll() : SeenSetting.unsetSeenAll()
        this.analyticsEvent('checkbox-' + (checked ? 'check' : 'uncheck'), null, false)
      },
      openVideoLink(videoLink) {
        this.analyticsEvent('open video link', videoLink)
        window.open(videoLink, '_blank')
      },
      async showVmsVideo(vmsId) {
        this.analyticsEvent('view video', 'vms:' + vmsId)
        this.visible = false
        this.vmsId = vmsId;
      },
      continueAfterVideo() {
        this.vmsId = null;
        this.$options.tour.getCurrentStep().show()
      }
    }
  }
</script>
