var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table-rhv" }, [
    _c("thead", [
      _c(
        "tr",
        [
          _c("th"),
          _vm._v(" "),
          _vm._l(_vm.systemLevels, function (level) {
            return _c("th", { staticStyle: { width: "20%" } }, [
              _vm._v(_vm._s(level)),
            ])
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.rhvAdvices, function (learningGoalAdvices, learningGoal) {
        return _c(
          "tr",
          [
            _c("td", [_vm._v(_vm._s(learningGoalAdvices.title))]),
            _vm._v(" "),
            _vm._l(_vm.systemLevels, function (level, letter) {
              return [
                _c("td", { staticClass: "table-rhv__cell" }, [
                  _c(
                    "ul",
                    _vm._l(
                      learningGoalAdvices.advices[letter],
                      function (userReferenceAndSource) {
                        return _c("li", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getFullName(
                                userReferenceAndSource,
                                learningGoal,
                                learningGoalAdvices.advices
                              )
                            ),
                          },
                        })
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  learningGoalAdvices.advices[letter]
                    ? _c("p", { staticClass: "table-rhv__user-count" }, [
                        _vm._v(
                          _vm._s(learningGoalAdvices.advices[letter].length) +
                            "x"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }