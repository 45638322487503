var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: {
        "td--border--top": _vm.firstRow,
        "archived--tr": _vm.itemResult.myContent.archived,
        "disabled--tr": _vm.itemResult.loading,
      },
    },
    [
      _c(
        "td",
        {
          staticClass:
            "whitespace-nowrap student-name cockpit-results--no-hover no-disable-color",
          class: _vm.lastRow ? "border-bottom" : "no-border-bottom",
        },
        [_vm._v(_vm._s(_vm.firstRow ? _vm.user.fullName : ""))]
      ),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.itemResult.learningGoalTitle))]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.educationLevel))]),
      _vm._v(" "),
      _c("td", [
        _vm._v(
          _vm._s(_vm.itemResult.progress.completed) +
            "/" +
            _vm._s(_vm.itemResult.progress.total)
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("score", {
            attrs: { score: _vm.itemResult.score, link: _vm.scoreLink },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("td", { staticClass: "whitespace-nowrap" }, [
        !_vm.isCompleted(_vm.itemResult)
          ? _c(
              "div",
              { staticClass: "flex flex-wrap--wrap" },
              [
                _c("toggle-button", {
                  ref: "toggle",
                  attrs: {
                    disabled: _vm.itemResult.myContent.archived,
                    id: String(_vm.itemResult.id),
                    checked: "open" === _vm.state,
                  },
                  on: { change: _vm.toggleMyContent },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("td", [
        _c("div", { staticClass: "cockpit-results--actions" }, [
          _c("button", {
            staticClass:
              "button button--cockpit button--primary button--small button-archive",
            class: { "button--inverse": _vm.itemResult.myContent.archived },
            domProps: { innerHTML: _vm._s(_vm.archiveButtonTitle) },
            on: { click: _vm.toggleArchived },
          }),
          _vm._v(" "),
          _vm.itemResult.progress.completed === 0
            ? _c(
                "button",
                {
                  staticClass:
                    "button button--cockpit button--cockpit-with-icon-only button--primary button--small",
                  on: {
                    click: function ($event) {
                      return _vm.confirmRemoveLearningGoal()
                    },
                  },
                },
                [_c("i", { staticClass: "icon-trash" })]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }