var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _vm.educationLevelSelectorStore.hasEducationLevels()
        ? _c("td", [
            _c("input", {
              attrs: {
                type: "checkbox",
                disabled:
                  !_vm.educationLevelSelectorStore.hasChangedEducationLevel(
                    _vm.user.userReference
                  ),
              },
              domProps: {
                checked: _vm.educationLevelSelectorStore.storeEducationLevel(
                  _vm.user.userReference
                ),
              },
              on: {
                change: function ($event) {
                  return _vm.educationLevelSelectorStore.setStoreEducationLevel(
                    _vm.user.userReference,
                    $event.target.checked
                  )
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("td", { staticClass: "whitespace-nowrap" }, [
        _vm._v(_vm._s(_vm.user.fullName)),
      ]),
      _vm._v(" "),
      _vm.educationLevelSelectorStore.hasEducationLevels()
        ? [
            _c("td", {
              staticClass: "text-align-center",
              domProps: {
                innerHTML: _vm._s(
                  _vm.educationLevelSelectorStore.getUserEducationLevel(
                    _vm.user.userReference
                  ).name
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-align-center" },
              [
                _c("education-level-selector", {
                  staticClass: "education-level-selector--centered",
                  attrs: {
                    "education-levels":
                      _vm.educationLevelSelectorStore.educationLevels,
                    "current-education-level":
                      _vm.educationLevelSelectorStore.getUserEducationLevel(
                        _vm.user.userReference
                      ),
                    "selected-education-level":
                      _vm.educationLevelSelectorStore.getSelectedUserEducationLevel(
                        _vm.user.userReference
                      ),
                    "adviced-education-level":
                      _vm.educationLevelSelectorStore.getAdvicedUserEducationLevel(
                        _vm.user.userReference
                      ),
                    "with-reset": true,
                  },
                  on: {
                    "select-education-level": _vm.selectEducationLevel,
                    "reset-education-level": _vm.resetEducationLevel,
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("score", {
            attrs: {
              score: _vm.store.getLastTwoAverageScore(_vm.user.userReference),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.store.testsData, function (testData) {
        return [
          testData.hasDivider
            ? _c("td", { staticClass: "horizontal-divider--left" })
            : _vm._e(),
          _vm._v(" "),
          testData.hasDivider
            ? _c("td", { staticClass: "horizontal-divider--right" })
            : _vm._e(),
          _vm._v(" "),
          _c("td", [
            _c(
              "div",
              {
                staticClass:
                  "flexbox flexbox--row flex-wrap--no-wrap flex-justify-content--center",
              },
              [
                _c("score", {
                  attrs: {
                    score: _vm.store.getScore(
                      _vm.user.userReference,
                      testData.contentPath
                    ),
                    link: _vm.store.getLink(
                      _vm.user.userReference,
                      testData.contentPath,
                      false
                    ),
                  },
                }),
                _vm._v(" "),
                null !==
                _vm.store.getOtherScore(
                  _vm.user.userReference,
                  testData.contentPath
                )
                  ? _c("score", {
                      staticClass: "ml-half",
                      attrs: {
                        score: _vm.store.getOtherScore(
                          _vm.user.userReference,
                          testData.contentPath
                        ),
                        link: _vm.store.getLink(
                          _vm.user.userReference,
                          testData.contentPath,
                          true
                        ),
                        "is-secondary": true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }