<template>
  <form ref="form" class="form">
    <div class="result-grid__questions">
      <div class="result-grid__question-header result-grid__index">
        Opdracht
      </div>
      <div class="result-grid__question-header">
        Aantal goed
      </div>
      <div class="result-grid__question-header"></div>

      <template v-for="(question, index) in store.getQuestionsForUser(user)">
        <div :id="user.userReference + '_' + index" class="result-grid__question result-grid__question-index" v-html="question.label"></div>
        <div class="result-grid__question">
          <div v-if="null !== question.entryType" class="result-grid__question-score">
            <input type="text"
              :maxlength="question.points.toString().length"
              :title="question.label"
              :data-user-reference="user.userReference"
              :data-question-id="question.id"
              autocomplete="off"
              :value="getPoints(question)"
              @input="validateInput($event.target, question.points, user.userReference, question.id)"
              class="result-entry-grid__input"
              :class="{'result-entry-grid__input--invalid': !store.resultIsValid(question.id, user.userReference)}"
              :disabled="'default' !== question.entryType.type"
            />
            <span>/ {{ question.points }}</span>
          </div>
          <div v-else class="result-grid__question-score">
            <div class="result-entry-grid__no-input" v-html="$t('generic.term.not_applicable_short')"></div>
          </div>
        </div>
        <div class="result-grid__question">
          <result-entry-words
            v-if="null !== question.entryType && 'words' === question.entryType.type"
            ref="words"
            :user="user"
            :question="question"
            :result-data="store.getResultData(question.id, user.userReference)"
            @updateScore="updateScoreFromWords"
          ></result-entry-words>
          <div
            v-if="!store.resultIsValid(question.id, user.userReference)"
            v-html="$t('cockpit.test.result_entry.notification.max_score', {count: question.points})"
            class="error--msg"
          ></div>
          <div v-if="null === question.entryType" class="result-entry-grid__no-input-message" v-html="$t('cockpit.test.result_entry.prior_knowledge_message')"></div>
        </div>
      </template>
    </div>

    <div class="result-tab__footer">
      <div class="result-tab__actions">
        <div class="result-tab__submitted-at">
          <span class="result-tab__submitted-at-title">{{ $t('cockpit.test.result_entry.submitted_at') }}:</span>
          <date-time-input
            input-class="form-group__field"
            :value="getSubmittedAt()"
            :pick-time="false"
            :position-top="true"
            id="submitted_at"
            :allow-future-dates="false"
            @input="setSubmittedAt"
          />
        </div>
        <div class="result-tab__action-buttons">
          <button
            ref="buttonReset"
            @click.prevent="clickAndResetResults()"
            type="button"
            class="button button--cockpit button--primary button--clear"
          >
            <i class="icon-rubber-eraser mr-1"></i>
            {{ $t('cockpit.test.modal.delete') }}
          </button>
          <button
            ref="buttonSave"
            @click.prevent="saveResultsByClick()"
            type="submit"
            class="button button--cockpit button--primary button--save"
            v-html="$t('cockpit.test.modal.save')"
          ></button>
        </div>
      </div>

      <div ref="resetMsg" class="error notification" v-html="$t('cockpit.test.result_entry.notification.reset')"></div>
      <div ref="savedMsg" class="success notification" v-html="$t('cockpit.test.result_entry.notification.saved')"></div>
      <div ref="noPointsMsg" class="error notification" v-html="$t('cockpit.test.result_entry.notification.incomplete')"></div>
      <div ref="notSavedPointsMsg" class="error notification" v-html="$t('cockpit.test.result_entry.notification.not_saved_points')"></div>
      <div ref="notSavedDateMsg" class="error notification" v-html="$t('cockpit.test.result_entry.notification.not_saved_date')"></div>
    </div>
  </form>
</template>

<script>
import FolioTestService from './FolioTestService'
import ResultEntryWords from './ResultEntryWords'
import FolioResultsStore from './folio-results/FolioResultsStore'
import DateTimeInput from '../components/DateTimeInput'
import * as Sentry from "@sentry/vue";

export default {
  components: {
    ResultEntryWords,
    DateTimeInput
  },
  props: {
    store: FolioResultsStore,
    user: Object,
    folioTestService: FolioTestService
  },
  data() {
    return {
      activeUserReference: '',
      activeIndex: false,
      disableSaveButton: true,
      inputs: null
    }
  },
  mounted() {
    this.inputs = Array.from(this.$refs.form.querySelectorAll('.result-entry-grid__input'))
  },
  methods: {
    getPoints(question) {
      const points = this.store.getPoints(question.id, this.user.userReference)
      if (null !== points) {
        return points
      }

      return this.setDefaultPointsForQuestion(question)
    },

    setDefaultPointsForQuestion(question) {
      this.store.updateScore(question.id, this.user.userReference, question.points)
      this.toggleFormSaveButton()
      return question.points
    },

    getSubmittedAt () {
      if (!this.store.isDefaultSubmittedAt(this.user.userReference)) {
        const submittedAt = this.store.getSubmittedAt(this.user.userReference)
        if (null !== submittedAt) {
          this.toggleFormSaveButton()
          return submittedAt
        }
      }

      const defaultSubmittedAt = this.store.getDefaultSubmittedAt()
      this.setSubmittedAt (defaultSubmittedAt)
      return defaultSubmittedAt
    },

    setSubmittedAt (value) {
      this.store.setSubmittedAt(this.user.userReference, value, true)
      this.toggleFormSaveButton()
    },

    validateInput(target, points, userReference, questionId) {
      this.store.updateScore(questionId, userReference, target.value)
      this.toggleFormSaveButton()
    },

    updateScoreFromWords(updateFormButtons, questionId, userReference, score, data) {
      this.store.updateScore(questionId, userReference, score, data)
      if (updateFormButtons) {
        this.toggleFormSaveButton()
      }
    },

    toggleFormSaveButton() {
      this.$nextTick(() => {
        if (this.$refs.buttonReset) {
          const hasChanges = this.store.hasChanges(this.user.userReference)

          this.$refs.buttonReset.disabled = hasChanges || !this.store.hasCompleteStoredResultForUserReference(this.user.userReference)
          this.filterAllInputFields(this.user)
        }
      })
    },

    filterAllInputFields(user) {
      const emptyFields = this.inputs.filter(input => input.value === '')
      const fields = this.inputs.filter(input => input.value)

      this.$refs.buttonSave.disabled = !this.isSaveButtonEnabled(emptyFields, fields, user)

      this.notificationMessageHandler(emptyFields, this.inputs, fields, user)
    },

    isSaveButtonEnabled(emptyFields, fields, user) {
      const invalidInputs = this.$refs.form.querySelectorAll('.invalid--result')

      if (emptyFields.length > 0 || invalidInputs.length > 0) {
        return false
      }

      const resetDisabled = this.$refs.buttonReset.disabled
      if (!resetDisabled && !(fields.length === this.inputs.length && resetDisabled)) {
        return false
      }

      return this.store.canSave(user.userReference)
    },

    notificationMessageHandler(emptyFields, inputs, fields, user) {
      this.showNotification(this.$refs.resetMsg, false)
      this.showNotification(this.$refs.noPointsMsg, (emptyFields.length !== 0 && fields.length !== 0 && inputs.length >= fields.length))

      this.showNotification(this.$refs.savedMsg, false)
      this.showNotification(this.$refs.notSavedPointsMsg, false)
      this.showNotification(this.$refs.notSavedDateMsg, false)

      if (this.$refs.buttonReset.disabled && fields.length === inputs.length) {
        if (this.store.userScoresHaveChanges(user.userReference)) {
          this.showNotification(this.$refs.notSavedPointsMsg, true)
        } else if (this.store.hasChanges(user.userReference)) {
          this.showNotification(this.$refs.notSavedDateMsg, true)
        }
      }
    },

    showNotification(notification, show) {
      notification.classList.toggle('show--msg', show)
    },

    async clickAndResetResults() {
      this.store.loading = true

      this.$refs.buttonSave.disabled = true
      this.$refs.buttonReset.disabled = true

      await this.deleteResults()

      this.store.resetResultDataForUserReference(this.user.userReference)
      this.store.updateStored(this.user.userReference)
      this.getSubmittedAt() // Needs to be triggers to update the current date in the store

      this.resetNotificationMessages()

      this.$refs.resetMsg.classList.add('show--msg')
      this.$refs.buttonSave.disabled = false

      this.store.loading = false

      this.$emit('reset')
    },

    async saveResultsByClick() {
      this.store.loading = true

      this.updateDataFromInputs(false)
      this.store.updateStored(this.user.userReference)

      await this.$nextTick()

      this.$refs.buttonSave.disabled = true
      this.$refs.buttonReset.disabled = true

      await this.saveResults()

      this.$refs.buttonReset.disabled = false
      this.resetNotificationMessages()

      this.$refs.savedMsg.classList.add('show--msg')

      this.store.loading = false

      this.$emit('stored')
    },

    resetNotificationMessages() {
      this.$refs.form.querySelectorAll('.show--msg').forEach(element => {
        element.classList.remove('show--msg')
      })
    },

    updateDataFromInputs(reset) {
      this.inputs.forEach(input => {
        let value = input.value
        if (reset) {
          input.value = ''
          value = null
        }
        this.store.updateScore(input.getAttribute('data-question-id'), input.getAttribute('data-user-reference'), value)
      })
    },

    async saveResults() {
      try {
        const response = await this.folioTestService.saveTestResults(
          this.store.group.id,
          this.store.test.id,
          this.store.getResultsForUserReference(this.user.userReference, true)
        )
        this.store.updateEducationLevels(response.data.educationLevels)
      } catch (exception) {
        Sentry.captureException(new Error(exception.message));
        console.error(exception.message)
      }
    },

    async deleteResults() {
      try {
        const response = await this.folioTestService.deleteTestResults(
          this.store.group.id,
          this.store.test.id,
          this.user.userReference
        )
        this.store.updateEducationLevels(response.data.educationLevels)
      } catch (exception) {
        Sentry.captureException(new Error(exception.message));
        console.error(exception.message)
      }
    }
  }
}
</script>
