<template>
  <transition name="fade" mode="out-in">
    <div class="lesson__topic column is-one-fifth" :data-test="'lesson-topic-card-' + index">
      <div :class="containerClass">
        <span class="card--modal-trigger card--bordered" @click.self="onClick"
              :class="[
                triggerClass,
                { 'card--added-to-activities' : store.added },
                { 'card--lesson-completed' : store.completed }
              ]">
          <my-activity-toggle
            v-if="store.groupId && store.groupId > 0"
            :store="store"
          ></my-activity-toggle>
          <slot></slot>

          <div class="card__lesson-status-label" @click.stop>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.03224 12.2465C1.03224 12.2465 11.3458 7.37428 13.7505 4.96959C16.1551 2.5649 11.6199 1.61634 8.10189 3.52679C4.58392 5.43723 5.27085 9.2908 10.2384 9.2629C11.5615 9.25547 13.5512 9.90807 12.6451 10.8387C11.7537 11.7544 9.18509 11.9736 8.10184 12.709"
                stroke="#97BE0C" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import MyActivityToggle from '../MyActivityToggle'
import ActivityStore from '../ActivityStore'

export default {
  navigateAndViewEventProducer: null,

  components: {
    MyActivityToggle
  },

  props: {
    store: ActivityStore,
    index: String,
    title: String,
    image: String,
    containerClass: {
      type: String,
      default: ''
    },
    triggerClass: {
      type: String,
      default: ''
    },
    meta: Object,
    metaLeerjaarLabel: String,
    addedToMyActivitiesLabel: String,
    completedStatusLabelChecked: String,
    completedStatusLabelUnchecked: String
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
