<template>
  <tr>
    <td v-if="streamHasAssignmentsExtra && hasAssignmentsExtra"><input type="checkbox" :checked="assignMyContentChecked" :disabled="selectedEducationLevel === null" @change="onCheckbox"/></td>
    <td class="whitespace-nowrap">{{ result.student.fullName }}</td>
    <td v-if="streamHasAssignmentsExtra && hasAssignmentsExtra" class="cockpit-results__education-level-selector">
      <my-content-education-level-selector
        :education-levels="educationLevels"
        :initial-education-level="initialEducationLevel"
        :selected-education-level="selectedEducationLevel"
        :with-reset="true"
        @select-education-level="selectEducationLevel"
        @reset-education-level="resetEducationLevel"
      ></my-content-education-level-selector>
    </td>
    <td v-else-if="streamHasAssignmentsExtra"><i>Geen extra oefening beschikbaar.</i></td>
    <td v-for="lesson in lessons">
      <div class="flexbox flex-justify-content--center">
        <span v-if="shouldShowEducationLevelMarker(lesson)" class="mr-half">{{ getEducationLevelMarker(lesson) }}</span>
        <score :score="getScore(lesson)" :link="getLink(lesson)"></score>
      </div>
    </td>
    <td v-if="streamHasAssignmentsExtra" class="text-align-center">{{ numberOfOpenMyContent }}</td>
  </tr>
</template>

<script>

import Score, { EMPTY_SCORE } from '../../components/Score'
import TreeSelect from '../../components/TreeSelect'
import EducationLevelForMyContent from './EducationLevelForMyContent'
import MyContentEducationLevelSelector from './my-content/MyContentEducationLevelSelector'
import MyContentState from './my-content/MyContentState'
import AbsoluteLinkService from "../../AbsoluteLinkService";
import ContentPathResolver from "../../ContentPathResolver";

const educationLevelForMyContent = new EducationLevelForMyContent()

export default {
  components: { MyContentEducationLevelSelector, Score, TreeSelect },

  props: {
    lessons: Array,
    result: Object,
    showEducationLevelMarker: Boolean,
    learningGoals: Object,
    learningGoalPath: String,
    assignMyContentChecked: Boolean,
    assignItemReference: String,
    hasAssignmentsExtra: Boolean,
    streamHasAssignmentsExtra: Boolean,
    numberOfOpenMyContent: Number,
    assignedContent: Object,
    streamCode: String,
    editionUrl: String,
    groupId: String
  },
  data() {
    // determine if there should be a my-content selection for the learninggoal
    const searchForEducationLevel = this.getSearchForEducationLevel()

    return {
      learningGoal: this.learningGoalPath,
      educationLevels: [],
      initialEducationLevel: null,
      selectedEducationLevel: null,

      searchForEducationLevel: searchForEducationLevel
    }
  },
  watch: {
    assignItemReference() {
      if (this.assignItemReference === null) {
        this.selectedEducationLevel = null
      }
    },
    assignedContent() {
      for (const educationlevel of this.educationLevels) {
        if (this.assignedContent[educationlevel.item.uniqueContentReference] !== undefined) {
          educationlevel.state = new MyContentState(this.assignedContent[educationlevel.item.uniqueContentReference].state)
        }
      }
    }
  },
  mounted () {
    this.initLearningGoal()
  },
  methods: {
    getSearchForEducationLevel() {
      // Lessons are in reverse order, so the first valid score is the last score, which we base the advice on
      for (const lesson of this.lessons) {
        const score = this.getScore(lesson)
        if (!score.isEmpty && this.shouldShowEducationLevelMarker(lesson)) {
          return educationLevelForMyContent.getEducationLevelForMyContent(
            this.result.userEducationLevel.educationLevels[lesson.contentPath].identifier,
            score
          )
        }
      }
      return null
    },
    onCheckbox() {
      this.$emit(this.assignMyContentChecked ? 'education-level-unchecked' : 'education-level-checked')
    },
    initLearningGoal() {
      this.onInitialLearningGoal(this.learningGoals)
    },
    findNode (treeNode, value) {
      if (treeNode.id === value) {
        return [treeNode]
      }
      if (treeNode.children.length > 0) {
        for (const childNode of treeNode.children) {
          const result = this.findNode(childNode, value)
          if (result.length > 0) {
            return result
          }
        }
      }
      return []
    },
    onInitialLearningGoal(node) {
      this._createEducationLevels(node)
      if (this.educationLevels.length === 0) {
        this.learningGoal = null
        this.searchForEducationLevel = null
        return
      }
      if (this.searchForEducationLevel !== null) {
        for (const level of this.educationLevels) {
          if (level.educationLevel.indexOf(this.searchForEducationLevel) !== -1) {
            this.initialEducationLevel = level
            this.selectedEducationLevel = level
            this.$emit('education-level-selected', level)
            break;
          }
        }
        this.searchForEducationLevel = null
      }
    },
    _createEducationLevels(node) {
      this.selectedEducationLevel = null

      const educationLevelsForNode = educationLevelForMyContent.getEducationLevelsForNode(node, this.assignedContent);
      this.educationLevels = educationLevelsForNode.educationLevels
    },
    resetEducationLevel() {
      this.selectedEducationLevel = this.initialEducationLevel
      this.emitSelected()
    },
    selectEducationLevel(educationLevel) {
      if (this.selectedEducationLevel === educationLevel) {
        this.selectedEducationLevel = null
        this.emitSelected()
        return
      }

      if (educationLevel.state.isUnassigned() || educationLevel.state.isWithdrawn()) {
        this.selectedEducationLevel = educationLevel
        this.$emit('education-level-selected', educationLevel)
      }
    },
    emitSelected() {
      if (null === this.selectedEducationLevel) {
        this.$emit('education-level-deselected')
      } else {
        this.$emit('education-level-selected', this.selectedEducationLevel)
      }
    },
    getScore(lesson) {
      if (this.result.scores[lesson.contentPath] !== undefined) {
        return this.result.scores[lesson.contentPath]
      }
      return EMPTY_SCORE
    },
    getLink(lesson) {
      if (this.getScore(lesson).isEmpty) {
        return '';
      }

      const absoluteLinkService = new AbsoluteLinkService(this.editionUrl)
      const contentPathResolver = new ContentPathResolver(lesson.contentPath)

      return absoluteLinkService.editionLink(
        'edition_result_group_paragraph',
        {
          streamCode: this.streamCode,
          contentPath: contentPathResolver.paragraphPath(),
          groupId: this.groupId,
          learningGoal: this.learningGoalPath
        }
      )

    },
    shouldShowEducationLevelMarker (lesson) {
      return this.showEducationLevelMarker && this.result.userEducationLevel.educationLevels[lesson.contentPath] !== undefined
    },
    getEducationLevelMarker (lesson) {
      if (this.shouldShowEducationLevelMarker(lesson)) {
        return this.result.userEducationLevel.educationLevels[lesson.contentPath].marker
      }
      return ''
    }
  }
}
</script>
