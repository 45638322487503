<template>
  <div>
    <div v-if="!isRoot" class="flexbox"><a class="ml-1" :class="iconClass" @click="toggleOpen"></a><a
      class="dropdown__item flex whitespace-nowrap" :class="nodeClass" @click="onClick" v-html="node.title"></a></div>
    <template v-if="node.children.length > 0 && open">
      <tree-node :class="{'ml-1': !isRoot}" v-for="child in node.children" :key="child.id" :node="child"
                 :is-root="false" :select-leafs-only="selectLeafsOnly" :selected-branch="selectedBranch"
                 @select-node="selectNode"></tree-node>
    </template>
  </div>
</template>

<script>

export default {
  name: 'tree-node',
  data () {
    const selectedBranchNode = this.selectedBranch.find((element) => {
      return element.id === this.node.id
    })
    return {
      isSelectable: !this.selectLeafsOnly || 0 === this.node.children.length,
      open: this.isRoot || this.node.forceOpen === true || (selectedBranchNode !== undefined && this.selectedBranch[this.selectedBranch.length - 1] !== selectedBranchNode)
    }
  },
  watch: {
    'node.forceOpen': function (value) {
      if (value === true && !this.open) {
        this.open = true
      } else if (value === false && this.open) {
        this.open = false
      }
    }
  },
  props: {
    isRoot: {
      type: Boolean,
      default: false
    },
    selectedBranch: Array,
    node: Object,
    selectLeafsOnly: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isSelected () {
      return this.selectedBranch.length > 0 && this.selectedBranch[this.selectedBranch.length - 1].id === this.node.id
    },
    nodeClass () {
      return {
        'dropdown__item--selected': this.isSelected,
        'dropdown__item--unselectable': this.node.isNotSelectable || !this.isSelectable
      }
    },
    iconClass () {
      return {
        'icon-tm-arrow-right': !this.open,
        'icon-tm-arrow-down': this.open,
        'is-invisible': this.node.children.length === 0
      }
    }
  },
  methods: {
    toggleOpen () {
      this.open = !this.open
    },
    onClick () {
      if (!this.isSelectable) {
        this.toggleOpen()
        return
      }
      this.$emit('select-node', this.node)
    },
    selectNode (node) {
      this.$emit('select-node', node)
    }
  }
}
</script>
