var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { class: _vm.noBorder ? "no-bottom-border" : "" },
    [
      _c("td", {
        staticClass: "whitespace-nowrap",
        domProps: {
          innerHTML: _vm._s(_vm.showUsername ? _vm.user.fullName : ""),
        },
      }),
      _vm._v(" "),
      _vm.store.showEducationLevelColumn
        ? _c("td", {
            domProps: {
              innerHTML: _vm._s(
                _vm.store
                  .getLevelsForTestType(
                    _vm.user.userReference,
                    _vm.type,
                    _vm.referenceLevel
                  )
                  .join(", ")
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("td", {
        class: _vm.empty ? "text-align-center" : "",
        domProps: {
          innerHTML: _vm._s(
            _vm.empty ? "-" : _vm.$t("cockpit.test." + _vm.type)
          ),
        },
      }),
      _vm._v(" "),
      _vm.store.hasReferenceLevels
        ? _c("td", { domProps: { innerHTML: _vm._s(_vm.referenceLevel) } })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.store.learningGoals, function (learningGoal, index) {
        return [
          index > 0
            ? [
                _c("td", { staticClass: "horizontal-divider--left" }),
                _vm._v(" "),
                _c("td", { staticClass: "horizontal-divider--right" }),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.empty &&
          _vm.store.showRhv &&
          _vm.hasPathScore(learningGoal.contentPath)
            ? _c(
                "td",
                { staticClass: "text-align-left" },
                [
                  _vm.pathsWithRhvAdvice.indexOf(learningGoal.contentPath) >= 0
                    ? _c("option-selector", {
                        key:
                          "rhv" +
                          _vm.user.userReference +
                          "-" +
                          learningGoal.contentPath +
                          "-" +
                          _vm.type,
                        attrs: {
                          options: _vm.store.getRhvAdviceOptions(
                            _vm.user.userReference,
                            learningGoal,
                            _vm.type,
                            _vm.referenceLevel
                          ),
                          "can-deselect": true,
                          disabled: _vm.store.isLoadingFor(
                            _vm.user.userReference,
                            learningGoal.contentPath,
                            _vm.type
                          ),
                          context: {
                            userReference: _vm.user.userReference,
                            contentReference: _vm.store.contentReference,
                            learningGoalId: learningGoal.contentPath,
                            source: _vm.type,
                            referenceLevel: _vm.referenceLevel,
                          },
                        },
                        on: {
                          select: _vm.selectAdvice,
                          reset: _vm.resetAdvice,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.store.showRhv
            ? _c("td")
            : _vm._e(),
          _vm._v(" "),
          _vm._l(
            _vm.store.getScoresForLearningGoalAndTestType(
              _vm.user.userReference,
              learningGoal,
              _vm.type,
              _vm.referenceLevel
            ),
            function (score, testType) {
              return _c(
                "td",
                [
                  _c("score", {
                    key:
                      "rhv" +
                      _vm.user.userReference +
                      "-" +
                      learningGoal.contentPath +
                      "-" +
                      _vm.type +
                      "-" +
                      index,
                    attrs: {
                      score: score,
                      "empty-value": _vm.store.getEmptyValue(
                        score,
                        _vm.user.userReference,
                        learningGoal,
                        _vm.type,
                        testType,
                        _vm.referenceLevel
                      ),
                      link: _vm.store.getScoreLink(
                        score,
                        _vm.user.userReference,
                        learningGoal,
                        _vm.type,
                        testType,
                        _vm.referenceLevel
                      ),
                    },
                  }),
                ],
                1
              )
            }
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }