var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button button--cockpit print-content--hidden",
      attrs: { "data-test": "print-page-content-button" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.print.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      !this.$slots.default
        ? _c("span", [
            _c("i", { staticClass: "icon-printer" }),
            _vm._v(" " + _vm._s(_vm.$t("generic.term.print"))),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }