'use strict'

import EducationLevels from '../util/EducationLevels'
import EducationLevel from '../util/EducationLevel'
import Routing from '../util/routing'
import { post } from '../util/request'

const _mapUserEducationLevels = (userEducationLevels, averageScores, educationLevels) => {
  Object.entries(userEducationLevels).forEach (([userReference, userEducationLevel]) => {
    userEducationLevel.current = EducationLevel.fromData(userEducationLevel.defaultEducationLevel)
    if (averageScores[userReference].isEmpty) {
      userEducationLevel.advice = userEducationLevel.current
      userEducationLevel.selected = userEducationLevel.current
    } else {
      userEducationLevel.advice = educationLevels.getEducationLevelForScore(averageScores[userReference].score)
      userEducationLevel.selected = userEducationLevel.advice
    }
    userEducationLevel.store = !userEducationLevel.current.equals(userEducationLevel.selected)
  })
  return userEducationLevels
}

export default class EducationLevelSelectorStore {
  constructor(educationLevelData, usersEducationLevels, userAverageScores, groupId) {
    this.isLoading = false
    this.educationLevels = EducationLevels.fromData(educationLevelData)
    this.usersEducationLevels = _mapUserEducationLevels(usersEducationLevels, userAverageScores, this.educationLevels)
    this.groupId = groupId
  }

  getUserEducationLevel(userReference) {
    return this.usersEducationLevels[userReference].current
  }

  getSelectedUserEducationLevel(userReference) {
    return this.usersEducationLevels[userReference].selected
  }

  getAdvicedUserEducationLevel(userReference) {
    return this.usersEducationLevels[userReference].advice
  }

  selectEducationLevelForUser(userReference, educationLevel) {
    const userLevel = this.usersEducationLevels[userReference];
    let selected = educationLevel
    if (null !== userLevel.selected && userLevel.selected.equals(educationLevel)) {
      selected = null
    }

    this.usersEducationLevels[userReference].selected = selected
    this.usersEducationLevels[userReference].store = this.hasChangedEducationLevel(userReference)
  }

  resetEducationLevelForUser(userReference) {
    const userLevel = this.usersEducationLevels[userReference]
    userLevel.selected = null !== userLevel.advice ? userLevel.advice : userLevel.current
    userLevel.store = !userLevel.current.equals(userLevel.selected)
  }

  hasChangedEducationLevel(userReference) {
    const levels = this.usersEducationLevels[userReference]
    return !levels.current.equals(levels.selected)
  }

  storeEducationLevel(userReference) {
    return this.usersEducationLevels[userReference].store
  }

  setStoreEducationLevel(userReference, value) {
    this.usersEducationLevels[userReference].store = value
  }

  setAllStoreEducationLevel(value) {
    for (const userReference of Object.keys(this.usersEducationLevels)) {
      this.usersEducationLevels[userReference].store = value && this.hasChangedEducationLevel(userReference)
    }
  }

  hasEducationLevels() {
    return educationLevelSelectorStore.educationLevels.educationLevels[0].identifier !== 'no-education-level';
  }

  hasEducationLevelChanges() {
    for (const levels of Object.values(this.usersEducationLevels)) {
      if (undefined !== levels.selected && null !== levels.selected && levels.selected.identifier !== levels.defaultEducationLevel.identifier) {
        return true
      }
    }
    return false
  }

  storeEducationLevelChangesCount() {
    return Object.values(this.usersEducationLevels).reduce((carry, levels) => (levels.store ? carry + 1 : carry), 0)
  }

  allEducationLevelChangesChecked() {
    let hasChanges = false
    for (const levels of Object.values(this.usersEducationLevels)) {
      if (!levels.current.equals(levels.selected)) {
        if (!levels.store) {
          return false
        }
        hasChanges = true
      }
    }
    return hasChanges
  }

  hasEducationLevels() {
    return this.educationLevels.isEnabled()
  }

  clearUserEducationLevelChanges() {
    for (const userReference of Object.keys(this.usersEducationLevels)) {
      if (this.usersEducationLevels[userReference].store && this.hasChangedEducationLevel(userReference)) {
        this.usersEducationLevels[userReference].current = this.usersEducationLevels[userReference].selected
        this.usersEducationLevels[userReference].store = false
      }
    }
  }

  changesToStore() {
    let changes = []

    for (const userReference of Object.keys(this.usersEducationLevels)) {
      if (this.usersEducationLevels[userReference].store && this.hasChangedEducationLevel(userReference)) {
        changes.push({
          'userReference': userReference,
          'educationLevelIdentifier': this.usersEducationLevels[userReference].selected.identifier
        })
      }
    }

    return changes
  }

  async saveEducationLevelChanges() {
    this.isLoading = true;

    const url = Routing.generate('cockpit_set_education_levels', { streamCode: msp.streamCode });

    const body = {
      'groupId': this.groupId,
      'userEducationLevels': this.changesToStore()
    }

    try {
      await post(url, body);
    } catch (e) {
      throw new Error(e.response.data);
    } finally {
      this.clearUserEducationLevelChanges()
      this.isLoading = false;
    }
  }
}
