'use strict'

export default class ContentPath {
  constructor (identifiers) {
    this.identifiers = identifiers
    this.reference = identifiers.join('/')
  }

  toString () {
    return this.reference
  }

  getDepth () {
    return this.identifiers.length
  }

  slice (start) {
    return new ContentPath(this.identifiers.slice(start))
  }

  up () {
    if (this.identifiers.length > 1) {
      return new ContentPath(this.identifiers.slice(0, this.identifiers.length - 1))
    }
    throw new Error('invalid level for content path')
  }

  static fromString (str) {
    return new ContentPath(str.split('/'))
  }

  static empty () {
    return new ContentPath([])
  }

  static fromAssignment(assignment) {
      if (null !== assignment.id) {
        return new ContentPath([assignment.parentId, assignment.id])
      }
      return new ContentPath([assignment.parentId])
  }

  toJSON () {
    return this.identifiers
  }

  /**
   * @param {ContentPath} contentPath
   */
  equals (contentPath) {
    return this.reference === contentPath.reference
  }

  /**
   * @param {ContentPath} contentPath
   */
  startsWith (contentPath) {
    return this.toString().startsWith(contentPath.toString())
  }
}
