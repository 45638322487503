var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "button button--small button--primary button--cockpit",
          attrs: { "data-test": "create-test-button" },
          on: { click: _vm.createTest },
        },
        [
          _c("i", { staticClass: "icon-plus" }),
          _vm._v(" " + _vm._s(_vm.$t("cockpit.test.assign_button")) + "\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button button--small button--primary button--cockpit",
          attrs: { "data-test": "resend-button" },
          on: { click: _vm.resendToThas },
        },
        [
          _c("i", { staticClass: "icon-send" }),
          _vm._v(
            " " +
              _vm._s(_vm.$t("cockpit.test.resend_digital_tests.button")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.editableTest !== null
        ? _c("test-form", {
            attrs: {
              "initial-test": _vm.editableTest,
              group: _vm.group,
              "test-service": _vm.testService,
              tests: _vm.tests,
            },
            on: {
              close: function ($event) {
                _vm.editableTest = null
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.resendVisible
        ? _c("resend-form", {
            attrs: { "test-service": _vm.testService, tests: _vm.tests },
            on: {
              close: function ($event) {
                _vm.resendVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }