export default class TreeSelectStore {
  constructor (tree, emptyTitle, selectLeafsOnly) {
    this.initialTree = tree
    this.searchTerm = ''
    this.selectedBranch = []
    this.selectLeafsOnly = selectLeafsOnly
    this.emptyTitle = emptyTitle
    this.title = emptyTitle
    this.easterEggActive = false
    this.tree = this.buildTree(this.initialTree)
  }

  setSelectedBranch (id) {
    this.selectedBranch = this.getSelectedBranch(this.initialTree, id)
    this.title = this.getTitle()
    if ('' !== this.searchTerm) {
      this.tree = this.buildTree(this.initialTree)
    }
  }

  clearSelectedBranch () {
    this.selectedBranch = []
    this.title = this.getTitle()
  }

  getTitle () {
    if (this.selectedBranch.length > 0) {
      if (!this.selectLeafsOnly) {
        return this.selectedBranch.map((selectedNode) => {
          return selectedNode.title
        }).join(' - ')
      } else {
        return this.selectedBranch[this.selectedBranch.length - 1].title
      }
    }
    return this.emptyTitle
  }

  getSelectedBranch (treeNode, value) {
    if (treeNode.id === value) {
      return [treeNode]
    }
    if (treeNode.children.length > 0) {
      for (const childNode of treeNode.children) {
        const result = this.getSelectedBranch(childNode, value)
        if (result.length > 0) {
          if (treeNode !== this.initialTree) {
            result.unshift(treeNode)
          }
          return result
        }
      }
    }
    return []
  }

  getSelectedTreeNode () {
    const length = this.selectedBranch.length
    if (length > 0) {
      return this.selectedBranch[length - 1]
    }
    return null
  }

  search (term) {
    this.searchTerm = term
    if ('TLA' === term) {
      this.easterEggActive = true
    }
    this.tree = this.buildTree(this.initialTree)
  }

  buildTree (tree) {
    const clonedTree = Object.assign({}, tree)
    clonedTree.children = this.filterChildren(clonedTree.children, '' === this.searchTerm)
    if (this.easterEggActive && ('Ruud' === this.searchTerm || 'Niels' === this.searchTerm || 'Eric' === this.searchTerm)) {
      clonedTree.children.push({
        id: 'tla',
        title: 'TM - Team Learning Applications',
        forceOpen: true,
        children: [{
          id: this.searchTerm,
          title: this.hilightTerm(this.searchTerm) + ' kan keigoed programmeren!',
          forceOpen: true,
          children: []
        }]
      })
      this.easterEggActive = false
    }
    return clonedTree
  }

  filterChildren (treeChildren, includeAll) {
    const filtered = []
    treeChildren.forEach(node => {
      const isMatch = '' !== this.searchTerm && node.title.indexOf(this.searchTerm) >= 0

      const children = this.filterChildren(
        node.children,
        includeAll || isMatch
      )

      if (includeAll || isMatch || children.length > 0) {
        const clonedNode = Object.assign({}, node)
        clonedNode.children = children
        if ('' !== this.searchTerm) {
          clonedNode.forceOpen = true
          if (isMatch) {
            clonedNode.title = this.hilightTerm(clonedNode.title)
          }
        } else if ('' === this.searchTerm) {
          clonedNode.forceOpen = false
        }
        filtered.push(clonedNode)
      }
    })
    return filtered
  }

  hilightTerm (title) {
    return title.replace(this.searchTerm, '<span class="search-term">' + this.searchTerm + '</span>')
  }
}
