<template>
  <transition name="fade" mode="out-in">
    <div class="lesson__topic column is-one-fifth" :data-test="'lesson-topic-card-' + index">
      <div :class="containerClass">
        <span class="card--modal-trigger" :class="[triggerClass, { 'card--completed' : completed }]" @click="onClick">
          <slot></slot>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    completed: Boolean,
    index: String,
    containerClass: {
      type: String,
      default: ''
    },
    triggerClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
