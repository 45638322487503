var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-modal", class: { "is-loading": _vm.loading } },
    [
      _c("div", {
        staticClass: "form-modal__background",
        on: { click: _vm.close },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-modal__container form-modal__container--wide" },
        [
          _c("button", {
            staticClass: "form-modal__close",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.close.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "labeled-title form-modal__title" }, [
            _c("span", { staticClass: "labeled-title__text" }, [
              _vm._v(
                _vm._s(_vm.$t("cockpit.progress.select_learning_goal_title"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-modal__body" },
            [
              _c("learning-goals-for-block", {
                attrs: {
                  "stream-code": _vm.streamCode,
                  blocks: _vm.blocks,
                  "block-content-path": _vm.blockContentPath,
                },
                on: {
                  loading: function ($event) {
                    _vm.loading = true
                  },
                  loaded: function ($event) {
                    _vm.loading = false
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }