var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-modal" }, [
    _c("div", {
      staticClass: "form-modal__background",
      on: { click: _vm.close },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-modal__container form-modal__container--wider" },
      [
        _c("button", {
          staticClass: "form-modal__close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.close.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "labeled-title form-modal__title form-modal__title--full-width mb-2",
          },
          [
            _c("span", { staticClass: "labeled-title__text" }, [
              _vm._v(_vm._s(_vm.test.subject)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [_c("links", { attrs: { links: _vm.modalLinks } })], 1),
        _vm._v(" "),
        _c("result-grid", {
          attrs: {
            questions: _vm.test.questions,
            users: _vm.users,
            store: _vm.store,
            "score-categories-data": _vm.scoreCategoriesData,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-modal__actions" }, [
          _c("button", {
            staticClass: "button button--cockpit button--primary",
            domProps: { innerHTML: _vm._s(_vm.$t("generic.term.close")) },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.close.apply(null, arguments)
              },
            },
          }),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }