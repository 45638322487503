<template>
  <div class="dropdown" :class="{ 'dropdown--open': open, 'dropdown--disabled': disabled }">
    <div ref="trigger" class="dropdown__trigger" @click="toggle">
      <span class="dropdown__trigger-label mr-1" v-html="store.title"></span>
      <i class="icon-tm-arrow-down dropdown__trigger-arrow"/>
    </div>
    <div ref="dropdownMenu" class="dropdown__menu" role="menu" v-if="open" v-on-clickaway="close"
         body-scroll-lock-ignore>
      <div ref="dropdownContent" class="dropdown__content">
        <div v-if="searchable" class="dropdown__filter">
          <input
            ref="searchField"
            v-model="searchTerm"
            type="search"
            :placeholder="searchPlaceholder"
            autocomplete="off"
            class="dropdown__search-input"
            @input="onSearchInput"
          >
        </div>
        <tree-node
          :node="store.tree"
          :select-leafs-only="selectLeafsOnly"
          :selected-branch="store.selectedBranch"
          :is-root="true"
          @select-node="selectNode"
        ></tree-node>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { DropdownPopper } from './dropdown/DropdownPopper'
import TreeSelectStore from './tree/TreeSelectStore'
import TreeNode from './tree/TreeNode'

export default {
  mixins: [clickaway],
  dropdownPopper: null,
  components: {
    TreeNode,
  },
  data: function () {
    return {
      store: new TreeSelectStore(this.tree, this.emptyTitle, this.selectLeafsOnly),
      open: false,
      searchTerm: '',
      searchTimer: null
    }
  },
  props: {
    value: String,
    tree: Object,
    emptyTitle: String,
    disabled: {
      type: Boolean,
      default: false
    },
    selectLeafsOnly: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: ''
    }
  },
  created () {
    this.$options.dropdownPopper = new DropdownPopper()
  },
  beforeDestroy () {
    this.$options.dropdownPopper.destroy()
  },
  watch: {
    value (value) {
      if (value) {
        this.store.setSelectedBranch(value)
      } else {
        this.store.clearSelectedBranch()
      }
    }
  },
  mounted () {
    if (this.value) {
      this.store.setSelectedBranch(this.value)
      const selectedNode = this.store.getSelectedTreeNode()
      if (selectedNode !== null) {
        this.$emit('initially-selected-node', selectedNode)
      }
    }
  },
  methods: {
    onSearchInput () {
      this._clearSearchTimeout()
      this.searchTimer = setTimeout(() => {
        this.search()
      }, 300)
    },
    _clearSearchTimeout () {
      if (this.searchTimer !== null) {
        clearTimeout(this.searchTimer)
        this.searchTimer = null
      }
    },
    search () {
      this._clearSearchTimeout()
      this.store.search(this.searchTerm)
    },
    close () {
      if (this.searchable && '' !== this.searchTerm) {
        this.searchTerm = ''
        this.store.search(this.searchTerm)
      }
      this.open = false
      this.$options.dropdownPopper.destroy()
    },
    toggle () {
      this.open = !this.open
      if (this.open) {
        this.createPopper()
        return
      }
      this.close()
    },
    selectNode (node, event) {
      if (node.isNotSelectable) {
        return
      }
      this.$emit('input', node.id, node)
      this.store.setSelectedBranch(node.id)
      this.open = false
    },
    async createPopper () {
      await this.$nextTick()
      this.$options.dropdownPopper.createScrollablePopper(
        this.$refs.dropdownContent,
        this.$refs.trigger,
        this.$refs.dropdownMenu
      )
      if (this.searchable) {
        this.$refs.searchField.focus()
      }
    }
  }
}
</script>
