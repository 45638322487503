'use strict'

import MaintenanceAnnouncement from '../components/MaintenanceAnnouncement'
import Vue from 'vue'

export default function setupMaintenanceAnnouncement () {
  const maintenanceAnnouncementApp = document.getElementById('maintenance-announcement-app')
  if (maintenanceAnnouncementApp !== null) {
    new Vue({ // eslint-disable-line no-new
      el: maintenanceAnnouncementApp,
      components: { MaintenanceAnnouncement }
    })
  }
}
