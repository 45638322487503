<template>
  <div>
    <div class="result-grid__tabs" :class="{'result-grid__tabs--loading':  store.loading}">
      <tabs
        tabs-additional-class="tabs--vertical"
        :animated="false"
        :truncate="true"
        :disabled="store.loading"
      >
        <tab
          v-for="(user, userIndex) in store.group.members"
          :name="user.fullName"
          :uid="userIndex"
          :key="user.fullName"
          :active="userIndex === 0"
          :icon="userIcons[user.userReference]"
          @onSelect="selectUserTab(user)"
        >
          <result-entry-grid-user
            v-if="activeUserReference === user.userReference"
            :key="user.fullName + '_' + index"
            :user="user"
            :store="store"
            :folio-test-service="folioTestService"
            @stored="storedUser"
            @reset="resetUser"
          ></result-entry-grid-user>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import Tab from '../components/tabs/Tab'
import Tabs from '../components/tabs/Tabs'
import FolioTestService from './FolioTestService'
import ResultEntryGridUser from './ResultEntryGridUser'
import FolioResultsStore from './folio-results/FolioResultsStore'

export default {
  components: {
    Tab,
    Tabs,
    ResultEntryGridUser
  },
  props: {
    store: FolioResultsStore,
    stream: Object,
    token: String,
    test: Object,
    group: Object,
  },
  data() {
    return {
      index: 0,
      activeUserReference: '',
      userIcons: this.getUserIcons(),
      folioTestService: new FolioTestService(
        this.stream.code,
        this.token
      )
    }
  },
  mounted() {
  },
  methods: {
    getUserIcons() {
      return this.updateUserIcons({})
    },
    updateUserIcons(object) {
      for (const user of this.store.group.members) {
        object[user.userReference] = this.store.getStoredResultIconForUserReference(user.userReference)
      }

      return object
    },
    selectUserTab(user) {
      this.activeUserReference = null === user ? '' : user.userReference
    },
    resetUser() {
      this.updateUserIcons(this.userIcons)
      this.index++
    },
    storedUser() {
      this.updateUserIcons(this.userIcons)
    }
  }
}
</script>
