<template>
  <div :class="{'has-loading-bar': isLoading}">
    <warning v-if="error" :message="error"/>
    <success v-if="successMessage" :message="successMessage"/>
    <StickyTable :sticky-heads="2" :sticky-columns="1" class="table table--full-width cockpit-results--hover">
      <thead>
      <tr>
        <th class="no-border-right"></th>
        <th colspan="6">
          <div class="cockpit-results--toggles mb-1">
            {{ $t('cockpit.my_content.show_archived_learninggoals') }}
            <toggle-button
              id="show-archived"
              :label-on="$t('generic.term.yes')"
              :label-off="$t('generic.term.no')"
              :checked="store.showArchived"
              @change="toggleShowArchived"
            />
          </div>
        </th>
        <th class="no-border-bottom"></th>
      </tr>
      <tr class="cockpit-results--heading vertical-align-bottom">
        <th></th>
        <th style="width:100%" v-html="$t('generic.term.extra_practice_with_goal')"></th>
        <th class="whitespace-nowrap text-align-center" v-html="$t('cockpit.my_content.education_level')"></th>
        <th class="whitespace-nowrap text-align-center" v-html="$t('generic.term.progress')"></th>
        <th class="whitespace-nowrap text-align-center" v-html="$t('generic.term.average')"></th>
        <th class="whitespace-nowrap" v-html="$t('cockpit.my_content.visible_for_student')"></th>
        <th v-html="$t('generic.term.actions')"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(result, resultIndex) in store.filteredResults">
        <my-content-result
          v-for="(itemResult, index) in result.itemResults"
          :key="itemResult.id"
          :user="result.user"
          :first-row="index === 0"
          :last-row="index === result.itemResults.length - 1"
          :item-result="itemResult"
          :my-content-token="myContentToken"
          :stream-code="streamCode"
          :edition-url="editionUrl"
          :result="result"
          :store="store"
        ></my-content-result>
        <assign-my-content
          v-if="result.itemResults.length === 0"
          :user="result.user"
        ></assign-my-content>
      </template>
      </tbody>
    </StickyTable>
  </div>
</template>

<script>
import Warning from '../../../components/notification/Warning'
import Success from '../../../components/notification/Success'
import MyContentResult from './MyContentResult'
import SelectDropdown from '../../../components/SelectDropdown'
import MyContentStateFilter from './MyContentStateFilter'
import TreeSelect from '../../../components/TreeSelect'
import StickyTable from '../../../components/StickyTable'
import AssignMyContent from './AssignMyContent'
import ToggleButton from '../../../components/buttons/ToggleButton'
import MyContentResultsStore from './MyContentResultsStore'

export default {
  components: { ToggleButton, AssignMyContent, MyContentStateFilter, SelectDropdown, MyContentResult, Warning, Success, TreeSelect, StickyTable },

  props: {
    myContentResults: Object,
    myContentToken: String,
    streamCode: String,
    editionUrl: String,
    assignmentsExtraTree: Object
  },
  data () {
    return {
      isLoading: false,
      error: null,
      successMessage: null,
      stateFilter: {},
      store: new MyContentResultsStore(
        this.myContentResults.results,
        false,
        this.streamCode,
        this.myContentToken
      )
    }
  },
  methods: {
    toggleShowArchived(showArchived) {
      this.store.showArchived = showArchived
      this.store.updateFilteredResults()
    }
  }
}
</script>
