var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.redesign && _vm.unseenAnnouncements[_vm.announcementIndex]
    ? _c(
        "modal-dialog",
        {
          attrs: {
            "show-close-button": true,
            title: _vm.unseenAnnouncements[_vm.announcementIndex].title,
            "close-type": "primary",
            "primary-action-label": _vm.buttonLabel,
          },
          on: { close: _vm.onClose },
        },
        [
          _c("div", {
            attrs: { slot: "content" },
            domProps: {
              innerHTML: _vm._s(
                _vm.unseenAnnouncements[_vm.announcementIndex].message
              ),
            },
            slot: "content",
          }),
        ]
      )
    : _vm.redesign && _vm.unseenAnnouncements[_vm.announcementIndex]
    ? _c("info-dialog", {
        attrs: {
          title: _vm.unseenAnnouncements[_vm.announcementIndex].title,
          content: _vm.unseenAnnouncements[_vm.announcementIndex].message,
        },
        on: { close: _vm.onClose },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }