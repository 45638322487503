<template>
  <label
    :for="'activity-toggle-' + store.contentPath"
    class="modal__status-toggle question-label question-label--type-selfscore-complete"
    :class="{ 'question-label--score-good' : store.added }">
    <input type="checkbox" :id="'activity-toggle-' + store.contentPath"
           :name="'activity-toggle-' + store.contentPath"
           class="invisible"
           v-model="store.added"
           @change.self="onChange" />
    <span :data-test="'lesson-topic-modal-activity-toggle-' + index" v-show="store.added">{{ $t('cockpit.activities.card.modal.added-to-my-activities-label') }}</span>
  </label>
</template>

<script>
  import axios from 'axios'
  import mixins from '../util/mixins'
  import ActivityStore from './ActivityStore'
  import { ACTION_EVENT, EventBus } from "../common/EventBus";
  import {
    ACTION_ACTIVITY_ADDED, ACTION_ACTIVITY_REMOVED
  } from "../common/ThasActionEventActionType";

  const ADD_ROUTE = 'cockpit_add_activity'
  const REMOVE_ROUTE = 'cockpit_remove_activity'

  export default {
    mixins: [ mixins ],

    props: {
      index: String,
      store: ActivityStore
    },

    methods: {
      onChange () {
        EventBus.$emit(
          ACTION_EVENT,
          window.location.href,
          this.store.added ? ACTION_ACTIVITY_ADDED : ACTION_ACTIVITY_REMOVED,
          {
            'added': this.store.added,
            'group': this.store.groupId,
            'cardContentPath': this.store.contentPath
          }
        )

        axios({
          method: 'post',
          url: this.getUrl(this.store.added, ADD_ROUTE, REMOVE_ROUTE, this.store),
          data: {
            added: this.store.added,
            _token: this.store.token
          }
        })
      }
    }
  }
</script>
