var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        buttons: [
          {
            label: "Sluiten",
            type: "primary",
            handler: () => _vm.$emit("close"),
          },
        ],
        "close-button": true,
        backdrop: true,
        "dismiss-via-backdrop": true,
        title: _vm.title,
        type: _vm.ModalType.condensed(),
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("p", {
        staticClass: "my-4",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }