var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { ref: "form", staticClass: "form" }, [
    _c(
      "div",
      { staticClass: "result-grid__questions" },
      [
        _c(
          "div",
          { staticClass: "result-grid__question-header result-grid__index" },
          [_vm._v("\n      Opdracht\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "result-grid__question-header" }, [
          _vm._v("\n      Aantal goed\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "result-grid__question-header" }),
        _vm._v(" "),
        _vm._l(
          _vm.store.getQuestionsForUser(_vm.user),
          function (question, index) {
            return [
              _c("div", {
                staticClass:
                  "result-grid__question result-grid__question-index",
                attrs: { id: _vm.user.userReference + "_" + index },
                domProps: { innerHTML: _vm._s(question.label) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "result-grid__question" }, [
                null !== question.entryType
                  ? _c("div", { staticClass: "result-grid__question-score" }, [
                      _c("input", {
                        staticClass: "result-entry-grid__input",
                        class: {
                          "result-entry-grid__input--invalid":
                            !_vm.store.resultIsValid(
                              question.id,
                              _vm.user.userReference
                            ),
                        },
                        attrs: {
                          type: "text",
                          maxlength: question.points.toString().length,
                          title: question.label,
                          "data-user-reference": _vm.user.userReference,
                          "data-question-id": question.id,
                          autocomplete: "off",
                          disabled: "default" !== question.entryType.type,
                        },
                        domProps: { value: _vm.getPoints(question) },
                        on: {
                          input: function ($event) {
                            return _vm.validateInput(
                              $event.target,
                              question.points,
                              _vm.user.userReference,
                              question.id
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("/ " + _vm._s(question.points))]),
                    ])
                  : _c("div", { staticClass: "result-grid__question-score" }, [
                      _c("div", {
                        staticClass: "result-entry-grid__no-input",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("generic.term.not_applicable_short")
                          ),
                        },
                      }),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "result-grid__question" },
                [
                  null !== question.entryType &&
                  "words" === question.entryType.type
                    ? _c("result-entry-words", {
                        ref: "words",
                        refInFor: true,
                        attrs: {
                          user: _vm.user,
                          question: question,
                          "result-data": _vm.store.getResultData(
                            question.id,
                            _vm.user.userReference
                          ),
                        },
                        on: { updateScore: _vm.updateScoreFromWords },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.store.resultIsValid(question.id, _vm.user.userReference)
                    ? _c("div", {
                        staticClass: "error--msg",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "cockpit.test.result_entry.notification.max_score",
                              { count: question.points }
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  null === question.entryType
                    ? _c("div", {
                        staticClass: "result-entry-grid__no-input-message",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "cockpit.test.result_entry.prior_knowledge_message"
                            )
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "result-tab__footer" }, [
      _c("div", { staticClass: "result-tab__actions" }, [
        _c(
          "div",
          { staticClass: "result-tab__submitted-at" },
          [
            _c("span", { staticClass: "result-tab__submitted-at-title" }, [
              _vm._v(
                _vm._s(_vm.$t("cockpit.test.result_entry.submitted_at")) + ":"
              ),
            ]),
            _vm._v(" "),
            _c("date-time-input", {
              attrs: {
                "input-class": "form-group__field",
                value: _vm.getSubmittedAt(),
                "pick-time": false,
                "position-top": true,
                id: "submitted_at",
                "allow-future-dates": false,
              },
              on: { input: _vm.setSubmittedAt },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "result-tab__action-buttons" }, [
          _c(
            "button",
            {
              ref: "buttonReset",
              staticClass:
                "button button--cockpit button--primary button--clear",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clickAndResetResults()
                },
              },
            },
            [
              _c("i", { staticClass: "icon-rubber-eraser mr-1" }),
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("cockpit.test.modal.delete")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("button", {
            ref: "buttonSave",
            staticClass: "button button--cockpit button--primary button--save",
            attrs: { type: "submit" },
            domProps: { innerHTML: _vm._s(_vm.$t("cockpit.test.modal.save")) },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.saveResultsByClick()
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        ref: "resetMsg",
        staticClass: "error notification",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("cockpit.test.result_entry.notification.reset")
          ),
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "savedMsg",
        staticClass: "success notification",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("cockpit.test.result_entry.notification.saved")
          ),
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "noPointsMsg",
        staticClass: "error notification",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("cockpit.test.result_entry.notification.incomplete")
          ),
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "notSavedPointsMsg",
        staticClass: "error notification",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("cockpit.test.result_entry.notification.not_saved_points")
          ),
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: "notSavedDateMsg",
        staticClass: "error notification",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t("cockpit.test.result_entry.notification.not_saved_date")
          ),
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }