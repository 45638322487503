<template>
  <div>
    <h5 class="lesson-detail-view__block-title mb-2" v-html="$t('cockpit.unit.title') + ' ' + blockTitle"></h5>
    <ul v-if="activeWeek != null" class="lesson-navigation__week-component lesson-navigation__week-component--current-week">
      <li class="lesson-navigation__item lesson-navigation__item--0 lesson-navigation__item--week-overview"
          :class="[ { 'lesson-navigation__item--active' : active } ]">
        <a v-if="selectWeek" :href="weekLink" class="lesson-navigation__item-link lesson-navigation__item-link-week"
           :title="activeWeekTitle">{{ activeWeekTitle }}</a>
        <div v-else class="lesson-navigation__item-no-link lesson-navigation__item-no-link-week" :title="activeWeekTitle">{{ activeWeekTitle }}</div>
      </li>

      <lesson-item
        v-for="(lesson, index) in activeWeek.lessons"
        :lesson="lesson"
        :previous-lesson="activeWeek.lessons[index - 1]"
        :next-lesson="activeWeek.lessons[index + 1]"
        :key="index"
        :route-service="routeService"
        type="active-week"
      ></lesson-item>
    </ul>
  </div>
</template>

<script>
  import LessonItem from './LessonItem'
  import ContentPath from '../../util/ContentPath'
  import CockpitRouteService from '../../CockpitRouteService'

  function getActiveWeek (blocks) {
    const isWeekActive = (week) => {
      if (!week.active) {
        return week.lessons.filter(lesson => lesson.active).length > 0
      }
      return week.active
    }

    const block = getActiveBlock(blocks)

    // if an active week is found, use that, if not pick the first week of the block
    // or if a complete block can be selected, return null
    return block.weeks.filter(isWeekActive)[0] || block.weeks[0]
  }

  function getActiveBlock (blocks) {
    const isBlockActive = block => block.active

    //if an active block is found, use that, if not, pick the first block
    return blocks.filter(isBlockActive)[0] || blocks[0]
  }

  export default {
    components: {
      LessonItem
    },
    props: {
      blocks: Array,
      selectWeek: Boolean,
      routeService: CockpitRouteService
    },
    computed: {
      blockTitle() {
        const activeBlock = getActiveBlock(this.blocks)

        return activeBlock.number + ' ' + (activeBlock.title !== '' ? ' ' + activeBlock.title : '')
      },
      activeWeekTitle() {
        const activeWeek = getActiveWeek(this.blocks);

        return activeWeek.number + ' ' + (activeWeek.title !== '' ? ' ' + activeWeek.title : '')
      },
      activeWeek () {
        return getActiveWeek(this.blocks)
      },
      activeBlock () {
        return getActiveBlock(this.blocks)
      },
      active () {
        return this.activeWeek.active
      },
      weekLink () {
        return this.routeService.weekLink(new ContentPath(this.activeWeek.contentPath))
      }
    }
  }
</script>
