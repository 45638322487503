var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "learn-lines pt-2" }, [
    _c(
      "header",
      { staticClass: "learn-lines__header" },
      [
        _c("dropdown", {
          key:
            "activeDomain--" + (this.activeDomain ? this.activeDomain.id : ""),
          staticClass: "dropdown--minimalistic dropdown--primary",
          attrs: {
            options: _vm.domains,
            "predefined-option": this.activeDomain,
            "trigger-only": false,
            labelAttribute: "title",
          },
          on: { "option-selected": _vm.setActiveDomain },
        }),
        _vm._v(" "),
        _c("dropdown", {
          key:
            "activeSubDomain--" +
            (this.activeSubDomain ? this.activeSubDomain.id : ""),
          staticClass: "dropdown--minimalistic dropdown--secondary",
          attrs: {
            "data-test": "learn-lines-secondary-dropdown",
            options: _vm.subDomains,
            "trigger-only": false,
            "predefined-option": _vm.activeSubDomain,
            labelAttribute: "title",
          },
          on: { "option-selected": _vm.setActiveSubdomain },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("section", { staticClass: "learn-lines__content" }, [
      _c(
        "div",
        { staticClass: "learn-lines__blocks-grid" },
        _vm._l(_vm.blocks, function (block) {
          return _c("div", { staticClass: "learn-lines__block-wrapper" }, [
            _c("span", {
              staticClass: "learn-lines__block-title",
              domProps: { innerHTML: _vm._s(block.aggregatedTitle) },
            }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "learn-lines__block-item" },
              _vm._l(_vm.blockLessons(_vm.lessons, block), function (treeNode) {
                return _c(
                  "li",
                  {
                    staticClass:
                      "lesson-navigation__item learn-lines__lesson-item",
                    class: _vm.isActive(treeNode)
                      ? "lesson-navigation__item--active"
                      : treeNode.lesson.completed
                      ? "lesson-navigation__item--completed"
                      : "",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "lesson-navigation__item-link lesson-navigation__item-link-day",
                        attrs: {
                          "data-test":
                            "learn-lines-block-item-" + treeNode.lesson.number,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.navigateToLesson(
                              treeNode.lesson.contentPath
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(treeNode.lesson.number) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }