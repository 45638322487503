<template>
  <button class="button button--cockpit print-content--hidden" @click.prevent="print" data-test="print-page-content-button">
    <slot></slot>
    <span v-if="!this.$slots.default"><i class="icon-printer"></i> {{ $t('generic.term.print') }}</span>
  </button>
</template>

<script>
  export default {
    props: {
      selector: {
        type: String,
        default: '.print-content'
      }
    },
    beforeDestroy() {
      document.body.classList.toggle('with-print-content', false)
      const container = document.querySelector('.print-content-container')
      if (null !== container) {
        container.remove()
      }
    },
    methods: {
      getContainer() {
        let container = document.querySelector('.print-content-container')
        if (null === container) {
          document.body.classList.toggle('with-print-content', true)
          container = document.createElement('div')
          container.classList.add('print-content-container')
          container.classList.add('print-content--visible')
          document.body.appendChild(container)
        }
        return container
      },
      unhideChildren (element) {
        Array.from(element.children).forEach(child => {
          child.classList.toggle('print-content--visible', true)
          this.unhideChildren(child)
        })
      },
      print () {
        const container = this.getContainer()

        container.innerHTML = ''

        const printableElements = document.querySelectorAll(this.selector);
        printableElements.forEach(element => {
          container.innerHTML += element.innerHTML
        })

        this.unhideChildren(container)

        window.print()
      }
    }
  }
</script>
