var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "toggle-button",
      class: {
        "toggle-button--on": _vm.isChecked,
        "toggle-button--disabled": _vm.disabled,
      },
      attrs: { for: "toggle-button-" + _vm.id },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isChecked,
            expression: "isChecked",
          },
        ],
        attrs: {
          type: "checkbox",
          id: "toggle-button-" + _vm.id,
          name: "toggle-button-" + _vm.id,
        },
        domProps: {
          checked: Array.isArray(_vm.isChecked)
            ? _vm._i(_vm.isChecked, null) > -1
            : _vm.isChecked,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.isChecked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.isChecked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.isChecked = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.isChecked = $$c
              }
            },
            function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.onChange.apply(null, arguments)
            },
          ],
        },
      }),
      _vm._v(" "),
      _vm.isChecked
        ? _c("span", {
            staticClass: "toggle-button__label-on",
            domProps: { innerHTML: _vm._s(_vm.labelOn) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "toggle-button__switch" }),
      _vm._v(" "),
      !_vm.isChecked
        ? _c("span", {
            staticClass: "toggle-button__label-off",
            domProps: { innerHTML: _vm._s(_vm.labelOff) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }