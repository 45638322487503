var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bar" }, [
    _c("div", { staticClass: "progress-bar__tube" }, [
      _c("span", {
        staticClass: "progress-bar__sand",
        style: { maxWidth: _vm.percentage + "%" },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "progress-bar__timer" }, [
      _vm._v("\n    " + _vm._s(_vm.countdown) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }