<template>
  <span v-html="renderedLinks"></span>
</template>

<script>
  export default {
    props: {
      links: Array
    },
    data() {
      return {
        renderedLinks: this.renderLinks()
      }
    },
    methods: {
      renderLinks() {
        let links = [];

        for (const link of this.links) {
          links.push(this.createLink(link))
        }

        if (1 === links.length) {
          return this.$t('cockpit.progress.results.single_link', {'link': links[0]})
        }

        const lastLink = links.pop()
        const otherLinks = links.join(', ')

        return this.$t('cockpit.progress.results.multiple_links', {'links': otherLinks, 'lastLink': lastLink})
      },
      createLink(link) {
        return '<a href="' + link.href + '">' + link.label + '</a>'
      }
    }
  }
</script>
