var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h5", {
      staticClass: "lesson-detail-view__block-title mb-2",
      domProps: {
        innerHTML: _vm._s(_vm.$t("cockpit.unit.title") + " " + _vm.blockTitle),
      },
    }),
    _vm._v(" "),
    _vm.activeWeek != null
      ? _c(
          "ul",
          {
            staticClass:
              "lesson-navigation__week-component lesson-navigation__week-component--current-week",
          },
          [
            _c(
              "li",
              {
                staticClass:
                  "lesson-navigation__item lesson-navigation__item--0 lesson-navigation__item--week-overview",
                class: [{ "lesson-navigation__item--active": _vm.active }],
              },
              [
                _vm.selectWeek
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "lesson-navigation__item-link lesson-navigation__item-link-week",
                        attrs: {
                          href: _vm.weekLink,
                          title: _vm.activeWeekTitle,
                        },
                      },
                      [_vm._v(_vm._s(_vm.activeWeekTitle))]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "lesson-navigation__item-no-link lesson-navigation__item-no-link-week",
                        attrs: { title: _vm.activeWeekTitle },
                      },
                      [_vm._v(_vm._s(_vm.activeWeekTitle))]
                    ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.activeWeek.lessons, function (lesson, index) {
              return _c("lesson-item", {
                key: index,
                attrs: {
                  lesson: lesson,
                  "previous-lesson": _vm.activeWeek.lessons[index - 1],
                  "next-lesson": _vm.activeWeek.lessons[index + 1],
                  "route-service": _vm.routeService,
                  type: "active-week",
                },
              })
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }