var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "custom-button",
    _vm._b(
      {
        attrs: { type: "primary" },
        on: {
          click: function ($event) {
            return _vm.$emit("click", $event)
          },
        },
      },
      "custom-button",
      _vm.$props,
      false
    ),
    [_vm.$slots.default ? _vm._t("default") : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }