var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _c("td", { staticClass: "whitespace-nowrap" }, [
        _vm._v(_vm._s(_vm.user.fullName)),
      ]),
      _vm._v(" "),
      _vm.showEducationLevelColumn
        ? _c("td", {
            domProps: {
              innerHTML: _vm._s(
                _vm.hasResult && null !== _vm.educationLevel
                  ? _vm.educationLevel.identifier
                  : ""
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "td",
        [
          _c("score", {
            attrs: { score: _vm.averageScore, link: _vm.getLink() },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.questions, function (question) {
        return _c(
          "td",
          [
            _c("score", {
              attrs: {
                score: _vm.getScore(question),
                "empty-value": _vm.emptyScoreValue,
                link: _vm.getLink(question),
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("td"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }