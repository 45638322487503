var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !(_vm.store.cleanup && !_vm.store.added)
        ? _c(
            "activity-card",
            {
              attrs: {
                "container-class": _vm.containerClass,
                "trigger-class": _vm.triggerClass,
                store: _vm.store,
                index: _vm.index,
              },
              on: { click: _vm.onClick },
            },
            [_vm._t("card")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.opened
        ? _c(
            "activity-modal",
            {
              ref: "modal",
              attrs: {
                title: _vm.title,
                image: _vm.image,
                meta: _vm.meta,
                "meta-leerjaar-label": _vm.metaLeerjaarLabel,
                "view-event-url": _vm.viewEventUrl,
                store: _vm.store,
                index: _vm.index,
              },
              on: { close: _vm.close },
            },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }