var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.learningGoals, function (learningGoal) {
      return _c(
        "a",
        {
          staticClass:
            "flexbox flex-align-items--center p-1 -mx-1 learning-goal",
          attrs: { href: _vm.getLink(learningGoal) },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _vm._v(_vm._s(learningGoal.learningGoal)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "flexbox flex-wrap--wrap learning-goal__lessons ml-1",
            },
            _vm._l(learningGoal.lessons, function (lesson, index) {
              return _c(
                "div",
                {
                  staticClass:
                    "lesson-navigation__item lesson-navigation__item--inversed lesson-navigation__item--small",
                  class: _vm.lessonClass(learningGoal.lessons, index),
                },
                [
                  _c(
                    "div",
                    { staticClass: "lesson-navigation__item-no-link" },
                    [_vm._v(_vm._s(lesson.number))]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }