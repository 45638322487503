<template>
  <button class="button-group__button button-group__button--outline button button--tiny"
          :class="buttonClass"
          v-html="educationLevel.name"
  ></button>
</template>

<script>
import EducationLevel from '../util/EducationLevel'

export default {
  props: {
    educationLevel: EducationLevel,
    currentEducationLevel: EducationLevel,
    selectedEducationLevel: EducationLevel,
    advicedEducationLevel: EducationLevel
  },
  computed: {
    isCurrent () {
      return this.educationLevel.equals(this.currentEducationLevel)
    },
    isSelected () {
      return this.educationLevel.equals(this.selectedEducationLevel)
    },
    hasAdviced () {
      return null !== this.advicedEducationLevel
    },
    isAdviced () {
      if (!this.hasAdviced) {
        return false
      }
      return this.educationLevel.equals(this.advicedEducationLevel)
    },
    buttonClass() {
      if (this.isSelected && (this.isAdviced || !this.hasAdviced)) {
        return 'button-group__button--selected'
      }

      if (this.isSelected && !this.isAdviced) {
        return 'button-group__button--teacher-selected'
      }

      if (!this.isSelected && this.isAdviced) {
        return 'button-group__button--teacher-deselected'
      }

      return ''
    }
  }
}
</script>
