var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "button-group__button button-group__button--outline button button--tiny",
      class: _vm.buttonClass,
      attrs: { disabled: _vm.educationLevel.state.isOpen() },
    },
    [_vm._v(_vm._s(_vm.educationLevel.educationLevel))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }