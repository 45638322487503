<template>
  <div class="advice-selector">
    <div class="button-group button-group--cockpit">
      <option-selector-button
        v-for="option in myOptions"
        :key="option.key"
        :option="option"
        :options="myOptions"
        :disabled="disabled"
        :button-style="buttonStyle"
        @click.native="clickButton(option)"
      ></option-selector-button>
    </div>
    <div class="advice-selector-reset">
      <button
        v-if="withReset && showReset"
        class="button button--tiny ml-1"
        :disabled="disabled"
        @click="clickReset"
      >
        <i class="icon-replay"></i>
      </button>
    </div>
  </div>
</template>

<script>

import OptionSelectorButton from './OptionSelectorButton'

export default {
  components: { OptionSelectorButton },
  props: {
    context: {
      type: Object,
      default: () => ({})
    },
    options: Array,
    withReset: {
      type: Boolean,
      default: true
    },
    canDeselect: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonStyle: {
      type: String,
      default: 'button--tiny'
    },
    resetToInitialSelected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const myOptions = this.options.map(option => {
      option.initialSelected = option.selected
      return option
    })

    return {
      myOptions: myOptions,
      current: myOptions.find(option => option.current),
      selected: myOptions.find(option => option.selected),
      adviced: myOptions.find(option => option.adviced),
      initialSelected: myOptions.find(option => option.initialSelected),
      mayDeselect: this.canDeselect || myOptions.length < 2
    }
  },
  computed: {
    showReset() {
      const hasSelected = undefined !== this.selected
      const hasAdviced = undefined !== this.adviced

      if (!this.resetToInitialSelected) {
        if (!hasSelected && !hasAdviced) {
          return false
        }
        if ((!hasSelected && hasAdviced) || (hasSelected && !hasAdviced)) {
          return true
        }
        return this.selected.key !== this.adviced.key
      }

      const hasInitialSelected = undefined !== this.initialSelected
      if (!hasSelected && !hasInitialSelected) {
        return false
      }
      if ((!hasSelected && hasInitialSelected) || (hasSelected && !hasInitialSelected)) {
        return true
      }
      return this.selected.key !== this.initialSelected.key
    }
  },
  methods: {
    clickButton(selectedOption) {
      this.myOptions.forEach(option => {
        if (option.key === selectedOption.key) {
          option.selected = this.mayDeselect ? !option.selected : true
        } else {
          option.selected = false
        }
      })
      this.selected = this.myOptions.find(option => option.selected)
      this.$emit('select', this.context, this.selected, this.myOptions)
    },
    clickReset() {
      if (this.resetToInitialSelected) {
        this.myOptions.forEach(option => {
          option.selected = option.initialSelected
        })
      } else {
        this.myOptions.forEach(option => {
          option.selected = option.adviced
        })
      }

      this.selected = this.myOptions.find(option => option.selected)
      this.$emit('reset', this.context, this.selected, this.myOptions)
    }
  }
}

</script>
