var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mr-3 learning-goal__goals_options" }, [
      _c("label", [
        _c("input", {
          attrs: { type: "radio", name: "editList", value: "always" },
        }),
        _vm._v("Nog\n  "),
      ]),
      _vm._v(" "),
      _c("label", [
        _c("input", {
          attrs: { type: "radio", name: "editList", value: "never" },
        }),
        _vm._v("Nu\n  "),
      ]),
      _vm._v(" "),
      _c("label", [
        _c("input", {
          attrs: { type: "radio", name: "editList", value: "costChange" },
        }),
        _vm._v("Al\n  "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }