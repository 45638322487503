var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "education-level-selector" }, [
    _c(
      "div",
      { staticClass: "button-group button-group--cockpit" },
      _vm._l(
        _vm.educationLevels.getEducationLevels(),
        function (educationLevel, index) {
          return _c("education-level-selector-education-level", {
            key: educationLevel.identifier,
            attrs: {
              "education-level": educationLevel,
              "current-education-level": _vm.currentEducationLevel,
              "selected-education-level": _vm.selectedEducationLevel,
              "adviced-education-level": _vm.advicedEducationLevel,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("select-education-level", educationLevel)
              },
            },
          })
        }
      ),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "education-level-selector-reset" }, [
      _vm.withReset && _vm.showReset
        ? _c(
            "button",
            {
              staticClass: "button button--tiny ml-1",
              on: {
                click: function ($event) {
                  return _vm.$emit("reset-education-level")
                },
              },
            },
            [_c("i", { staticClass: "icon-replay" })]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }