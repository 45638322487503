var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-modal form-modal--enter-test-results" },
    [
      _c("div", {
        staticClass: "form-modal__background",
        on: { click: _vm.close },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-modal__container form-modal__container--wider",
          class: { "has-loading-bar": _vm.store.loading },
        },
        [
          _c("button", {
            staticClass: "form-modal__close",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.close.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "h2",
            {
              staticClass:
                "labeled-title form-modal__title form-modal__title--full-width mb-2",
            },
            [
              _c("span", { staticClass: "labeled-title__text" }, [
                _vm._v(_vm._s(_vm.store.test.subject)),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("result-entry-grid", {
            attrs: {
              store: _vm.store,
              stream: _vm.stream,
              token: _vm.token,
              group: _vm.group,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Geef in dit overzicht per opgave aan wat de leerling goed gedaan heeft."
      ),
      _c("br"),
      _vm._v(
        "Voor leerlingen die de toets digitaal hebben gemaakt hoef je hier niets te doen."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }