var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "test-list__actions" },
    [
      _c("edit-test-button", {
        staticClass: "mr-half",
        attrs: {
          stream: _vm.stream,
          group: _vm.group,
          "initial-test": _vm.test,
          token: _vm.token,
          tests: _vm.tests,
        },
      }),
      _vm._v(" "),
      _c("delete-test-button", {
        staticClass: "mr-half",
        attrs: {
          stream: _vm.stream,
          "scheduled-test": _vm.test,
          "selected-test": _vm.findScheduledTest(_vm.tests, _vm.test),
          token: _vm.token,
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "button button--small button--primary button--cockpit button--cockpit-with-icon-only",
          on: { click: _vm.openScheduledTest },
        },
        [_c("i", { staticClass: "icon-share-2" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }