<template>
  <li class="lesson-navigation__item"
      :data-test="'lesson-navigation-' + type + '-' + blockNumber + '-' + lesson.number"
      :class="[ typeClasses, { 'lesson-navigation__item--completed' : lesson.completed, 'lesson-navigation__item--active' : lesson.active } ]">

    <a :href="lessonLink"
       class="lesson-navigation__item-link lesson-navigation__item-link-day"
       :title="lesson.label">{{ lesson.number }}</a>
  </li>
</template>

<script>
  import ContentPath from '../../util/ContentPath'
  import CockpitRouteService from '../../CockpitRouteService'

  export default {
    props: {
      type: String,
      blockNumber: String,
      lesson: Object,
      previousLesson: Object,
      nextLesson: Object,
      routeService: CockpitRouteService
    },
    data () {
      return {
        typeClasses: '',
        lessonLink: this.routeService.lessonLink(new ContentPath(this.lesson.contentPath))
      }
    },
    mounted () {
      if (this.previousLesson && !this.nextLesson) {
        this.typeClasses = 'lesson-navigation__item--type-rounded-right'
      } else if (this.nextLesson && !this.previousLesson) {
        this.typeClasses = 'lesson-navigation__item--type-rounded-left'
      } else if (this.nextLesson && this.previousLesson) {
        this.typeClasses = 'lesson-navigation__item--type-squared'
      }
    }
  }
</script>
