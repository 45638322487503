var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "StickyTable",
    {
      staticClass: "table table--full-width cockpit-results--hover",
      attrs: {
        "sticky-heads": 1,
        "sticky-columns": _vm.showEducationLevelColumn ? 3 : 2,
        "data-test": "test-results-table",
      },
    },
    [
      _c("thead", [
        _c(
          "tr",
          { staticClass: "cockpit-results--heading vertical-align-middle" },
          [
            _c("th"),
            _vm._v(" "),
            _vm.showEducationLevelColumn
              ? _c("th", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("generic.term.testlevel")),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$t("generic.term.score")))]),
            _vm._v(" "),
            _vm._l(_vm.test.questions, function (question) {
              return _c(
                "th",
                { staticClass: "text-align-center whitespace-nowrap" },
                [_vm._v(_vm._s(question.number))]
              )
            }),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "100%" } }, [_vm._v(" ")]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.users, function (user) {
            return [
              _c("test-result", {
                attrs: {
                  user: user,
                  questions: _vm.test.questions,
                  "show-education-level-column": _vm.showEducationLevelColumn,
                  "education-level": _vm.getUserEducationLevel(
                    user.userReference
                  ),
                  result: _vm.getUserResults(user.userReference),
                  "stream-code": _vm.streamCode,
                  "edition-url": _vm.editionUrl,
                  "content-path": _vm.test.contentPath,
                  "group-id": _vm.groupId,
                },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }