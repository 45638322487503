const educationLevelForMyContent = {
  Maat: {
    poor: 'Maat',
    unsatisfactory: 'Maat',
    good: 'Basis',
    perfect: 'Basis'
  },
  Basis: {
    poor: 'Maat',
    unsatisfactory: 'Basis',
    good: 'Basis'
  },
  Plus: {
    poor: 'Basis',
    unsatisfactory: 'Plus'
  }
}

import MyContentState from './my-content/MyContentState'

export default class EducationLevelForMyContent {
  getEducationLevelForMyContent (educationLevel, score) {
    if (educationLevelForMyContent[educationLevel]) {
      return educationLevelForMyContent[educationLevel][score.scoreCategoryName] || null
    }
    return null
  }

  getCombinedName (educationLevels) {
    return educationLevels.map(educationLevel => educationLevel.identifier).join('/')
  }

  getFirstIdentifier (educationLevels) {
    if (0 === educationLevels.length) {
      return ''
    }
    educationLevels.sort((a, b) => a.order > b.order ? 1 : -1)
    return educationLevels[0].identifier
  }

  getOrder (educationLevels) {
    return Math.min(...educationLevels.map(educationLevel => educationLevel.order))
  }

  getEducationLevelsForNode (node, assignedContentMapping) {
    const result = {
      hasOpenMyContent: false,
      educationLevels: []
    }
    if (!node.viewModels) {
      return result
    }
    const educationLevelMapping = {}
    for (const item of node.viewModels) {
      const assignedContent = assignedContentMapping[item.uniqueContentReference]
      const state = assignedContent === undefined ? MyContentState.createUnassigned() : new MyContentState(assignedContent.state)
      const educationLevelKey = this.getCombinedName(item.educationLevels)

      if (educationLevelMapping[educationLevelKey] === undefined) {
        educationLevelMapping[educationLevelKey] = true
        result.educationLevels.push({
          educationLevel: educationLevelKey,
          item: item,
          state: state,
          order: this.getOrder(item.educationLevels)
        })
      }

      if (state.isOpen()) {
        result.hasOpenMyContent = true
      }
    }
    result.educationLevels.sort((a, b) => a.order > b.order ? 1 : -1)
    return result
  }
}
