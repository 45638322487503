var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal-dialog-trigger",
        {
          attrs: {
            "secondary-action-label": _vm.$t("generic.term.no"),
            title: _vm.$t("cockpit.test.withdraw.title"),
            "close-type": "secondary",
            "confirm-link": "#",
            "container-class": "flexbox flex",
            "trigger-class": "flexbox flex",
            loading: _vm.loading,
          },
          on: { action: _vm.deleteTest },
        },
        [
          _c(
            "button",
            {
              staticClass: "button button--primary button--cockpit",
              attrs: { slot: "primary-action" },
              slot: "primary-action",
            },
            [_vm._v(_vm._s(_vm.$t("generic.term.yes")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "button button--small button--primary button--cockpit button--cockpit-with-icon-only",
              attrs: { slot: "trigger", "data-test": "delete-test-button" },
              slot: "trigger",
            },
            [_c("i", { staticClass: "icon-delete" })]
          ),
          _vm._v(" "),
          _c("p", { attrs: { slot: "content" }, slot: "content" }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("cockpit.test.withdraw.confirm_delete", {
                    title: _vm.selectedTest.title,
                  })
                ),
              },
            }),
            _c("br"),
            _vm._v(" "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("cockpit.test.withdraw.results_remain")
                ),
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }