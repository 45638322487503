var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      {
        staticClass: "lesson__topic column is-one-fifth",
        attrs: { "data-test": "lesson-topic-card-" + _vm.index },
      },
      [
        _c("div", { class: _vm.containerClass }, [
          _c(
            "span",
            {
              staticClass: "card--modal-trigger",
              class: [_vm.triggerClass, { "card--completed": _vm.completed }],
              on: { click: _vm.onClick },
            },
            [_vm._t("default")],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }