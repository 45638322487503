<template>
  <div class="dropdown" v-bind:class="{ 'dropdown--open': isOpen }" :data-test="'group-selection-dropdown'">
    <div ref="trigger" class="dropdown__trigger" @click="isOpen ? close() : open()">
      <span class="dropdown__trigger-label mr-1">{{ title }}</span>
      <i class="icon-tm-arrow-down dropdown__trigger-arrow"></i>
    </div>
    <div ref="dropdownMenu" class="dropdown__menu" id="dropdown-menu" role="menu" v-if="isOpen" v-on-clickaway="close">
      <div ref="dropdownContent" class="dropdown__content">
        <div class="dropdown__divider">
          <span class="dropdown__divider--label" data-test="group-selection-divider-settings"><i class="icon-settings mr-1"></i>{{ settingsTitle }}</span>
        </div>
        <div class="dropdown__setting" data-test="group-selection-setting-show-teachers">
          <input v-model="showTeachersChecked"
                 type="checkbox"
                 class="dropdown__checkbox dropdown__checkbox--show-teachers"
                 data-test="group-selection-setting-show-teachers-checkbox"
          />{{ showTeachersTitle }}
        </div>
        <a class="dropdown__item"
           v-if="showAllGroups"
           v-bind:class="{ 'dropdown__item--selected': selectedGroup === null }"
           @click="selectGroup(null)">{{ allGroupsTitle }}</a>
        <template v-if="manualGroups.length > 0">
          <div class="dropdown__divider">
            <span class="dropdown__divider--label" data-test="group-selection-divider-your-groups"><i class="icon-user-group mr-1"></i>{{ manualGroupsTitle }}</span>
          </div>
          <template v-for="(group, index) in manualGroups">
            <a class="dropdown__item"
               v-bind:class="{ 'dropdown__item--selected': isSelected(group) }"
               @click="selectGroup(group)" :data-test="'group-selection-manual-group-' + index">{{ group.name }}</a>
          </template>
        </template>
        <template v-if="staticGroups.length > 0">
          <div class="dropdown__divider">
            <span class="dropdown__divider--label" data-test="group-selection-divider-all-groups">{{ staticGroupsTitle }}</span>
          </div>
          <template v-for="(group, index) in staticGroups">
            <a class="dropdown__item"
               v-bind:class="{ 'dropdown__item--selected': isSelected(group) }"
               @click="selectGroup(group)" :data-test="'group-selection-static-group-' + index">{{ group.name }}</a>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway'
  import { DropdownPopper } from './dropdown/DropdownPopper'

  export default {
    showTeachersOnOpen: null,
    dropdownPopper: null,
    mixins: [clickaway],
    data: function () {
      return {
        isOpen: false,
        selectedGroup: this.initialSelectedGroup(),
        showTeachersChecked: this.showTeachers
      }
    },
    props: {
      groups: Array,
      selectGroupTitle: String,
      showAllGroups: Boolean,
      settingsTitle: String,
      showTeachersTitle: String,
      allGroupsTitle: String,
      manualGroupsTitle: String,
      staticGroupsTitle: String,
      selectedGroupId: String,
      showTeachers: Boolean
    },
    created() {
      this.$options.dropdownPopper = new DropdownPopper()
    },
    beforeDestroy () {
      this.$options.dropdownPopper.destroy()
    },
    computed: {
      title () {
        if (this.selectedGroup === null) {
          return this.selectGroupTitle
        }
        return this.selectedGroup.name
      },
      manualGroups () {
        return this.groups.filter(function (g) {
          return g.manual === true
        })
      },
      staticGroups () {
        return this.groups.filter(function (g) {
          return g.manual === false
        })
      }
    },
    methods: {
      initialSelectedGroup () {
        if ('' === this.selectedGroupId) {
          return null
        }

        const foundGroup = this.groups.find((group) => {
          return group.id === this.selectedGroupId
        })

        return foundGroup !== undefined ? foundGroup : null
      },
      isSelected (group) {
        return group === this.selectedGroup
      },
      close () {
        this.isOpen = false
        this.checkShowTeachersChanged()
        this.$options.dropdownPopper.destroy()
      },
      open () {
        this.isOpen = true
        this.createPopper()
        this.$options.showTeachersOnOpen = this.showTeachersChecked
      },
      checkShowTeachersChanged () {
        if (this.$options.showTeachersOnOpen !== this.showTeachersChecked) {
          this.$emit('show-teachers-changed', this.selectedGroup, this.showTeachersChecked)
        }
      },
      selectGroup (group) {
        this.selectedGroup = group
        this.$emit('group-selected', this.selectedGroup, this.showTeachersChecked)
        this.isOpen = false
      },
      async createPopper () {
        await this.$nextTick()
        this.$options.dropdownPopper.createScrollablePopper(
          this.$refs.dropdownContent,
          this.$refs.trigger,
          this.$refs.dropdownMenu
        )
      }
    }
  }
</script>
