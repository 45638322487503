var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("focus-trap", [
    _c(
      "div",
      {
        staticClass: "fixed inset-0 items-center flex items-center z-50 p-5",
        class: {
          "bg-black bg-opacity-50": _vm.backdrop,
          "cursor-pointer": _vm.dismissViaBackdrop,
        },
        attrs: {
          role: "dialog",
          "aria-modal": "true",
          "aria-label": _vm.title,
        },
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.backdropClicked.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            ref: "modalContent",
            staticClass:
              "bg-white m-auto rounded-lg shadow-xl cursor-auto relative flex flex-col",
            class: _vm.contentClasses,
            staticStyle: { "max-height": "min(600px, 100%)" },
          },
          [
            _vm.type.isEmpty() || _vm.type.isEmptyCondensed()
              ? [_vm._t("default")]
              : _c(
                  "div",
                  {
                    staticClass: "overflow-auto",
                    class: {
                      "p-16 pb-4": _vm.type.isDefault(),
                      "p-4": _vm.type.isCondensed(),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "text-center": _vm.type.isDefault(),
                          "text-left": _vm.type.isCondensed(),
                        },
                      },
                      [
                        _vm.icon
                          ? _c("div", {
                              staticClass: "w-28 mx-auto mb-12",
                              domProps: { innerHTML: _vm._s(_vm.icon) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.title
                          ? _c("h5", [_vm._v(_vm._s(_vm.title))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._t("default"),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "space-x-4 flex",
                        class: {
                          "mt-12 justify-center": _vm.type.isDefault(),
                          "mt-4 justify-end": _vm.type.isCondensed(),
                        },
                      },
                      _vm._l(_vm.buttons, function (button) {
                        return _c(_vm.getButtonComponent(button.type), {
                          key: button.label,
                          tag: "component",
                          attrs: { label: button.label },
                          on: { click: () => button.handler() },
                        })
                      }),
                      1
                    ),
                  ]
                ),
            _vm._v(" "),
            _vm.closeButton
              ? _c(
                  "button",
                  {
                    staticClass:
                      "absolute top-4 right-4 flex p-1 cursor-pointer",
                    attrs: { tabindex: "0", "aria-label": "Sluiten" },
                    on: { click: _vm.close },
                  },
                  [_c("inline-icon", { attrs: { icon: "close" } })],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }