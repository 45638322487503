var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cockpit-results--actions" },
    [
      _c("show-test-results-button", {
        attrs: {
          stream: _vm.stream,
          group: _vm.group,
          test: _vm.test,
          store: _vm.store,
          "score-categories-data": _vm.scoreCategoriesData,
          "modal-links": _vm.modalLinks,
        },
      }),
      _vm._v(" "),
      _c("enter-test-results-button", {
        attrs: {
          stream: _vm.stream,
          group: _vm.group,
          store: _vm.store,
          token: _vm.token,
        },
      }),
      _vm._v(" "),
      _c("replay-test-results-button", {
        attrs: {
          stream: _vm.stream,
          group: _vm.group,
          test: _vm.test,
          store: _vm.store,
          token: _vm.token,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }