<template>
  <tr :class="{'td--border--top': firstRow, 'archived--tr': itemResult.myContent.archived, 'disabled--tr': itemResult.loading}">
    <td class="whitespace-nowrap student-name cockpit-results--no-hover no-disable-color" :class="lastRow ? 'border-bottom' : 'no-border-bottom'">{{ firstRow ? user.fullName : '' }}</td>
    <td>{{ itemResult.learningGoalTitle }}</td>
    <td>{{ educationLevel }}</td>
    <td>{{ itemResult.progress.completed }}/{{ itemResult.progress.total }}</td>
    <td>
      <score :score="itemResult.score" :link="scoreLink" />
    </td>
    <td class="whitespace-nowrap">
      <div v-if="!isCompleted(itemResult)" class="flex flex-wrap--wrap">
        <toggle-button ref="toggle" :disabled="itemResult.myContent.archived" :id="String(itemResult.id)" :checked="'open' === state" @change="toggleMyContent" />
      </div>
    </td>
    <td>
      <div class="cockpit-results--actions">
        <button
          class="button button--cockpit button--primary button--small button-archive"
          :class="{ 'button--inverse': itemResult.myContent.archived }"
          @click="toggleArchived"
          v-html="archiveButtonTitle"
        ></button>
        <button
          v-if="itemResult.progress.completed === 0"
          class="button button--cockpit button--cockpit-with-icon-only button--primary button--small"
          @click="confirmRemoveLearningGoal()"
        ><i class="icon-trash"></i></button>
      </div>
    </td>
  </tr>
</template>

<script>
import ToggleButton from '../../../components/buttons/ToggleButton'
import Score from '../../../components/Score'
import EducationLevelForMyContent from '../EducationLevelForMyContent'
import ContentPathResolver from '../../../ContentPathResolver'
import AbsoluteLinkService from '../../../AbsoluteLinkService'
import MyContentResultsStore from './MyContentResultsStore'
import {ACTION_EVENT, EventBus} from "../../../common/EventBus";
import {
  ACTION_MY_CONTENT_ARCHIVED, ACTION_MY_CONTENT_ASSIGNED,
  ACTION_MY_CONTENT_DELETED,
  ACTION_MY_CONTENT_RESTORED, ACTION_MY_CONTENT_WITHDRAWN
} from "../../../common/ThasActionEventActionType";

const educationLevelForMyContent = new EducationLevelForMyContent()

export default {
  components: { Score, ToggleButton },

  props: {
    user: Object,
    firstRow: Boolean,
    lastRow: Boolean,
    itemResult: Object,
    myContentToken: String,
    streamCode: String,
    editionUrl: String,
    result: Object,
    store: MyContentResultsStore
  },

  data () {
    return {
      loading: false,
      scoreLink: this.createScoreLink()
    }
  },

  computed: {
    state () {
      return this.itemResult.myContent.state
    },
    archiveButtonTitle () {
      return this.itemResult.myContent.archived ? 'Herstellen' : 'Archiveren'
    },
    educationLevel () {
      return educationLevelForMyContent.getCombinedName(this.itemResult.educationLevels)
    }
  },

  methods: {
    async toggleArchived() {
      EventBus.$emit(ACTION_EVENT, window.location.href, this.getActionType(), {
        'streamCode': this.itemResult.myContent.streamCode,
        'educationLevel': this.itemResult.myContent.educationLevel,
        'userReference': this.itemResult.myContent.userReference,
        'contentReference': this.itemResult.myContent.contentReference,
        'state': this.itemResult.myContent.state,
        'learningGoal': this.itemResult.learningGoalTitle
      })

      try {
        this.itemResult.loading = true
        if (this.itemResult.myContent.archived) {
          await this.store.restore(this.itemResult.myContent)
        } else {
          await this.store.archive(this.itemResult.myContent)
        }
        this.itemResult.myContent.archived = !this.itemResult.myContent.archived
      } finally {
        this.itemResult.loading = false
        this.store.updateFilteredResults()
      }
    },
    getActionType() {
      if (this.itemResult.myContent.archived) {
        return ACTION_MY_CONTENT_RESTORED
      }
      return ACTION_MY_CONTENT_ARCHIVED
    },
    isCompleted (itemResult) {
      return itemResult.progress.completed >= itemResult.progress.total
    },
    createScoreLink () {
      const absoluteLinkService = new AbsoluteLinkService(this.editionUrl)
      const contentPathResolver = new ContentPathResolver(this.itemResult.myContent.contentPath)

      return absoluteLinkService.editionLink(
        'edition_result_group_paragraph',
        {
          streamCode: this.streamCode,
          contentPath: contentPathResolver.paragraphPath(),
          userReference: this.result.user.userReference,
          itemId: contentPathResolver.itemId(),
          groupId: this.groupId,
          learningGoal: this.itemResult.learningGoalPath
        }
      )
    },
    confirmRemoveLearningGoal() {
      this.$confirm(
        {
          message: this.$t('cockpit.my_content.confirm_remove', {
            learningGoal: this.itemResult.learningGoalTitle,
            educationLevel: this.educationLevel,
            userName: this.user.fullName
          }),
          button: {
            no: this.$t('generic.term.cancel'),
            yes: this.$t('generic.term.delete')
          },
          callback: confirm => {
            if (confirm) {
              this.removeLearningGoal()
            }
          }
        }
      )
    },
    async removeLearningGoal () {
      EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_MY_CONTENT_DELETED, {
        'streamCode': this.itemResult.myContent.streamCode,
        'educationLevel': this.itemResult.myContent.educationLevel,
        'userReference': this.itemResult.myContent.userReference,
        'contentReference': this.itemResult.myContent.contentReference,
        'state': this.itemResult.myContent.state,
        'archived': this.itemResult.myContent.archived,
        'learningGoal': this.itemResult.learningGoalTitle
      })

      try {
        this.itemResult.loading = true
        await this.store.delete(this.itemResult.myContent)
      }
      finally {}
    },
    async toggleMyContent(active) {
      if (active) {
        return await this.assignMyContent()
      }
      return await this.withdrawMyContent()
    },
    async assignMyContent () {
      try {
        this.store.setLoading(this.result.user.userReference, this.itemResult.learningGoalTitle, true)
        this.itemResult.loading = true
        this.itemResult.myContent.state = 'open';
        this.itemResult.myContent.educationLevel = educationLevelForMyContent.getFirstIdentifier(this.itemResult.educationLevels)

        EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_MY_CONTENT_ASSIGNED, {
          'streamCode': this.itemResult.myContent.streamCode,
          'educationLevel': this.itemResult.myContent.educationLevel,
          'userReference': this.itemResult.myContent.userReference,
          'contentReference': this.itemResult.myContent.contentReference,
          'archived': this.itemResult.myContent.archived,
          'learningGoal': this.itemResult.learningGoalTitle
        })

        await this.store.assignSingle(this.itemResult.myContent, this.itemResult.learningGoalPath)
      } finally {
        this.store.setLoading(this.result.user.userReference, this.itemResult.learningGoalTitle, false)
      }
    },
    async withdrawMyContent () {
      const item = this.itemResult

      try {
          this.itemResult.loading = true
          item.myContent.state = 'withdrawn';
          item.myContent.educationLevel = educationLevelForMyContent.getFirstIdentifier(item.educationLevels)

          EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_MY_CONTENT_WITHDRAWN, {
            'streamCode': this.itemResult.myContent.streamCode,
            'educationLevel': this.itemResult.myContent.educationLevel,
            'userReference': this.itemResult.myContent.userReference,
            'contentReference': this.itemResult.myContent.contentReference,
            'archived': this.itemResult.myContent.archived,
            'learningGoal': this.itemResult.learningGoalTitle
          })

          await this.store.withdraw(item.myContent)
        } finally {
          this.itemResult.loading = false
      }
    }
  }
}
</script>
