var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "dropdown--open": _vm.open, "dropdown--disabled": _vm.disabled },
    },
    [
      _c(
        "div",
        {
          ref: "trigger",
          staticClass: "dropdown__trigger",
          on: { click: _vm.toggle },
        },
        [
          _c("span", {
            staticClass: "dropdown__trigger-label mr-1",
            domProps: { innerHTML: _vm._s(_vm.store.title) },
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-tm-arrow-down dropdown__trigger-arrow",
          }),
        ]
      ),
      _vm._v(" "),
      _vm.open
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              ref: "dropdownMenu",
              staticClass: "dropdown__menu",
              attrs: { role: "menu", "body-scroll-lock-ignore": "" },
            },
            [
              _c(
                "div",
                { ref: "dropdownContent", staticClass: "dropdown__content" },
                [
                  _vm.searchable
                    ? _c("div", { staticClass: "dropdown__filter" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTerm,
                              expression: "searchTerm",
                            },
                          ],
                          ref: "searchField",
                          staticClass: "dropdown__search-input",
                          attrs: {
                            type: "search",
                            placeholder: _vm.searchPlaceholder,
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.searchTerm },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.searchTerm = $event.target.value
                              },
                              _vm.onSearchInput,
                            ],
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tree-node", {
                    attrs: {
                      node: _vm.store.tree,
                      "select-leafs-only": _vm.selectLeafsOnly,
                      "selected-branch": _vm.store.selectedBranch,
                      "is-root": true,
                    },
                    on: { "select-node": _vm.selectNode },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }