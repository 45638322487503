var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "result-grid__tabs",
        class: { "result-grid__tabs--loading": _vm.store.loading },
      },
      [
        _c(
          "tabs",
          {
            attrs: {
              "tabs-additional-class": "tabs--vertical",
              animated: false,
              truncate: true,
              disabled: _vm.store.loading,
            },
          },
          _vm._l(_vm.store.group.members, function (user, userIndex) {
            return _c(
              "tab",
              {
                key: user.fullName,
                attrs: {
                  name: user.fullName,
                  uid: userIndex,
                  active: userIndex === 0,
                  icon: _vm.userIcons[user.userReference],
                },
                on: {
                  onSelect: function ($event) {
                    return _vm.selectUserTab(user)
                  },
                },
              },
              [
                _vm.activeUserReference === user.userReference
                  ? _c("result-entry-grid-user", {
                      key: user.fullName + "_" + _vm.index,
                      attrs: {
                        user: user,
                        store: _vm.store,
                        "folio-test-service": _vm.folioTestService,
                      },
                      on: { stored: _vm.storedUser, reset: _vm.resetUser },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }