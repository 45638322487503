<template>
    <div>
        <button
          class="button button--small button--primary button--cockpit"
          @click="createTest"
          data-test="create-test-button"
        >
            <i class="icon-plus"></i> {{ $t('cockpit.test.assign_button') }}
        </button>
      <button
        class="button button--small button--primary button--cockpit"
        @click="resendToThas"
        data-test="resend-button"
      >
        <i class="icon-send"></i> {{ $t('cockpit.test.resend_digital_tests.button') }}
      </button>
        <test-form
          :initial-test="editableTest"
          :group="group"
          :test-service="testService"
          v-if="editableTest !== null"
          v-on:close="editableTest = null"
          :tests="tests"
        />
        <resend-form
          v-if="resendVisible"
          :test-service="testService"
          :tests="tests"
          v-on:close="resendVisible = false"
        />
    </div>
</template>

<script>
  import ResendForm from './ResendForm'
  import TestForm from './TestForm'
  import TestService from './TestService'
  import dayjs from 'dayjs'
  import { formatDayjs } from '../util/date-format'

  export default {
    props: {
      group: Object,
      stream: Object,
      token: String,
      tests: Array
    },
    components: { TestForm, ResendForm },
    data () {
      return {
        editableTest: null,
        resendVisible: false,
        testService: new TestService(
          this.stream.code,
          this.token
        ),
      }
    },
    methods: {
      createTest () {
        const start = dayjs().minute(0)
        this.editableTest = {
          stream: this.stream,
          users: this.group.members.map(user => user.userReference),
          start: formatDayjs(start),
          end: formatDayjs(start.add(7, 'day').add(2, 'hour')),
          contentPath: []
        }
      },
      resendToThas () {
        this.resendVisible = true
      }
    }
  }
</script>

<style scoped>

</style>
