<template>
  <tr>
    <td v-if="educationLevelSelectorStore.hasEducationLevels()">
      <input type="checkbox" :checked="educationLevelSelectorStore.storeEducationLevel(user.userReference)"
        @change="educationLevelSelectorStore.setStoreEducationLevel(user.userReference, $event.target.checked)"
        :disabled="!educationLevelSelectorStore.hasChangedEducationLevel(user.userReference)" />
    </td>
    <td class="whitespace-nowrap">{{ user.fullName }}</td>
    <template v-if="educationLevelSelectorStore.hasEducationLevels()">
      <td class="text-align-center" v-html="educationLevelSelectorStore.getUserEducationLevel(user.userReference).name"></td>
      <td class="text-align-center">
        <education-level-selector
          class="education-level-selector--centered"
          :education-levels="educationLevelSelectorStore.educationLevels"
          :current-education-level="educationLevelSelectorStore.getUserEducationLevel(user.userReference)"
          :selected-education-level="educationLevelSelectorStore.getSelectedUserEducationLevel(user.userReference)"
          :adviced-education-level="educationLevelSelectorStore.getAdvicedUserEducationLevel(user.userReference)"
          :with-reset="true"
          @select-education-level="selectEducationLevel"
          @reset-education-level="resetEducationLevel"
        ></education-level-selector>
      </td>
    </template>
    <td>
      <score :score="store.getLastTwoAverageScore(user.userReference)"/>
    </td>
    <template v-for="testData in store.testsData">
      <td v-if="testData.hasDivider" class="horizontal-divider--left"></td>
      <td v-if="testData.hasDivider" class="horizontal-divider--right"></td>
      <td>
        <div class="flexbox flexbox--row flex-wrap--no-wrap flex-justify-content--center">
        <score
          :score="store.getScore(user.userReference, testData.contentPath)"
          :link="store.getLink(user.userReference, testData.contentPath, false)"
        />
        <score
          v-if="null !== store.getOtherScore(user.userReference, testData.contentPath)"
          class="ml-half"
          :score="store.getOtherScore(user.userReference, testData.contentPath)"
          :link="store.getLink(user.userReference, testData.contentPath, true)"
          :is-secondary="true"
        />
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import Score from '../../components/Score'
import AllTestResultsStore from './AllTestResultsStore'
import EducationLevelSelector from '../../components/EducationLevelSelector'
import EducationLevelSelectorStore from '../../components/EducationLevelSelectorStore'

export default {
  components: { Score, EducationLevelSelector },
  props: {
    user: Object,
    store: AllTestResultsStore,
    educationLevelSelectorStore: EducationLevelSelectorStore
  },
  methods: {
    selectEducationLevel(educationLevel) {
      this.educationLevelSelectorStore.selectEducationLevelForUser(this.user.userReference, educationLevel)
    },
    resetEducationLevel() {
      this.educationLevelSelectorStore.resetEducationLevelForUser(this.user.userReference)
    }
  }
}
</script>
