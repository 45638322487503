<template>
  <div v-if="store.hasResults()" class="cockpit-results pr-4">
    <StickyTable :sticky-heads="2" :sticky-columns="1" class="table table--full-width cockpit-results--hover">
      <thead>
      <tr class="cockpit-results--heading-groups align-center vertical-align-middle">
        <th></th>
        <template v-for="header in store.getBlockHeaders(true)">
          <th v-if="header.hasDivider" class="horizontal-divider--left"></th>
          <th v-if="header.hasDivider" class="horizontal-divider--right"></th>
          <th class="cockpit-results--heading-group" :colspan="header.span"><span :class="header.classes" v-html="header.label"></span></th>
        </template>
      </tr>
      <tr class="cockpit-results--heading align-center vertical-align-bottom">
        <th></th>
        <template v-for="header in store.getTestHeaders(true)">
          <th v-if="header.hasDivider" class="horizontal-divider--left"></th>
          <th v-if="header.hasDivider" class="horizontal-divider--right"></th>
          <th :class="header.classes" v-html="header.label"></th>
        </template>
      </tr>
      </thead>
      <tbody>
      <template v-for="user in users">
        <prior-knowledge-results-user
          :user="user"
          :store="store"
        ></prior-knowledge-results-user>
      </template>
      </tbody>
    </StickyTable>
  </div>
  <div v-else class="flexbox flex-justify-content--center mt-8 font-italic">
    <span v-html="$t('cockpit.progress.results.no_prior_knowledge_results')"></span>
  </div>
</template>

<script>

import PriorKnowledgeResultsUser from './PriorKnowledgeResultsUser'
import AllTestResultsStore from './AllTestResultsStore'
import StickyTable from '../../components/StickyTable'

export default {
  components: {
    PriorKnowledgeResultsUser,
    StickyTable
  },
  props: {
    groupId: String,
    users: Array,
    tests: Array,
    folioTests: Array,
    blocks: Array,
    scoreCategoriesData: Array,
    streamCode: String,
    editionUrl: String
  },
  data () {
    return {
      store: new AllTestResultsStore(
        this.users,
        this.tests,
        this.folioTests,
        this.blocks,
        this.scoreCategoriesData,
        this.$t,
        this.editionUrl,
        this.streamCode,
        this.groupId
      )
    }
  }
}
</script>
