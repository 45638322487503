var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.store.hasResults()
    ? _c(
        "div",
        { staticClass: "cockpit-results pr-4" },
        [
          _c(
            "StickyTable",
            {
              staticClass: "table table--full-width cockpit-results--hover",
              attrs: { "sticky-heads": 2, "sticky-columns": 1 },
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  {
                    staticClass:
                      "cockpit-results--heading-groups align-center vertical-align-middle",
                  },
                  [
                    _c("th"),
                    _vm._v(" "),
                    _vm._l(_vm.store.getBlockHeaders(true), function (header) {
                      return [
                        header.hasDivider
                          ? _c("th", {
                              staticClass: "horizontal-divider--left",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        header.hasDivider
                          ? _c("th", {
                              staticClass: "horizontal-divider--right",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "cockpit-results--heading-group",
                            attrs: { colspan: header.span },
                          },
                          [
                            _c("span", {
                              class: header.classes,
                              domProps: { innerHTML: _vm._s(header.label) },
                            }),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass:
                      "cockpit-results--heading align-center vertical-align-bottom",
                  },
                  [
                    _c("th"),
                    _vm._v(" "),
                    _vm._l(_vm.store.getTestHeaders(true), function (header) {
                      return [
                        header.hasDivider
                          ? _c("th", {
                              staticClass: "horizontal-divider--left",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        header.hasDivider
                          ? _c("th", {
                              staticClass: "horizontal-divider--right",
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", {
                          class: header.classes,
                          domProps: { innerHTML: _vm._s(header.label) },
                        }),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.users, function (user) {
                    return [
                      _c("prior-knowledge-results-user", {
                        attrs: { user: user, store: _vm.store },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "flexbox flex-justify-content--center mt-8 font-italic",
        },
        [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("cockpit.progress.results.no_prior_knowledge_results")
              ),
            },
          }),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }