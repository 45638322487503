var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "button button--small button--primary button--cockpit button--cockpit-with-icon-only",
          on: { click: _vm.showEntryScreen },
        },
        [_c("i", { staticClass: "icon-statistics" })]
      ),
      _vm._v(" "),
      _vm.editableTest !== null
        ? _c("show-test-results-grid", {
            attrs: {
              group: _vm.group,
              test: _vm.test,
              stream: _vm.stream,
              token: _vm.token,
              store: _vm.store,
              "score-categories-data": _vm.scoreCategoriesData,
              "modal-links": _vm.modalLinks,
            },
            on: {
              close: function ($event) {
                _vm.editableTest = null
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }