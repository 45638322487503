var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "education-level-selector education-level-selector--centered",
    },
    [
      _c(
        "div",
        { staticClass: "button-group button-group--cockpit" },
        _vm._l(_vm.educationLevels, function (educationLevel, index) {
          return _c("my-content-education-level", {
            key: educationLevel.educationLevel,
            attrs: {
              "education-level": educationLevel,
              "initial-education-level": _vm.initialEducationLevel,
              "selected-education-level": _vm.selectedEducationLevel,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.selectEducationLevel(educationLevel)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "education-level-selector-reset" }, [
        _vm.withReset &&
        _vm.initialEducationLevel !== _vm.selectedEducationLevel
          ? _c(
              "button",
              {
                staticClass: "button button--tiny ml-1",
                on: {
                  click: function ($event) {
                    return _vm.$emit("reset-education-level")
                  },
                },
              },
              [_c("i", { staticClass: "icon-replay" })]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }