var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-modal" }, [
    _c("div", {
      staticClass: "form-modal__background",
      on: { click: _vm.close },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "form-modal__container",
        attrs: { "data-test": "test-form-modal" },
      },
      [
        _c("button", {
          staticClass: "form-modal__close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.close.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass: "labeled-title form-modal__title",
            attrs: { "data-test": "test-form-modal-title" },
          },
          [
            _c("span", { staticClass: "labeled-title__text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      _vm.isNewTest
                        ? "cockpit.test.modal.title.assign"
                        : "cockpit.test.modal.title.edit"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("form", [
          _vm.validationErrors.length > 0
            ? _c(
                "div",
                { staticClass: "form-modal__errors" },
                _vm._l(_vm.validationErrors, function (validationError) {
                  return _c(
                    "div",
                    { staticClass: "notification notification--warning mb-1" },
                    [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("div", { staticClass: "notification__text" }, [
                        _vm._v(_vm._s(validationError)),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-modal__body" }, [
            _c("div", { staticClass: "form-group form-group--readonly mb-3" }, [
              _c(
                "label",
                {
                  staticClass: "form-group__label text-bold",
                  attrs: { for: "group_id" },
                },
                [_vm._v(_vm._s(_vm.$t("generic.term.group")))]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-group__field form-group__field--readonly",
                attrs: { id: "group_id", type: "text", readonly: "" },
                domProps: { value: _vm.group.name },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-3" }, [
              _c("label", { staticClass: "form-group__label text-bold" }, [
                _vm._v(_vm._s(_vm.$t("generic.term.test"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.test.contentPath,
                      expression: "test.contentPath",
                    },
                  ],
                  staticClass: "form-group__field form-group__field",
                  attrs: { "data-test": "test-form-modal-test-dropdown" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.test,
                        "contentPath",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                _vm._l(_vm.tests, function (availableTest) {
                  return _c(
                    "option",
                    { domProps: { value: availableTest.contentPath } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(availableTest.title) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group form-group--readonly mb-0" }, [
              _c("div", { staticClass: "form-group__field--nolabel" }, [
                _c("div", { staticClass: "ml-1 form-group__userselect-all" }, [
                  _c("input", {
                    attrs: {
                      id: "form-select-all-users",
                      type: "checkbox",
                      "data-test": "test-form-modal-select-all-users",
                    },
                    domProps: {
                      checked:
                        _vm.test.users.length === _vm.group.members.length,
                    },
                    on: { click: _vm.toggleSelectAllUsers },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "form-select-all-users" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("cockpit.test.modal.select_all_students"))
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group mb-1" }, [
              _c("label", { staticClass: "form-group__label text-bold" }, [
                _vm._v(_vm._s(_vm.$t("generic.term.students"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group__field form-group__userselect",
                  attrs: { id: "form-select-users" },
                },
                _vm._l(_vm.group.members, function (user) {
                  return _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.test.users,
                          expression: "test.users",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: `user_${user.userReference}`,
                      },
                      domProps: {
                        value: user.userReference,
                        checked: Array.isArray(_vm.test.users)
                          ? _vm._i(_vm.test.users, user.userReference) > -1
                          : _vm.test.users,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.test.users,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = user.userReference,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.test, "users", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.test,
                                  "users",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.test, "users", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: `user_${user.userReference}` } },
                      [_vm._v(_vm._s(user.fullName))]
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group form-group--readonly mb-3" }, [
              _c("div", { staticClass: "form-group__field--nolabel" }, [
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("cockpit.test.modal.students_selected", {
                        count: _vm.test.users.length,
                      })
                    )
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group mb-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-group__label text-bold",
                    attrs: { for: "start" },
                  },
                  [_vm._v(_vm._s(_vm.$t("cockpit.test.modal.start")))]
                ),
                _vm._v(" "),
                _c("date-time-input", {
                  attrs: {
                    "input-class": "form-group__field",
                    "position-top": true,
                    id: "start",
                  },
                  model: {
                    value: _vm.test.start,
                    callback: function ($$v) {
                      _vm.$set(_vm.test, "start", $$v)
                    },
                    expression: "test.start",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group mb-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "form-group__label text-bold",
                    attrs: { for: "end" },
                  },
                  [_vm._v(_vm._s(_vm.$t("cockpit.test.modal.end")))]
                ),
                _vm._v(" "),
                _c("date-time-input", {
                  attrs: {
                    "input-class": "form-group__field",
                    "position-top": true,
                    id: "end",
                  },
                  model: {
                    value: _vm.test.end,
                    callback: function ($$v) {
                      _vm.$set(_vm.test, "end", $$v)
                    },
                    expression: "test.end",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-modal__actions" }, [
              _c(
                "button",
                {
                  staticClass: "button button--cockpit button--primary",
                  attrs: {
                    type: "submit",
                    disabled: _vm.loading,
                    "data-test": "test-form-modal-button-submit",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submitTest.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t(
                          _vm.isNewTest
                            ? "cockpit.test.modal.assign"
                            : "cockpit.test.modal.save"
                        )
                      ) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button button--link button--cockpit",
                  attrs: { "data-test": "test-form-modal-button-cancel" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("generic.term.cancel")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notification__label" }, [
      _c("i", { staticClass: "icon-alert" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }