var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.steps.length > 0 && !_vm.disabled
    ? _c(
        "a",
        {
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.click.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "span",
            [
              _c("span", { staticClass: "icon-help" }),
              _vm._v(" "),
              _vm._l(_vm.steps, function (step) {
                return _vm.visible && !_vm.disabled && null !== step.attachTo
                  ? _c("shortcut-button", {
                      key: step.id,
                      attrs: {
                        disabled: _vm.disabled,
                        currentId: _vm.currentId,
                        step: step,
                      },
                      on: { "click-shortcut": _vm.clickShortcut },
                    })
                  : _vm._e()
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.vmsId
            ? _c("media-modal", {
                attrs: { "vms-id": _vm.vmsId, redesign: _vm.redesign },
                on: { close: _vm.continueAfterVideo },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }