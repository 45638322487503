'use strict'

const _getFilteredResults = (results, showArchived) => {
  let filtered = []
  results.forEach(result => {
    filtered.push({
      user: result.user,
      itemResults: _sortItemResults(_filterItemResults(_updateItemResults(result.itemResults), showArchived))
    })
  })
  return filtered
}

const _updateItemResults = (itemResults, showArchived) => {
  return itemResults.map(itemResult => {
    if (!itemResult.hasOwnProperty('loading')) {
      itemResult.loading = false
    }
    return itemResult
  })
}

const _filterItemResults = (itemResults, showArchived) => {
  let filteredItemResults = []

  itemResults.forEach(itemResult => {
    if (showArchived || !itemResult.myContent.archived) {
      filteredItemResults.push(itemResult)
    }
  })

  return filteredItemResults
}

const _removeItemFromItemResults = (itemResults, myContent) => {
  let filteredItemResults = []

  itemResults.forEach(itemResult => {
    if (itemResult.myContent !== myContent) {
      filteredItemResults.push(itemResult)
    }
  })

  return filteredItemResults
}

const _sortItemResults = itemResults => {
  return itemResults.sort((resultA, resultB) => {
    if (resultA.learningGoalTitle === resultB.learningGoalTitle) {
      if (resultA.educationLevels[0].order === resultB.educationLevels[0].order) {
        return 0
      }
      return resultA.educationLevels[0].order > resultB.educationLevels[0].order ? 1 : -1
    }
    return resultA.learningGoalTitle.localeCompare(resultB.learningGoalTitle)
  })
}

import MyContentService from './MyContentService'

export default class MyContentResultsStore {
  constructor (results, showArchived, streamCode, token) {
    this.sourceResults = results
    this.showArchived = showArchived
    this.service = new MyContentService(streamCode, token)
    this.updateFilteredResults()
  }

  updateFilteredResults() {
    this.filteredResults = _getFilteredResults(this.sourceResults, this.showArchived)
  }

  setLoading(userReference, learningGoalTitle, loading) {
    this.sourceResults.forEach(result => {
      if (result.user.userReference === userReference) {
        result.itemResults.forEach(itemResult => {
          if (itemResult.learningGoalTitle === learningGoalTitle) {
            itemResult.loading = loading
          }
        })
      }
    })
  }

  async assignCollection (myContentCollection) {
    return this.service.assignMyContentCollection(myContentCollection)
  }

  async assignSingle (myContent, learningGoalPath) {
    const contentReferences = this.setStateGetContentReferences(myContent, learningGoalPath)
    const response = await this.service.assignMyContent(myContent, learningGoalPath, contentReferences)
    response.data.forEach(content => {
      this.updateState(myContent.userReference, content.id, content.state)
    })
  }

  async withdraw (myContent) {
    return this.service.withdrawMyContent(myContent)
  }

  async archive (myContent) {
    return this.service.archiveMyContent(myContent)
  }

  async restore (myContent) {
    return this.service.restoreMyContent(myContent)
  }

  async delete (myContent) {
    await this.service.deleteActiveLearningGoal(myContent)
    this.deleteFromSource(myContent)
  }

  setStateGetContentReferences(myContent, learningGoalPath) {
    let contentReferences = []

    this.sourceResults.forEach(result => {
      if (result.user.userReference === myContent.userReference) {
        result.itemResults.forEach(itemResult => {
          if (itemResult.learningGoalPath === learningGoalPath) {
            contentReferences.push(itemResult.myContent.contentReference)
            const isOpen = itemResult.myContent.contentReference === myContent.contentReference
            itemResult.myContent.state = isOpen ? 'open' : 'withdrawn'
          }
        })
      }
    })

    return contentReferences
  }

  deleteFromSource(myContent) {
    this.sourceResults.forEach(result => {
      if (result.user.userReference === myContent.userReference) {
        result.itemResults = _removeItemFromItemResults(result.itemResults, myContent)
      }
    })
    this.updateFilteredResults()
  }

  updateState(userReference, id, state) {
    this.sourceResults.forEach(result => {
      if (result.user.userReference === userReference) {
        result.itemResults.forEach(itemResult => {
          if (itemResult.id === id) {
            itemResult.myContent.state = state
          }
        })
      }
    })
  }
}
