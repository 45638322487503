<template>
  <div>
    <button class="button button--small button--primary button--cockpit"
            @click="open"
            @click.shift="openDebug"
            :disabled="isDisabled || isLoading"
            ref="button">
      <i class="icon-send"></i>
    </button>

    <div class="form-modal" :class="{'is-loading': isLoading}" v-if="showModal">
      <div class="form-modal__background" @click="close"></div>
      <div class="form-modal__container">
        <button @click.prevent="close" class="form-modal__close"></button>

        <h2 class="labeled-title form-modal__title"><span
          class="labeled-title__text">{{ $t('cockpit.test.resend_folio_tests.modal.title') }}</span></h2>
        <div class="form-modal__body">
          <p>{{ $t('cockpit.test.resend_folio_tests.modal.description') }}</p>

          <h4>{{ test.subject }}</h4>
          <p>{{ $t('cockpit.test.resend_folio_tests.modal.result_count') }}: {{ store.resultCount }}</p>

          <div v-if="store.resultCountNoSubmittedAt > 0" class="form-modal__select_date">
            <div v-html="$transChoice('cockpit.test.resend_folio_tests.modal.missing_submitted_at', store.resultCountNoSubmittedAt, { count: store.resultCountNoSubmittedAt })"></div>
            <date-time-input
              class="mt-1"
              input-class="form-group__field"
              :pick-time="false"
              :value="submittedAt"
              id="submitted_at"
              :position-top="true"
              :allow-future-dates="false"
              @input="setSubmittedAt"
            />
          </div>

          <warning v-if="resendFailed" :message="$t('cockpit.test.resend_folio_tests.modal.failed')"/>

          <div class="form-modal__actions">
            <a v-if="showViewUwlr" :href="folioTestService.getViewUwlrUrl(test.id)" target="_blank" class="button button--cockpit button--primary">
              {{ $t('cockpit.test.view_uwlr.button') }}
            </a>
            <button @click.prevent="performReplay" type="submit" class="button button--cockpit button--primary"
                    :disabled="isLoading">{{ $t('cockpit.test.resend_folio_tests.button') }}
            </button>
            <button @click.prevent="close" class="button button--cockpit button--secondary button--cockpit-with-icon-only" :disabled="isLoading">{{
                $t('generic.term.close')
              }}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FolioTestService from './FolioTestService'
import FolioResultsStore from './folio-results/FolioResultsStore'
import Warning from '../components/notification/Warning'
import DateTimeInput from '../components/DateTimeInput'
import { ACTION_EVENT, EventBus } from "../common/EventBus";
import { ACTION_FOLIO_TEST_RESENT } from "../common/ThasActionEventActionType";

export default {
  components: {
    Warning,
    DateTimeInput
  },
  props: {
    group: Object,
    stream: Object,
    token: String,
    test: Object,
    store: FolioResultsStore
  },
  data () {
    return {
      showViewUwlr: false,
      showModal: false,
      isLoading: false,
      isDisabled: this.store.resultCount === 0,
      resendFailed: false,
      folioTestService: new FolioTestService(
        this.stream.code,
        this.token
      ),
      submittedAt: this.store.getDefaultSubmittedAt()
    }
  },
  methods: {
    setSubmittedAt (value) {
      this.submittedAt = value
    },
    openDebug () {
      const location = new URL(window.location.href)
      this.showViewUwlr = location.hostname === 'cockpit.localhost' || location.hostname.indexOf('cockpit') > 0 // Don't show on production
      this.showModal = true
    },
    open () {
      this.showViewUwlr = false
      this.showModal = true
      this.resendFailed = false
    },
    close () {
      this.showViewUwlr = false
      this.showModal = false
      this.resendFailed = false
    },
    async performReplay () {
      this.isLoading = true
      this.isDisabled = true
      this.resendFailed = false

      const userReferences = this.store.getUserReferencesWithResults()

      const submittedAt = this.store.resultCountNoSubmittedAt > 0 ? this.submittedAt : null

      EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_FOLIO_TEST_RESENT, {
        'subject': this.test.subject,
        'type': this.test.type,
        'userResults': userReferences.length,
        'submittedAt': this.submittedAt
      })

      try {
        await this.folioTestService.resendFolioResults(this.test.id, userReferences, submittedAt)
        if (null !== submittedAt) {
          this.store.setEmptySubmittedAt(submittedAt)
        }
        this.close()
      } catch (error) {
        this.resendFailed = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
