<template>
  <div class="learn-lines-navigation__item-inner" @click="toggleOpenedState">
    <span class="learn-lines-navigation__item-title">{{ node.title }}</span>
    <i class="learn-lines-navigation__item-icon">
      <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.11565 10.7867C3.86178 11.0141 3.73484 11.1278 3.60968 11.2022C3.10062 11.5052 2.46095 11.4801 1.97713 11.1383C1.85817 11.0543 1.74051 10.931 1.50518 10.6845C1.25602 10.4235 1.13144 10.293 1.05078 10.1645C0.722021 9.64081 0.748316 8.96903 1.11701 8.47262C1.20746 8.35083 1.34186 8.23046 1.61066 7.98973L8.45012 1.86426C9.43715 0.980261 9.93067 0.538262 10.5209 0.549795C11.1112 0.561328 11.5871 1.02227 12.5388 1.94414L18.7731 7.98271C19.0339 8.23534 19.1643 8.36166 19.2503 8.48779C19.5979 8.99756 19.5979 9.6682 19.2503 10.178C19.1643 10.3041 19.0339 10.4304 18.7731 10.6831C18.5268 10.9216 18.4036 11.0409 18.2806 11.1206C17.7845 11.4418 17.146 11.4418 16.6499 11.1206C16.5269 11.0409 16.4038 10.9216 16.1575 10.6831L12.4351 7.07751C11.4833 6.15563 11.0075 5.69469 10.4172 5.68316C9.82694 5.67163 9.33343 6.11363 8.34639 6.99762L4.11565 10.7867Z"
          fill="#8B867B"/>
      </svg>
    </i>
  </div>
</template>

<script>
export default {
  name: 'Header',

  components: {},

  props: {
    node: Object
  },

  methods: {
    toggleOpenedState () {
      if (!this.node.opened) {
        this.$emit('open', this.node)
        return
      }
      this.$emit('close', this.node)
    }
  }
}
</script>
