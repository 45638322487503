<template>
  <div class="cockpit-results pr-4">
    <div class="mb-3 d-flex between-3">
      <dropdown
        key="dropdown-blocks"
        class="dropdown--blue mr-4"
        :options="blockOptions"
      ></dropdown>
      <option-selector
        v-if="store.learningGoalTypes.length > 1"
        :options="store.learningGoalTypes"
        :can-deselect="false"
        :with-reset="false"
        button-style="button--small button--sans-serif"
        @select="selectLearningGoalType"
      ></option-selector>
    </div>
    <StickyTable :sticky-heads="2" :sticky-columns="store.stickyColumns" class="table table--full-width cockpit-results--hover" data-test="learning-goal-test-results-table">
      <thead>
        <tr class="cockpit-results--heading align-center vertical-align-top">
          <th></th>
          <th v-if="store.showEducationLevelColumn"></th>
          <th></th>
          <th v-if="store.hasReferenceLevels"></th>
          <template v-for="(header, index) in store.getGroupHeaders()">
            <template v-if="index > 0">
              <th class="horizontal-divider--left"></th>
              <th class="horizontal-divider--right"></th>
            </template>
            <th :colspan="header.span" v-html="header.label"></th>
          </template>
        </tr>
        <tr class="cockpit-results--heading align-center vertical-align-middle">
          <th></th>
          <th v-if="store.showEducationLevelColumn" v-html="$t('generic.term.testlevel')"></th>
          <th class="text-align-left" v-html="$t('generic.term.source')"></th>
          <th v-if="store.hasReferenceLevels" v-html="$t('generic.term.reference_level')"></th>
          <th v-for="header in store.getHeaders()" :class="header.class" v-html="header.label"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="user in users">
          <template v-if="store.userHasResults(user.userReference)">
            <learning-goal-test-results-user
              v-for="(resultType, index) in store.getResultTypes(user.userReference)"
              :key="user.userReference + '-' + resultType.digitalOrFolio + '-' + resultType.referenceLevel"
              :user="user"
              :show-username="0 === index"
              :no-border="0 === index && store.getResultTypes(user.userReference).length > 1"
              :type="resultType.digitalOrFolio"
              :store="store"
              :reference-level="resultType.referenceLevel"
              @adviceUpdated="updatePrintSheetRhvAdvices"
            ></learning-goal-test-results-user>
          </template>
          <learning-goal-test-results-user
            v-else
            :key="user.userReference + '-no-result'"
            :user="user"
            :show-username="true"
            :no-border="false"
            type=""
            :store="store"
          ></learning-goal-test-results-user>
        </template>
      </tbody>
    </StickyTable>

    <div v-if="store.showRhv" class="button-container">
      <a
        href="#" @click="showPrintModal=true"
        class="button button--small button--primary button--cockpit"
        :class="{'button--disabled': !store.showRhv || !hasRhvAdvices}"
        :disabled="!store.showRhv || !hasRhvAdvices"
        data-test="learning-goal-test-results-print-overview-button"
      >
        <i class="icon-printer"></i> {{ $t('cockpit.test.feedback.overview') }}
      </a>
      <div class="button-with-message-container">
        <form method="POST" target="_blank" :action="store.getPrintRhvSheetsUrl()">
          <input type="hidden" name="data" :value="JSON.stringify(printSheetRhvAdvices)" />
          <button
            type="submit"
            class="button button--cockpit button--small button--primary"
            :class="{'button--disabled': '' !== disabledReason}"
            :disabled="'' !== disabledReason"
            data-test="learning-goal-test-results-print-sheets-button"
          >
            <span><i class="icon-printer"></i> {{ $t('cockpit.test.feedback.print_sheets') }}</span>
          </button>
        </form>
        <span v-if="'' !== disabledReason" v-html="$t('cockpit.test.feedback.print_sheets_disabled_' + disabledReason)"></span>
      </div>
    </div>

    <rhv-print-modal
      v-if="store.rhvEnabled && showPrintModal"
      @close="showPrintModal=false"
      :store="store"
    ></rhv-print-modal>
  </div>
</template>

<script>

import LearningGoalTestResultsUser from './LearningGoalTestResultsUser'
import LearningGoalTestResultsStore from './LearningGoalTestResultsStore'
import Dropdown from '../../components/Dropdown'
import OptionSelector from '../../components/OptionSelector'
import StickyTable from '../../components/StickyTable'
import RhvPrintModal from '../rhv/RhvPrintModal'

export default {
  components: {
    LearningGoalTestResultsUser, Dropdown, StickyTable, OptionSelector, RhvPrintModal
  },
  props: {
    groupId: String,
    streamCode: String,
    editionUrl: String,
    users: Array,
    tests: Array,
    folioTests: Array,
    scoreCategoriesData: Array,
    rhvEnabled: Boolean,
    rhvAdvices: Array,
    educationLevelData: Object,
    groupEducationLevels: Object,
    blockOptions: Array,
    contentReference: String,
    rhvPrintSheets: Object
  },
  data () {
    return {
      showPrintModal: false,
      printSheetRhvAdvices: {},
      hasRhvAdvices: false,
      store: new LearningGoalTestResultsStore(
        this.contentReference,
        this.users,
        this.tests,
        this.folioTests,
        this.scoreCategoriesData,
        this.rhvEnabled,
        this.rhvAdvices,
        this.rhvPrintSheets,
        this.educationLevelData,
        this.groupEducationLevels,
        this.groupId,
        this.streamCode,
        this.editionUrl,
        this.$t
      )
    }
  },
  mounted() {
    this.updatePrintSheetRhvAdvices()
  },
  computed: {
    disabledReason () {
      if (!this.store.hasResults) {
        return 'no_results'
      }
      if (!this.hasRhvAdvices) {
        return 'no_advices'
      }
      if (!this.store.hasRhvPrintSheets) {
        return 'no_sheets'
      }
      return ''
    }
  },
  methods: {
    selectLearningGoalType (context, option) {
      this.store.setLearningGoalType(option.key)
      this.updatePrintSheetRhvAdvices()
    },
    updatePrintSheetRhvAdvices () {
      const adviceData = this.store.getRhvAdviceData()
      this.printSheetRhvAdvices = adviceData.rhvAdvices
      this.hasRhvAdvices = Object.keys(adviceData.users).length > 0
    }
  }
}
</script>
