<template>
  <div ref="tabContent" v-if="target !== '' || isSelected || renderHidden" class="tab-content" :class="{ 'is-hidden': !isSelected || target !== '' }">
    <slot></slot>
  </div>
</template>

<script>
import Video from '../Video'

export default {
  componentName: 'Tab',

  data() {
    return {
      isSelected: Boolean
    }
  },

  created() {
    this.tabs = this.$children
  },

  mounted() {
    this.isSelected = this.active
  },

  watch: {
    isSelected (value) {
      if (this.target !== '' && value) {
        const element = document.querySelector(this.target)
        element.innerHTML = this.$refs.tabContent.innerHTML
        this.initVideos(element)
      }

      if (this.isSelected) {
        this.$emit('onSelect')
      }
    }
  },

  methods: {
    initVideos (element) {
      const videoElements = element.querySelectorAll('.tabcontent-vms-container')
      for (const videoElement of videoElements) {
        new Video(videoElement)
      }
    }
  },

  props: {
    name: {
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    },
    active: {
      default: false
    },
    renderHidden: {
      type: Boolean,
      default: true
    },
    uid: Number,
    activeId: String
  }
}
</script>
