<template>
  <div class="form-modal" :class="{'is-loading': loading}">
    <div class="form-modal__background" @click="close"></div>
    <div class="form-modal__container" data-test="resend-form-modal">
      <button @click.prevent="close" class="form-modal__close" data-test="resend-form-modal-close"></button>
      <h2 class="labeled-title form-modal__title mb-2" data-test="resend-form-modal-title">
        <span class="labeled-title__text">{{ $t('cockpit.test.resend_digital_tests.modal.title') }}</span>
      </h2>
      <p class="mx-6">{{ $t('cockpit.test.resend_digital_tests.modal.description') }}</p>
      <div class="form-modal__body">
        <table v-if="tests.length > 0" class="table table--cockpit table--full-width">
          <thead>
            <tr>
              <th>Toets</th>
              <th>Aantal ingeleverde toetsen</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <resend-test
              v-for="(test, index) in tests"
              :key="test.contentPath[test.contentPath.length - 1]"
              :test="test" :test-service="testService"
              :component-index="index"
            />
          </tbody>
        </table>
        <div class="form-modal__actions">
          <button @click.prevent="close" type="submit" class="button button--cockpit button--primary"
                  :disabled="loading">{{ $t('generic.term.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ResendTest from './ResendTest'
  import TestService from './TestService'

  export default {
    components: {
      ResendTest
    },

    props: {
      group: Object,
      testService: TestService
    },

    data: function () {
      return {
        tests: [],
        testResults: [],
        loading: false
      }
    },

    created() {
      this.requestList()
    },

    methods: {
      close () {
        if (this.loading) {
          return
        }

        this.$emit('close')
      },

      async requestList() {
        this.loading = true

        try {
          const response = await this.testService.getTests()
          this.tests = response.data.tests
        } catch (error) {
        }

        this.loading = false
      }
    }
  }
</script>
