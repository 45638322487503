var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "learn-lines-navigation pt-2" }, [
    _c(
      "ul",
      {
        staticClass:
          "learn-lines-navigation__list learn-lines-navigation__list--level-1",
      },
      _vm._l(_vm.rootNodes, function (node, index) {
        return _c(
          "li",
          {
            staticClass: "learn-lines-navigation__item",
            class: [
              "learn-lines-navigation__item--" + ++index,
              { "learn-lines-navigation__item--opened": node.opened },
            ],
            attrs: {
              "data-test": "learn-lines-navigation-list-level-1-" + index,
            },
          },
          [
            _c("Header", {
              attrs: { node: node },
              on: { open: _vm.openNode, close: _vm.closeNode },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "learn-lines-navigation__list-wrapper" },
              [
                _c(
                  "tabs",
                  {
                    staticClass: "px-6 flexbox",
                    attrs: {
                      "first-level-index": index,
                      "tabs-additional-class":
                        "learn-lines-navigation__list learn-lines-navigation__list--level-2 mt-3",
                      identifier: "level2",
                    },
                  },
                  _vm._l(node.children, function (levelOneChild, childIndex) {
                    return _c(
                      "tab",
                      {
                        key: "tabs-" + index + "-tab-" + childIndex,
                        staticClass: "flexbox flexbox--column mt-1",
                        attrs: {
                          uid: childIndex,
                          name: levelOneChild.title,
                          active: index === 0,
                        },
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "learn-lines-navigation__list learn-lines-navigation__list--level-3",
                          },
                          _vm._l(
                            _vm._getLessonGoals(levelOneChild),
                            function (goalNode, indexLevel3) {
                              return _c(
                                "li",
                                {
                                  staticClass: "learn-lines-navigation__item",
                                  attrs: {
                                    "data-test":
                                      "learn-lines-navigation-list-level-3-" +
                                      indexLevel3,
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "learn-lines-navigation__item-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onGoalClick(goalNode)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(goalNode.title))]
                                  ),
                                  _vm._v(" "),
                                  levelOneChild.activitiesCompleted
                                    ? _c(
                                        "p",
                                        { staticClass: "ml-auto mr-3 mb-0" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                title:
                                                  _vm.checkedActivitiesNumberLabel,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  levelOneChild.activitiesCompleted
                                                )
                                              ),
                                            ]
                                          ),
                                          levelOneChild.activities
                                            ? _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    title:
                                                      _vm.targetActivitiesNumberLabel,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        levelOneChild.activities
                                                      )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  levelOneChild.activitiesCompleted
                                    ? _c("donut", {
                                        staticClass: "ml-auto mr-0",
                                        attrs: {
                                          size: "small",
                                          progress:
                                            (levelOneChild.activitiesCompleted /
                                              levelOneChild.activities) *
                                              100 || 0,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }