var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass:
            "button button--small button--primary button--cockpit button--cockpit-with-icon-only",
          on: { click: _vm.showGrid },
        },
        [_c("i", { staticClass: "icon-plus" })]
      ),
      _vm._v(" "),
      _vm.showEntryGrid
        ? _c("enter-test-results-grid", {
            attrs: {
              group: _vm.group,
              stream: _vm.stream,
              token: _vm.token,
              store: _vm.store,
            },
            on: {
              close: function ($event) {
                _vm.showEntryGrid = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }