<template>
  <div class="mr-3 learning-goal__goals_options">
    <label>
      <input type="radio" name="editList" value="always"/>Nog
    </label>
    <label>
      <input type="radio" name="editList" value="never"/>Nu
    </label>
    <label>
      <input type="radio" name="editList" value="costChange"/>Al
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0
    }
  }
}
</script>
