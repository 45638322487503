var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    [
      _c("td", { staticClass: "whitespace-nowrap" }, [
        _vm._v(_vm._s(_vm.user.fullName)),
      ]),
      _vm._v(" "),
      _vm._l(_vm.store.getBlocksWithResults(), function (block, index) {
        return [
          index > 0
            ? _c("td", { staticClass: "horizontal-divider--left" })
            : _vm._e(),
          _vm._v(" "),
          index > 0
            ? _c("td", { staticClass: "horizontal-divider--right" })
            : _vm._e(),
          _vm._v(" "),
          _c("td", {
            domProps: {
              innerHTML: _vm._s(
                _vm.store.getCompactRouteAdvice(_vm.user.userReference, block)
                  .name
              ),
            },
          }),
          _vm._v(" "),
          _vm._l(block.tests, function (test) {
            return _c("td", [
              _c(
                "div",
                {
                  staticClass:
                    "flexbox flexbox--row flex-wrap--no-wrap flex-justify-content--center",
                },
                [
                  _c("score", {
                    attrs: {
                      score: _vm.store.getScore(
                        _vm.user.userReference,
                        test.contentPath
                      ),
                      "empty-value": _vm.store.getEmptyScoreValue(
                        _vm.user.userReference,
                        test.contentPath
                      ),
                      link: _vm.store.getLink(
                        _vm.user.userReference,
                        test.contentPath,
                        false
                      ),
                    },
                  }),
                  _vm._v(" "),
                  null !==
                  _vm.store.getOtherScore(
                    _vm.user.userReference,
                    test.contentPath
                  )
                    ? _c("score", {
                        staticClass: "ml-half",
                        attrs: {
                          score: _vm.store.getOtherScore(
                            _vm.user.userReference,
                            test.contentPath
                          ),
                          "is-secondary": true,
                          "empty-value": _vm.store.getEmptyScoreValue(
                            _vm.user.userReference,
                            test.contentPath
                          ),
                          link: _vm.store.getLink(
                            _vm.user.userReference,
                            test.contentPath,
                            true
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }