<template>
  <select-dropdown
    class="dropdown--minimalistic"
    @input="$emit('input', $event)"
    v-model="value"
    :options="statusOptions"
    key-attribute="key"
    label-attribute="value"
    title-attribute="title"
  ></select-dropdown>
</template>

<script>
import SelectDropdown from '../../../components/SelectDropdown'
import MyContentState from './MyContentState'

export default {
  components: { SelectDropdown },

  data () {
    const states = MyContentState.list(this.$t)
    const statusOptions = [
      { key: null, value: this.$t('cockpit.my_content.state_all'), title: this.$t('cockpit.my_content.state') },
    ]
    for (const state of states) {
      statusOptions.push({key: state.state, value: state.description, title: state.description})
    }
    return {
      statusOptions,
      value: statusOptions[0]
    }
  },
  created() {
    this.$emit('input', this.value)
  }
}
</script>
