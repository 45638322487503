export default class ScoreCategory {
  /**
   * @param {String} name
   * @param {Number} from
   * @param {Number} to
   */
  constructor(name, from, to) {
    this.name = name
    this.from = from
    this.to = to
  }

  static fromData(data) {
    return new ScoreCategory(data.name, data.from, data.to)
  }
}
