<template>
  <div class="lesson-navigation__toggle-container">
    <a data-click-target="lesson-navigation__inner"
       @click="toggle($event)"
       class="lesson-navigation__toggle js-cockpit-week-overview-toggle" :class="toggleClass"
       data-test="lesson-navigation-toggle"
    >
      <span class="lesson-navigation__label" data-test="lesson-navigation-toggle-label">{{ label }}</span>
      <span class="lesson-navigation__toggle-icon icon-tm-arrow-down"></span></a>
  </div>
</template>

<script>
  import { store as CockpitStore } from '../../CockpitStore'

  export default {
    computed: {
      label () {
        if (CockpitStore.state.collapseNavigation) {
          return this.$t('cockpit.lesson.navigation.overview_toggle_label.collapsed')
        }
        return this.$t('cockpit.lesson.navigation.overview_toggle_label.expanded')
      },
      toggleClass () {
        return CockpitStore.state.collapseNavigation ? 'lesson-navigation__toggle--collapsed' : 'lesson-navigation__toggle--expanded'
      }
    },
    methods: {
      toggle (e) {
        let { clickTarget } = e.target.dataset.clickTarget !== undefined ? e.target.dataset : e.target.parentNode.dataset
        let target = document.querySelectorAll(`.${clickTarget}`)

        target[0].classList.toggle(`${clickTarget}--collapsed`)
        target[0].classList.toggle(`${clickTarget}--expanded`)

        CockpitStore.toggleCollapsedState()
      }
    }
  }
</script>
