<template>
  <div class="cockpit-content__lesson-navigation lesson-navigation-wrapper">
    <div class="container container-fluid lesson-navigation-wrapper__inner py-3">
      <week-overview-toggle></week-overview-toggle>

      <div class="lesson-navigation__inner lesson-navigation__inner--collapsed">
        <active-week
          :blocks="blocks"
          v-show="CockpitStore.state.collapseNavigation"
          :select-week="selectWeek"
          :route-service="routeService"
        ></active-week>

        <tabs
          page-wrapper-class="tab-pages--cockpit-content-lesson-navigation"
          tabs-additional-class="tabs--cockpit"
          :bordered="true"
          identifier="level1"
          first-level-index="0"
        >
          <tab :name="$t('cockpit.lesson.navigation.tabs.tab1_title')" :active="true" :uid="0">
            <week-overview
              :blocks="blocks"
              :select-week="selectWeek"
              :route-service="routeService"
            ></week-overview>
          </tab>

          <tab v-if="showLearnLines" :name="$t('cockpit.lesson.navigation.tabs.tab2_title')" :uid="1">
            <learn-lines
              :learn-line-root="learnLineRoot"
              :content-path="contentPath"
              :route-service="routeService"
            ></learn-lines>
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { store as CockpitStore } from '../CockpitStore'

import Tabs from './tabs/Tabs.vue'
import Tab from './tabs/Tab.vue'
import WeekOverviewToggle from './weekoverview/WeekOverviewToggle.vue'
import WeekOverview from './weekoverview/WeekOverview'
import ActiveWeek from './weekoverview/ActiveWeek'
import CockpitRouteService from '../CockpitRouteService'
import LearnLines from './learn-lines/LearnLines'

export default {
  components: {
    Tabs,
    Tab,
    WeekOverviewToggle,
    WeekOverview,
    ActiveWeek,
    LearnLines
  },
  props: {
    blockTitle: String,
    blocks: Array,
    groupId: [String, Number],
    streamCode: String,
    selectWeek: {
      type: Boolean,
      default: true
    },
    learnLineRoot: Object,
    contentPath: Array,
    blockRoute: {
      type: String,
      default: 'cockpit_progress'
    },
    weekRoute: {
      type: String,
      default: 'cockpit_week'
    },
    lessonRoute: {
      type: String,
      default: 'cockpit_lesson_v2'
    }
  },
  data() {
    return {
      CockpitStore,
      showLearnLines: this.learnLineRoot.children.length > 0 || this.learnLineRoot.viewModels.length > 0,
      routeService: new CockpitRouteService(this.streamCode, this.groupId, this.blockRoute, this.weekRoute, this.lessonRoute)
    }
  }
}
</script>
