var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("select-group-for-stream", {
    staticClass: "dropdown--minimalistic",
    attrs: {
      groups: _vm.groups,
      "selected-group-id": _vm.selectedGroupId,
      "show-teachers": _vm.showTeachers,
      "save-setting-token": _vm.groupSaveSettingToken,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }