import UserEducationLevels from './UserEducationLevels'
import EducationLevel from './EducationLevel'

export default class UsersEducationLevels {
  /**
   * @param {Object.<string, UserEducationLevels>} usersEducationLevels
   */
  constructor(usersEducationLevels) {
    this.usersEducationLevels = usersEducationLevels
  }

  getUserEducationLevel(userReference, contentPath, returnDefault) {
    if (this.usersEducationLevels.hasOwnProperty(userReference)) {
      return this.usersEducationLevels[userReference].getEducationLevel(contentPath, returnDefault)
    }
    return EducationLevel.createNone()
  }

  update(data) {
    Object.entries(data).forEach(([userReference, educationLevelData]) => {
      this.usersEducationLevels[userReference] = UserEducationLevels.fromData(educationLevelData)
    })
  }

  static fromData(data) {
    const usersEducationLevels = Object.entries(data).reduce((carry, [userReference, educationLevelData]) => {
      carry[userReference] = UserEducationLevels.fromData(educationLevelData)
      return carry
    }, {})

    return new UsersEducationLevels(usersEducationLevels)
  }
}
