var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "dropdown--open": _vm.isOpen },
      attrs: { "data-test": "group-selection-dropdown" },
    },
    [
      _c(
        "div",
        {
          ref: "trigger",
          staticClass: "dropdown__trigger",
          on: {
            click: function ($event) {
              _vm.isOpen ? _vm.close() : _vm.open()
            },
          },
        },
        [
          _c("span", { staticClass: "dropdown__trigger-label mr-1" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c("i", {
            staticClass: "icon-tm-arrow-down dropdown__trigger-arrow",
          }),
        ]
      ),
      _vm._v(" "),
      _vm.isOpen
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              ref: "dropdownMenu",
              staticClass: "dropdown__menu",
              attrs: { id: "dropdown-menu", role: "menu" },
            },
            [
              _c(
                "div",
                { ref: "dropdownContent", staticClass: "dropdown__content" },
                [
                  _c("div", { staticClass: "dropdown__divider" }, [
                    _c(
                      "span",
                      {
                        staticClass: "dropdown__divider--label",
                        attrs: {
                          "data-test": "group-selection-divider-settings",
                        },
                      },
                      [
                        _c("i", { staticClass: "icon-settings mr-1" }),
                        _vm._v(_vm._s(_vm.settingsTitle)),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown__setting",
                      attrs: {
                        "data-test": "group-selection-setting-show-teachers",
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.showTeachersChecked,
                            expression: "showTeachersChecked",
                          },
                        ],
                        staticClass:
                          "dropdown__checkbox dropdown__checkbox--show-teachers",
                        attrs: {
                          type: "checkbox",
                          "data-test":
                            "group-selection-setting-show-teachers-checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.showTeachersChecked)
                            ? _vm._i(_vm.showTeachersChecked, null) > -1
                            : _vm.showTeachersChecked,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.showTeachersChecked,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.showTeachersChecked = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.showTeachersChecked = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.showTeachersChecked = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(_vm._s(_vm.showTeachersTitle) + "\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showAllGroups
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown__item",
                          class: {
                            "dropdown__item--selected":
                              _vm.selectedGroup === null,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectGroup(null)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.allGroupsTitle))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.manualGroups.length > 0
                    ? [
                        _c("div", { staticClass: "dropdown__divider" }, [
                          _c(
                            "span",
                            {
                              staticClass: "dropdown__divider--label",
                              attrs: {
                                "data-test":
                                  "group-selection-divider-your-groups",
                              },
                            },
                            [
                              _c("i", { staticClass: "icon-user-group mr-1" }),
                              _vm._v(_vm._s(_vm.manualGroupsTitle)),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.manualGroups, function (group, index) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown__item",
                                class: {
                                  "dropdown__item--selected":
                                    _vm.isSelected(group),
                                },
                                attrs: {
                                  "data-test":
                                    "group-selection-manual-group-" + index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectGroup(group)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(group.name))]
                            ),
                          ]
                        }),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.staticGroups.length > 0
                    ? [
                        _c("div", { staticClass: "dropdown__divider" }, [
                          _c(
                            "span",
                            {
                              staticClass: "dropdown__divider--label",
                              attrs: {
                                "data-test":
                                  "group-selection-divider-all-groups",
                              },
                            },
                            [_vm._v(_vm._s(_vm.staticGroupsTitle))]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.staticGroups, function (group, index) {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown__item",
                                class: {
                                  "dropdown__item--selected":
                                    _vm.isSelected(group),
                                },
                                attrs: {
                                  "data-test":
                                    "group-selection-static-group-" + index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectGroup(group)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(group.name))]
                            ),
                          ]
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }