var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "lesson-navigation__week-component" },
    [
      _c(
        "li",
        {
          staticClass:
            "lesson-navigation__item lesson-navigation__item--0 lesson-navigation__item--week-overview",
          class: [{ "lesson-navigation__item--active": _vm.week.active }],
        },
        [
          _vm.selectWeek
            ? _c(
                "a",
                {
                  staticClass:
                    "lesson-navigation__item-link lesson-navigation__item-link-week",
                  attrs: { href: _vm.weekLink, title: _vm.weekTitle },
                },
                [_vm._v(_vm._s(_vm.weekTitle))]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "lesson-navigation__item-no-link lesson-navigation__item-no-link-week",
                  attrs: { title: _vm.weekTitle },
                },
                [_vm._v(_vm._s(_vm.weekTitle))]
              ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.week.lessons, function (lesson, index) {
        return _c("lesson-item", {
          key: index,
          attrs: {
            lesson: lesson,
            "previous-lesson": _vm.week.lessons[index - 1],
            "next-lesson": _vm.week.lessons[index + 1],
            "route-service": _vm.routeService,
            "block-number": _vm.blockNumber,
            type: "all-weeks",
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }