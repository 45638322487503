<template>
  <tabs identifier="blocks"
        first-level-index="1"
        tabs-additional-class="tabs--cockpit cockpit-tabs--blocks"
        page-wrapper-class="tab-pages--cockpit-content-lesson-navigation mt-3"
        selector="lesson-navigation">
    <tab
      v-for="(block, index) in blocks"
      :name="block.aggregatedTitle"
      :active="block.active"
      :key="index"
      :uid="index"
    >
      <block-item
        :block="block"
        :select-week="selectWeek"
        :route-service="routeService"
      ></block-item>
    </tab>
  </tabs>
</template>

<script>
import Tabs from '../tabs/Tabs'
import Tab from '../tabs/Tab'
import BlockItem from './BlockItem'
import CockpitRouteService from '../../CockpitRouteService'

export default {
  components: {
    BlockItem,
    Tabs,
    Tab
  },
  props: {
    blocks: Array,
    activeContentPath: Array,
    week: Object,
    selectWeek: Boolean,
    routeService: CockpitRouteService
  }
}
</script>
