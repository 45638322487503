import EducationLevel from './EducationLevel'

export default class EducationLevels {
  /**
   * @param {EducationLevel[]} educationLevels
   * @param {Boolean} enabled
   */
  constructor(educationLevels, enabled) {
    this.educationLevels = educationLevels
    this.enabled = enabled
  }

  isEnabled() {
    return this.enabled
  }

  getEducationLevels() {
    return this.educationLevels
  }

  getEducationLevelForScore(score) {
    return this.educationLevels.find(level => score >= level.scoreFrom && score < level.scoreTo)
  }

  static fromData(data) {
    return new EducationLevels(
      data.educationLevels.map(educationLevel => EducationLevel.fromData(educationLevel)),
      !data.isNone
    )
  }
}
