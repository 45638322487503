<template>
  <div class="form-modal" :class="{'is-loading': loading}">
    <div class="form-modal__background" @click="close"></div>
    <div class="form-modal__container form-modal__container--wide">
      <button @click.prevent="close" class="form-modal__close"></button>
      <h2 class="labeled-title form-modal__title"><span class="labeled-title__text">{{ $t('cockpit.progress.select_learning_goal_title') }}</span></h2>

      <div class="form-modal__body">
        <learning-goals-for-block :stream-code="streamCode" :blocks="blocks" :block-content-path="blockContentPath" @loading="loading=true" @loaded="loading=false"></learning-goals-for-block>
      </div>
    </div>
  </div>
</template>

<script>

import LearningGoalsForBlock from './LearningGoalsForBlock'

export default {
  components: { LearningGoalsForBlock },
  props: {
    streamCode: String,
    blockContentPath: String,
    blocks: Array
  },
  data() {
    return {
      loading: false
    }
  },

  methods: {
    close () {
      if (this.loading) {
        return
      }

      this.$emit('close')
    }
  }
}
</script>
