import Routing from './util/routing'

export default class CockpitRouteService {
  constructor (streamCode, groupId, blockRoute, weekRoute, lessonRoute) {
    this._streamCode = streamCode
    this._groupId = groupId

    this._blockRoute = blockRoute
    this._weekRoute = weekRoute
    this._lessonRoute = lessonRoute
  }

  blockLink (contentPath) {
    return Routing.generate(this._blockRoute, {
      streamCode: this._streamCode,
      contentPath: contentPath,
      groupId: this._groupId
    })
  }

  weekLink (contentPath) {
    return Routing.generate(this._weekRoute, {
      streamCode: this._streamCode,
      contentPath: contentPath,
      groupId: this._groupId
    })
  }

  lessonLink (contentPath) {
    return Routing.generate(this._lessonRoute, {
      streamCode: this._streamCode,
      contentPath: contentPath,
      groupId: this._groupId
    })
  }
}
