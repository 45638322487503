var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "modal", staticClass: "modal modal--card is-active" },
    [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c(
        "article",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.close,
              expression: "close",
            },
          ],
          staticClass: "modal__inner",
        },
        [
          _c(
            "header",
            {
              staticClass: "modal__header",
              class: [{ "modal__header--no-bg": !_vm.image }],
              style: _vm.image
                ? "background-image: url(" + _vm.image + ");"
                : "",
            },
            [
              _c("div", { staticClass: "modal__header-inner" }, [
                _vm.meta !== undefined
                  ? _c(
                      "p",
                      { staticClass: "modal__pretitle" },
                      [
                        _vm.meta.trefwoorden
                          ? [_vm._v(_vm._s(_vm.meta.trefwoorden[0]))]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.meta.leerjaar
                          ? [
                              _vm._v(
                                " • " +
                                  _vm._s(_vm.metaLeerjaarLabel) +
                                  " " +
                                  _vm._s(_vm.leerjaar)
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("h4", { staticClass: "modal__title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "modal__lesson-status-button button button--light flex flex-align-items--center mr-auto mt-6",
                    class: _vm.store.completed ? "toggled" : "",
                    on: { click: _vm.checkOffLesson },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "modal__lesson-status-icon mr-1",
                        attrs: {
                          width: "16",
                          height: "16",
                          viewBox: "0 0 16 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M1.03224 12.2465C1.03224 12.2465 11.3458 7.37428 13.7505 4.96959C16.1551 2.5649 11.6199 1.61634 8.10189 3.52679C4.58392 5.43723 5.27085 9.2908 10.2384 9.2629C11.5615 9.25547 13.5512 9.90807 12.6451 10.8387C11.7537 11.7544 9.18509 11.9736 8.10184 12.709",
                            stroke: "#97BE0C",
                            "stroke-width": "1.6",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "modal__lesson-status-label",
                      attrs: {
                        "data-test": "lesson-topic-modal-finish-" + _vm.index,
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "cockpit.activities.card.modal.completed-status-label-" +
                              (_vm.store.completed ? "checked" : "unchecked")
                          )
                        ),
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.store.groupId && _vm.store.groupId > 0
                ? _c("my-activity-toggle", {
                    attrs: { store: _vm.store, index: _vm.index },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("a", {
                staticClass: "modal__close",
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "content",
              staticClass: "modal__content",
              attrs: { "data-test": "lesson-topic-modal-content-" + _vm.index },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }