'use strict'

const states = {
  UNASSIGNED: 'unassigned', // this state is only used in the frontend

  OPEN: 'open',
  WITHDRAWN: 'withdrawn',
  FINISHED: 'finished'
}

export default class MyContentState {
  constructor (state, translate) {
    this.state = state
    this.translate = translate
    this.setDescription()
  }

  static list (translate) {
    return [
      new MyContentState(states.OPEN, translate),
      new MyContentState(states.WITHDRAWN, translate),
      new MyContentState(states.FINISHED, translate)
    ]
  }

  static createUnassigned (translate) {
    return new MyContentState(states.UNASSIGNED, translate)
  }

  static createOpen (translate) {
    return new MyContentState(states.OPEN, translate)
  }

  static createWithdrawn (translate) {
    return new MyContentState(states.WITHDRAWN, translate)
  }

  setDescription() {
    this.description = this.translate ? this.translate('cockpit.my_content.state_' + this.state) : this.state
  }

  isUnassigned () {
    return this.state === states.UNASSIGNED
  }

  isOpen () {
    return this.state === states.OPEN
  }

  isWithdrawn () {
    return this.state === states.WITHDRAWN
  }

  open () {
    this.state = states.OPEN
    this.setDescription()
  }

  withdraw () {
    this.state = states.WITHDRAWN
    this.setDescription()
  }
}
