<template>
  <div class="result-entry-words">
    <template v-for="(word, key) in question.entryType.data.words">
      <div v-if="wordIsEnabled(key)" class="word" :class="results[key] ? 'correct' : 'incorrect'" @click="toggleCorrect(key)">
        <span class="word-icon" v-html="results[key] ? correctIcon : incorrectIcon"></span>
        {{ word }}
      </div>
      <div v-else class="word predefined">
        <div class="word-icon-container">
          <span class="word-icon" v-html="predefinedIcon"></span>
        </div>
        {{ word }}
      </div>
    </template>
  </div>
</template>

<script>
import InlineIcon from '../components/InlineIcon'

export default {
  components: {
    InlineIcon
  },
  props: {
    user: Object,
    question: Object,
    resultData: Object
  },
  data () {
    let results = {}
    let updateInitialScore = false

    Object.entries(this.question.entryType.data.words).forEach(([key, word]) => {
      if (this.wordIsEnabled(key)) {
        const result = this.getInitialResult(key)
        if (null === result) {
          results[key] = true
          updateInitialScore = true
        } else {
          results[key] = result
        }
      }
    })

    return {
      updateInitialScore: updateInitialScore,
      results: results,
      correctIcon: require('@Icons/checkmark.svg'),
      incorrectIcon: require('@Icons/close.svg'),
      predefinedIcon: require('@Icons/star.svg')
    }
  },
  mounted () {
    this.updateScore(this.updateInitialScore)
  },
  methods: {
    wordIsEnabled(key) {
      if (this.question.entryType.data.hasOwnProperty('disabledWords')) {
        return -1 === this.question.entryType.data.disabledWords.indexOf(key)
      }
      return true
    },
    getInitialResult (key) {
      if (null !== this.resultData && undefined !== this.resultData && this.resultData.hasOwnProperty(key)) {
        return this.resultData[key]
      }
      return null
    },
    toggleCorrect (key) {
      this.results[key] = !this.results[key]
      this.updateScore(true)
    },
    updateScore (updateFormButtons) {
      const score = Object.entries(this.results).filter(([key, correct]) => {
        return correct
      }).length

      this.$emit('updateScore', updateFormButtons, this.question.id, this.user.userReference, score, this.results);
    }
  }
}
</script>
