<template>
  <div class="cockpit-results pr-4 w-100">
    <StickyTable :sticky-heads="stickyHeads" :sticky-columns="2" class="table table--full-width cockpit-results--hover">
      <thead>
        <tr v-if="store.hasMultipleTestsPerBlock()" class="cockpit-results--heading-groups align-center vertical-align-middle">
          <th></th>
          <th></th>
          <template v-if="educationLevelSelectorStore.hasEducationLevels()">
            <th></th>
            <th></th>
          </template>
          <th></th>
          <template v-for="header in store.getBlockHeaders()">
            <th v-if="header.hasDivider" class="horizontal-divider--left"></th>
            <th v-if="header.hasDivider" class="horizontal-divider--right"></th>
            <th class="cockpit-results--heading-group" :colspan="header.span"><span :class="header.classes" v-html="header.label"></span></th>
          </template>
        </tr>
        <tr class="cockpit-results--heading align-center vertical-align-bottom">
          <th v-if="educationLevelSelectorStore.hasEducationLevels()" class="text-align-left">
            <input
              type="checkbox"
              :checked="educationLevelSelectorStore.allEducationLevelChangesChecked()"
              @change="educationLevelSelectorStore.setAllStoreEducationLevel($event.target.checked)"
              :disabled="!educationLevelSelectorStore.hasEducationLevelChanges()"
            />
          </th>
          <th></th>
          <template v-if="educationLevelSelectorStore.hasEducationLevels()">
            <th class="whitespace-nowrap" v-html="$t('cockpit.progress.results.current_education_level')"></th>
            <th class="whitespace-nowrap" v-html="$t('cockpit.progress.results.new_education_level')"></th>
          </template>
          <th class="whitespace-nowrap" v-html="$t('cockpit.progress.results.last_two_block_average')"></th>
          <template v-for="header in store.getTestHeaders()">
            <th v-if="header.hasDivider" class="horizontal-divider--left"></th>
            <th v-if="header.hasDivider" class="horizontal-divider--right"></th>
            <th :class="header.classes" v-html="header.label"></th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="user in users">
          <all-test-results-user
            :user="user"
            :store="store"
            :education-level-selector-store="educationLevelSelectorStore"
          ></all-test-results-user>
        </template>
      </tbody>
    </StickyTable>

    <button
      v-if="educationLevelSelectorStore.hasEducationLevels()"
      class="button button--primary button--cockpit"
      :disabled="educationLevelSelectorStore.isLoading || 0 === educationLevelSelectorStore.storeEducationLevelChangesCount()"
      @click="educationLevelSelectorStore.saveEducationLevelChanges()"
      v-html="$transChoice('cockpit.set_educationlevel_for_number_of_students', educationLevelSelectorStore.storeEducationLevelChangesCount(), {numberOfStudents: educationLevelSelectorStore.storeEducationLevelChangesCount()})"
    ></button>
  </div>
</template>

<script>

import AllTestResultsUser from './AllTestResultsUser'
import AllTestResultsStore from './AllTestResultsStore'
import StickyTable from '../../components/StickyTable'
import EducationLevelSelectorStore from '../../components/EducationLevelSelectorStore'

export default {
  components: {
    StickyTable,
    AllTestResultsUser,
  },
  props: {
    groupId: String,
    users: Array,
    tests: Array,
    folioTests: Array,
    blocks: Array,
    educationLevelData: Object,
    usersEducationLevels: Object,
    scoreCategoriesData: Array,
    streamCode: String,
    editionUrl: String
  },
  data () {
    const store = new AllTestResultsStore(
      this.users,
      this.tests,
      this.folioTests,
      this.blocks,
      this.scoreCategoriesData,
      this.$t,
      this.editionUrl,
      this.streamCode,
      this.groupId
    )

    const educationLevelSelectorStore = new EducationLevelSelectorStore(
      this.educationLevelData,
      this.usersEducationLevels,
      store.userAverageScores,
      this.groupId
    )

    return {
      store: store,
      educationLevelSelectorStore: educationLevelSelectorStore,
      stickyHeads: store.hasMultipleTestsPerBlock() ? 2 : 1
    }
  },
  mounted () {
  }
}
</script>
