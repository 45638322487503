var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "lesson__status-toggle",
      attrs: { href: "#", "data-test": "lesson-status-toggle" },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.completed,
            expression: "completed",
          },
        ],
        staticClass: "lesson-status-toggle__input",
        attrs: { type: "checkbox", name: "lesson-status", id: "lesson-status" },
        domProps: {
          checked: Array.isArray(_vm.completed)
            ? _vm._i(_vm.completed, null) > -1
            : _vm.completed,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.completed,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.completed = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.completed = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.completed = $$c
              }
            },
            _vm.onChange,
          ],
        },
      }),
      _vm._v(" "),
      _c("label", {
        staticClass: "lesson__status-background",
        attrs: { "data-test": "lesson-status-color", for: "lesson-status" },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "lesson__status-icon icon-check" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }