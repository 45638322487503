'use strict'

import Routing from '../../util/routing'
import UserSettingService from './UserSettingService'

export default class SelectedGroupSettingService {
  constructor(token) {
    this._setUrl = Routing.generate('user_setting_set_selected_group', { streamCode: window.msp.streamCode })
    this._token = token;
  }

  setGroupAndShowTeachers(group, showTeachers) {
    return UserSettingService.set(this._setUrl, { groupId: group ? group.id : null, showTeachers: showTeachers }, this._token)
  }
}
