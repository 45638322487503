var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lesson-navigation__toggle-container" }, [
    _c(
      "a",
      {
        staticClass:
          "lesson-navigation__toggle js-cockpit-week-overview-toggle",
        class: _vm.toggleClass,
        attrs: {
          "data-click-target": "lesson-navigation__inner",
          "data-test": "lesson-navigation-toggle",
        },
        on: {
          click: function ($event) {
            return _vm.toggle($event)
          },
        },
      },
      [
        _c(
          "span",
          {
            staticClass: "lesson-navigation__label",
            attrs: { "data-test": "lesson-navigation-toggle-label" },
          },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm._v(" "),
        _c("span", {
          staticClass: "lesson-navigation__toggle-icon icon-tm-arrow-down",
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }