<template>
  <div v-if="!isLinked" class="text-align-center" :class="scoreClasses" v-html="value"></div>
  <a v-else :href="link" target="_blank" class="text-align-center score-linked" :class="scoreClasses" v-html="value"></a>
</template>

<script>
export const EMPTY_SCORE = {
  isEmpty: true,
  score: 0,
  scoreCategoryName: 'none'
}

export default {
  props: {
    emptyValue: {
      type: String,
      default: '-'
    },
    score: Object,
    isSecondary: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    const value = this.score.isEmpty ? this.emptyValue : `${this.score.score}%`

    return {
      value: this.isSecondary ? `(${value})` : value,
      isLinked: '' !== this.link && !this.score.isEmpty
    }
  },
  computed: {
    scoreClasses() {
      return this.score.isEmpty ? '' : `score-color--${this.score.scoreCategoryName}`
    }
  }
}
</script>
