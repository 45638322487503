<template>
  <select-group-for-stream
    class="dropdown--minimalistic"
    :groups="groups"
    :selected-group-id="selectedGroupId"
    :show-teachers="showTeachers"
    :save-setting-token="groupSaveSettingToken"
  ></select-group-for-stream>
</template>

<script>
import SelectGroupForStream from "../SelectGroupForStream";

export default {
  name: 'dropdown-group-stream',
  components: {
    SelectGroupForStream,
  },
  props: {
    groups: Array,
    selectedGroupId: String,
    showTeachers: Boolean,
    groupSaveSettingToken: String,
  },
}
</script>
