var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      {
        staticClass: "lesson__topic column is-one-fifth",
        attrs: { "data-test": "lesson-topic-card-" + _vm.index },
      },
      [
        _c("div", { class: _vm.containerClass }, [
          _c(
            "span",
            {
              staticClass: "card--modal-trigger card--bordered",
              class: [
                _vm.triggerClass,
                { "card--added-to-activities": _vm.store.added },
                { "card--lesson-completed": _vm.store.completed },
              ],
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.onClick.apply(null, arguments)
                },
              },
            },
            [
              _vm.store.groupId && _vm.store.groupId > 0
                ? _c("my-activity-toggle", { attrs: { store: _vm.store } })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card__lesson-status-label",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "16",
                        height: "16",
                        viewBox: "0 0 16 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1.03224 12.2465C1.03224 12.2465 11.3458 7.37428 13.7505 4.96959C16.1551 2.5649 11.6199 1.61634 8.10189 3.52679C4.58392 5.43723 5.27085 9.2908 10.2384 9.2629C11.5615 9.25547 13.5512 9.90807 12.6451 10.8387C11.7537 11.7544 9.18509 11.9736 8.10184 12.709",
                          stroke: "#97BE0C",
                          "stroke-width": "1.6",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            2
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }