var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("select-dropdown", {
        staticClass: "dropdown--minimalistic",
        attrs: { "label-attribute": "aggregatedTitle", options: _vm.blocks },
        model: {
          value: _vm.block,
          callback: function ($$v) {
            _vm.block = $$v
          },
          expression: "block",
        },
      }),
      _vm._v(" "),
      _vm.error ? _c("warning", { attrs: { message: _vm.error } }) : _vm._e(),
      _vm._v(" "),
      _c("learning-goals-with-lessons", {
        attrs: { "learning-goals": _vm.learningGoals, link: _vm.link },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }