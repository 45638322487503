<template>
  <button class="button-group__button button-group__button--outline button"
          :class="buttonClasses"
          :disabled="isDisabled"
          v-html="option.value"
  ></button>
</template>

<script>

export default {
  props: {
    option: Object,
    options: Array,
    disabled: Boolean,
    buttonStyle: String
  },
  computed: {
    isDisabled () {
      return this.disabled || this.option.current
    },
    hasAdviced () {
      return undefined !== this.options.find(option => option.adviced)
    },
    buttonClasses() {
      return [this.buttonStyle, this.buttonClass]
    },
    buttonClass() {
      if (this.option.current) {
        return 'button-group__button--selected'
      }

      if (this.option.single && this.option.selected) {
        return this.option.adviced ? 'button-group__button--selected' : 'button-group__button--teacher-selected'
      }

      if (this.option.single !== true && this.option.selected && (this.option.adviced || !this.hasAdviced)) {
        return 'button-group__button--selected'
      }

      if (this.option.selected && !this.option.adviced) {
        return 'button-group__button--teacher-selected'
      }

      if (!this.option.selected && this.option.adviced) {
        return 'button-group__button--teacher-deselected'
      }

      return ''
    }
  }
}
</script>
