<template>
  <div class="education-level-selector">
    <div class="button-group button-group--cockpit">
      <education-level-selector-education-level
        v-for="(educationLevel, index) in educationLevels.getEducationLevels()"
        :key="educationLevel.identifier"
        :education-level="educationLevel"
        :current-education-level="currentEducationLevel"
        :selected-education-level="selectedEducationLevel"
        :adviced-education-level="advicedEducationLevel"
        @click.native="$emit('select-education-level', educationLevel)"
      ></education-level-selector-education-level>
    </div>
    <div class="education-level-selector-reset">
      <button
        v-if="withReset && showReset"
        class="button button--tiny ml-1"
        @click="$emit('reset-education-level')"
      >
        <i class="icon-replay"></i>
      </button>
    </div>
  </div>
</template>

<script>

import EducationLevelSelectorEducationLevel from './EducationLevelSelectorEducationLevel'
import EducationLevels from '../util/EducationLevels'
import EducationLevel from '../util/EducationLevel'

export default {
  components: { EducationLevelSelectorEducationLevel },
  props: {
    educationLevels: EducationLevels,
    currentEducationLevel: EducationLevel,
    selectedEducationLevel: EducationLevel,
    advicedEducationLevel: EducationLevel,
    withReset: Boolean
  },
  computed: {
    showReset() {
      if (null === this.selectedEducationLevel) {
        return true;
      }

      if (null === this.advicedEducationLevel) {
        return !this.selectedEducationLevel.equals(this.currentEducationLevel)
      }

      return !this.selectedEducationLevel.equals(this.advicedEducationLevel)
    }
  }
}

</script>
