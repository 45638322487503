<template>
  <div>
    <modal-dialog-trigger
      v-on:action="deleteTest"
      :secondary-action-label="$t('generic.term.no')"
      :title="$t('cockpit.test.withdraw.title')"
      close-type="secondary"
      confirm-link="#"
      container-class="flexbox flex"
      trigger-class="flexbox flex"
      :loading="loading"
    >
      <button slot="primary-action" class="button button--primary button--cockpit">{{ $t('generic.term.yes') }}</button>
      <button
        slot="trigger"
        class="button button--small button--primary button--cockpit button--cockpit-with-icon-only"
        data-test="delete-test-button"
      >
        <i class="icon-delete"></i>
      </button>
      <p slot="content">
        <span v-html="$t('cockpit.test.withdraw.confirm_delete', { title: selectedTest.title })"></span><br />
        <span v-html="$t('cockpit.test.withdraw.results_remain')"></span>
      </p>
    </modal-dialog-trigger>
  </div>
</template>

<script>
import TestForm from './TestForm'
import ModalDialogTrigger from '../components/ModalDialogTrigger'
import TestService from './TestService'

export default {
  props: {
    stream: Object,
    token: String,
    scheduledTest: Object,
    selectedTest: Object,
  },
  components: {TestForm, ModalDialogTrigger},
  data() {
    return {
      loading: false,
      testService: new TestService(
        this.stream.code,
        this.token
      ),
    }
  },
  methods: {
    async deleteTest() {
      this.loading = true
      try {
        await this.testService.deleteTest(this.scheduledTest)
        document.location.reload()
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
