<template>
  <label
    :for="'toggle-button-' + id"
    class="toggle-button"
    :class="{ 'toggle-button--on' : isChecked, 'toggle-button--disabled': disabled }"
  >
    <input type="checkbox" :id="'toggle-button-' + id" :name="'toggle-button-' + id" v-model="isChecked" @change.self="onChange" />
    <span class="toggle-button__label-on" v-if="isChecked" v-html="labelOn"></span>
    <span class="toggle-button__switch"></span>
    <span class="toggle-button__label-off" v-if="!isChecked" v-html="labelOff"></span>
  </label>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        default: ''
      },
      labelOn: {
        type: String,
        default: 'Aan'
      },
      labelOff: {
        type: String,
        default: 'Uit'
      },
      checked: Boolean,
      disabled: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      checked() {
        this.isChecked = this.checked
      }
    },

    data() {
      return {
        isChecked: this.checked
      }
    },

    methods: {
      onChange () {
        this.$emit('change', this.isChecked)
      }
    }
  }
</script>
