var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLinked
    ? _c("div", {
        staticClass: "text-align-center",
        class: _vm.scoreClasses,
        domProps: { innerHTML: _vm._s(_vm.value) },
      })
    : _c("a", {
        staticClass: "text-align-center score-linked",
        class: _vm.scoreClasses,
        attrs: { href: _vm.link, target: "_blank" },
        domProps: { innerHTML: _vm._s(_vm.value) },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }