var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "tour__shortcut-button icon-help",
    style: _vm.buttonStyle,
    on: {
      click: function ($event) {
        $event.preventDefault()
        return _vm.click.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }