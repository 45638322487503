<template>
  <div>
    <select-dropdown
      class="dropdown--minimalistic"
      v-model="block"
      label-attribute="aggregatedTitle"
      :options="blocks"
    ></select-dropdown>
    <warning v-if="error" :message="error"/>
    <learning-goals-with-lessons :learning-goals="learningGoals" :link="link"></learning-goals-with-lessons>
  </div>
</template>

<script>

import LearningGoalsWithLessons from './LearningGoalsWithLessons'
import { routeGet } from '../util/request'
import SelectDropdown from '../components/SelectDropdown'
import Warning from '../components/notification/Warning'
import Routing from '../util/routing'
import * as Sentry from "@sentry/vue";

export default {
  components: { SelectDropdown, LearningGoalsWithLessons, Warning },

  props: {
    streamCode: String,
    blockContentPath: String,
    blocks: Array
  },
  data() {
    return {
      learningGoals: [],
      loading: false,
      error: null,
      block: this.blocks.find(block => block.contentPath.join('/') === this.blockContentPath)
    }
  },
  mounted () {
    this.loadLearningGoals()
  },
  watch: {
    block() {
      this.loadLearningGoals()
    }
  },
  computed: {
    link() {
      return Routing.generate('cockpit_learning_goal_results', {
        streamCode: this.streamCode,
        contentPath: this.block.contentPath.join('/')
      })
    }
  },
  methods: {
    async loadLearningGoals() {
      this.$emit('loading')
      this.error = null
      this.loading = true
      try {
        const response = await routeGet('cockpit_learning_goals_with_lessons', {
          streamCode: this.streamCode,
          contentPath: this.block.contentPath.join('/')
        })

        this.learningGoals = response.data
      } catch (exception) {
        Sentry.captureException(new Error(exception.message));
        this.error = exception.message
      }

      this.$emit('loaded')
      this.loading = false
    }
  }
}
</script>
