<template>
  <div class="cockpit-results--actions">
    <show-test-results-button
      :stream="stream"
      :group="group"
      :test="test"
      :store="store"
      :score-categories-data="scoreCategoriesData"
      :modal-links="modalLinks"
    ></show-test-results-button>
    <enter-test-results-button
      :stream="stream"
      :group="group"
      :store="store"
      :token="token"
    ></enter-test-results-button>
    <replay-test-results-button
      :stream="stream"
      :group="group"
      :test="test"
      :store="store"
      :token="token"
    ></replay-test-results-button>
  </div>
</template>

<script>
import ShowTestResultsButton from '../ShowTestResultsButton'
import EnterTestResultsButton from '../EnterTestResultsButton'
import ReplayTestResultsButton from '../ReplayTestResultsButton'
import FolioResultsStore from './FolioResultsStore'

export default {
  components: {
    ShowTestResultsButton,
    EnterTestResultsButton,
    ReplayTestResultsButton
  },
  props: {
    stream: Object,
    group: Object,
    groupEducationLevels: Object,
    test: Object,
    results: Array,
    scoreCategoriesData: Array,
    modalLinks: Array,
    token: String
  },
  data() {
    return {
      store: new FolioResultsStore(this.test, this.group, this.results, this.groupEducationLevels, this.$transChoice)
    }
  },
  watch: {
    'store.resultCount': function () {
      this.updateResultCount()
    }
  },
  mounted() {
    this.updateResultCount()
  },
  methods: {
    updateResultCount() {
      const element = document.getElementById('result_count_' + this.test.id)

      let html = '<div class="folio-result-count">'
      html += '<span>' + this.store.resultCount + '</span>'

      if ('' !== this.store.resultCountWarning) {
        html += '<i class="icon-alert"></i>'
        html += '<span>' + this.store.resultCountWarning + '</span>'
      }
      html += '</div>'
      element.innerHTML = html
    }
  }
}
</script>
