'use strict'

import { routePost, routeGet } from '../util/request'

function mapDataToRequestBody (testData, token) {
  return Object.assign({ _token: token }, testData)
}

export default class TestService {
  /**
   * @param streamCode {string}
   * @param token {string}
   */
  constructor (streamCode, token) {
    this.streamCode = streamCode
    this.token = token
  }

  async assignTest (testData) {
    return await routePost('cockpit_test_admin_create', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(testData, this.token))
  }

  async updateTest (testData) {
    return await routePost('cockpit_test_admin_update', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(testData, this.token))
  }

  async deleteTest (testData) {
    return await routePost('cockpit_test_admin_delete', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(testData, this.token))
  }

  async getTests () {
    return await routeGet('cockpit_test_admin_list', {
      streamCode: this.streamCode
    })
  }

  async resendResults (contentPath) {
    return await routePost('cockpit_test_admin_resend_results', {
      streamCode: this.streamCode,
      contentPath: contentPath.join('/')
    }, { _token: this.token })
  }
}
