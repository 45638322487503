<template>
  <tr>
    <td class="whitespace-nowrap">{{ user.fullName }}</td>
    <td v-if="showEducationLevelColumn" v-html="hasResult && null !== educationLevel ? educationLevel.identifier : ''"></td>
    <td><score :score="averageScore" :link="getLink()"/></td>
    <td v-for="question in questions">
      <score :score="getScore(question)" :empty-value="emptyScoreValue" :link="getLink(question)"/>
    </td>
    <td> </td>
  </tr>
</template>

<script>
import Score, { EMPTY_SCORE } from '../../components/Score'
import EducationLevel from '../../util/EducationLevel'
import AbsoluteLinkService from "../../AbsoluteLinkService";
import ContentPathResolver from "../../ContentPathResolver";

export default {
  components: { Score },

  props: {
    user: Object,
    questions: Array,
    result: Object,
    showEducationLevelColumn: Boolean,
    educationLevel: EducationLevel,
    streamCode: String,
    editionUrl: String,
    contentPath: String,
    groupId: String
  },

  data() {
    const hasResult = this.result.hasOwnProperty('averageScore')
    return {
      hasResult: hasResult,
      averageScore: hasResult ? this.result.averageScore : EMPTY_SCORE,
      emptyScoreValue: hasResult ? this.$t('generic.term.not_applicable_short') : '-'
    }
  },

  methods: {
    getLink(question) {
      if (!this.hasResult) {
        return ''
      }

      const absoluteLinkService = new AbsoluteLinkService(this.editionUrl)
      const contentPathResolver = new ContentPathResolver(question ? question.contentPath : this.contentPath)

      return absoluteLinkService.editionLink(
        'edition_result_group_paragraph',
        {
          streamCode: this.streamCode,
          contentPath: contentPathResolver.paragraphPath(),
          userReference: this.user.userReference,
          itemId: contentPathResolver.itemId(),
          questionId: contentPathResolver.questionId(),
          groupId: this.groupId
        }
      )

    },
    hasScore(question) {
      return this.hasResult && this.result.scores.hasOwnProperty(question.contentPath)
    },
    getScore(question) {
      if (this.hasScore(question)) {
        return this.result.scores[question.contentPath]
      }
      return EMPTY_SCORE
    }
  }
}
</script>
