<template>
  <tr>
    <td class="whitespace-nowrap">{{ user.fullName }}</td>
    <template v-for="(block, index) in store.getBlocksWithResults()">
      <td v-if="index > 0" class="horizontal-divider--left"></td>
      <td v-if="index > 0" class="horizontal-divider--right"></td>
      <td v-html="store.getCompactRouteAdvice(user.userReference, block).name"></td>
      <td v-for="test in block.tests">
        <div class="flexbox flexbox--row flex-wrap--no-wrap flex-justify-content--center">
          <score
            :score="store.getScore(user.userReference, test.contentPath)"
            :empty-value="store.getEmptyScoreValue(user.userReference, test.contentPath)"
            :link="store.getLink(user.userReference, test.contentPath, false)"
          />
          <score
            v-if="null !== store.getOtherScore(user.userReference, test.contentPath)"
            class="ml-half"
            :score="store.getOtherScore(user.userReference, test.contentPath)"
            :is-secondary="true"
            :empty-value="store.getEmptyScoreValue(user.userReference, test.contentPath)"
            :link="store.getLink(user.userReference, test.contentPath, true)"
          />
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import Score from '../../components/Score'
import AllTestResultsStore from './AllTestResultsStore'
import EducationLevelSelector from '../../components/EducationLevelSelector'

export default {
  components: { Score, EducationLevelSelector },
  props: {
    user: Object,
    store: AllTestResultsStore
  }
}
</script>
