<template>
  <div class="form-modal">
    <div class="form-modal__background" @click="close"></div>

    <div class="form-modal__container form-modal__container--wider">

      <button @click.prevent="close" class="form-modal__close"></button>

      <h2 class="labeled-title form-modal__title form-modal__title--full-width mb-2">
        <span class="labeled-title__text">{{ test.subject }}</span>
      </h2>

      <p><links :links="modalLinks" /></p>

      <result-grid
        :questions="test.questions"
        :users="users"
        :store="store"
        :score-categories-data="scoreCategoriesData"
      />

      <div class="form-modal__actions">
        <button @click.prevent="close" class="button button--cockpit button--primary" v-html="$t('generic.term.close')"></button>
      </div>

    </div>
  </div>
</template>

<script>
import ResultGrid from './folio-results/ResultGrid'
import FolioResultsStore from './folio-results/FolioResultsStore'
import Links from '../components/Links'

export default {
  components: {
    ResultGrid,
    Links
  },
  props: {
    group: Object,
    test: Object,
    stream: Object,
    token: String,
    store: FolioResultsStore,
    scoreCategoriesData: Array,
    modalLinks: Array
  },

  data: function () {
    return {
      users: this.group.members,
      validationErrors: [],
      loading: false
    }
  },

  methods: {
    close () {
      if (this.loading) {
        return
      }

      this.$emit('close')
    },

    updateScore (questionId, userReference, score) {
      for (let result of this.results) {
        if (result.questionId === questionId && result.userReference.reference === userReference) {
          result.score = score
          this.results = [...this.results]
          return
        }
      }
      this.results = [...this.results, {
        questionId,
        testId: this.test.id,
        userReference: { reference: userReference },
        score
      }]
    },

    async showResultsEntryScreen () {
      this.validationErrors = []
      if (!this.valid) {
        if (!this.usersAreValid) {
          this.validationErrors.push(this.$t('cockpit.test.error.select_at_least_1_student'))
        }
        return
      }

      this.step = 'results_entry'
    },

    async saveAndSubmit () {
      this.loading = true

      try {
        await this.folioTestService.saveTestResults(this.group.id, this.test.id, this.results)
        document.location.reload()
        this.close()
      } catch (exception) {
        console.error(exception.message)
      } finally {
        this.loading = false
      }
    }

  }
}
</script>
