<template>
  <table class="table table--full-width table--condensed cockpit-results cockpit-results--hover">
    <thead>
    <tr class="cockpit-results--heading">
      <th></th>
      <th v-for="question in questions" class="text-align-center">{{ question.label }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="user in users">
      <td class="cockpit-results--name">{{ user.fullName }}</td>
      <td v-for="question in questions"
          class="text-align-center">{{ store.getStoredPoints(question.id, user.userReference) }}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ScoreCategories from '../../util/ScoreCategories'
import FolioResultsStore from './FolioResultsStore'

export default {
  props: {
    questions: Array,
    users: Array,
    store: FolioResultsStore,
    scoreCategoriesData: Array
  },
  data() {
    return {
      scoreCategories: ScoreCategories.fromData(this.scoreCategoriesData)
    }
  }
}
</script>
