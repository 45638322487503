var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lesson-navigation__week-container tab-content__inner",
      attrs: { id: "block-" + _vm.block.contentPath[0] },
    },
    _vm._l(_vm.block.weeks, function (week, index) {
      return _c("week-item", {
        key: index,
        attrs: {
          week: week,
          "select-week": _vm.selectWeek,
          "route-service": _vm.routeService,
          "block-number": _vm.block.number,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }