var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("startdescription"),
      _vm._v(" "),
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              _vm.open = true
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("cockpit.progress.select_learning_goal")))]
      ),
      _vm.noFinishDescriptionSlot ? [_vm._v(".")] : _vm._e(),
      _vm._v(" "),
      _vm._t("finishdescription"),
      _vm._v(" "),
      _vm.open
        ? _c("learning-goals-for-block-modal", {
            attrs: {
              "stream-code": _vm.streamCode,
              blocks: _vm.blocks,
              "block-content-path": _vm.blockContentPath,
            },
            on: {
              close: function ($event) {
                _vm.open = false
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }