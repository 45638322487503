
'use strict'

import { createPopper } from '@popperjs/core'

export class DropdownPopper {
  constructor () {
    this._popperInstance = null
    this._content = null

    this._offset = {
      name: 'offset',
      options: { offset: [0, -1] }
    }
    this._options = {
      onFirstUpdate: (state) => {
        this._onOpen()
      },
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'computeStyles',
          options: { gpuAcceleration: false }
        },
        this._offset,
        {
          name: 'preventOverflow',
          options: { mainAxis: false }
        }
      ]
    }
    this._onOpen = () => {}
    this._maxHeight = Number.MAX_SAFE_INTEGER
  }

  createPopper (dropdownContent, dropdownTrigger, dropdownMenu) {
    dropdownContent.style.minWidth = `${dropdownTrigger.offsetWidth}px`

    this._content = dropdownContent
    this._popperInstance = createPopper(
      dropdownTrigger,
      dropdownMenu,
      this._options
    )
  }

  withOffset (offset) {
    this._offset.options.offset = offset
    return this
  }

  withOnOpen (callback) {
    this._onOpen = callback
    return this
  }

  withMaxHeight (maxHeight) {
    this._maxHeight = maxHeight
    return this
  }

  createScrollablePopper (dropdownContent, dropdownTrigger, dropdownMenu) {
    this.createPopper(dropdownContent, dropdownTrigger, dropdownMenu)

    this._setMaxHeight()

    this.setMaxHeight = this._setMaxHeight.bind(this)
    window.addEventListener('resize', this.setMaxHeight)
  }

  _setMaxHeight () {
    if (this._content === null) {
      return
    }

    let maxHeight = Math.min(window.innerHeight - this._content.getBoundingClientRect().top - 100, this._maxHeight)
    maxHeight = Math.max(100, maxHeight)
    this._content.style.maxHeight = `${maxHeight}px`
  }

  destroy () {
    if (this._popperInstance === null) {
      return
    }
    window.removeEventListener('resize', this.setMaxHeight)
    this._popperInstance.destroy()
    this._popperInstance = null
    this._content = null
    this._onOpen = () => {}
  }
}
