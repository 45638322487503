<template>
  <div :id="'block-' + block.contentPath[0]" class="lesson-navigation__week-container tab-content__inner">
    <week-item
      v-for="(week, index) in block.weeks"
      :week="week"
      :key="index"
      :select-week="selectWeek"
      :route-service="routeService"
      :block-number="block.number"
    ></week-item>
  </div>
</template>

<script>
  import WeekItem from './WeekItem'
  import ContentPath from '../../util/ContentPath'
  import CockpitRouteService from '../../CockpitRouteService'

  export default {
    components: {
      WeekItem
    },
    props: {
      block: Object,
      isSelected: Boolean,
      selectWeek: Boolean,
      routeService: CockpitRouteService
    }
  }
</script>
