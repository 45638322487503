var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "result-entry-words" },
    [
      _vm._l(_vm.question.entryType.data.words, function (word, key) {
        return [
          _vm.wordIsEnabled(key)
            ? _c(
                "div",
                {
                  staticClass: "word",
                  class: _vm.results[key] ? "correct" : "incorrect",
                  on: {
                    click: function ($event) {
                      return _vm.toggleCorrect(key)
                    },
                  },
                },
                [
                  _c("span", {
                    staticClass: "word-icon",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.results[key] ? _vm.correctIcon : _vm.incorrectIcon
                      ),
                    },
                  }),
                  _vm._v("\n      " + _vm._s(word) + "\n    "),
                ]
              )
            : _c("div", { staticClass: "word predefined" }, [
                _c("div", { staticClass: "word-icon-container" }, [
                  _c("span", {
                    staticClass: "word-icon",
                    domProps: { innerHTML: _vm._s(_vm.predefinedIcon) },
                  }),
                ]),
                _vm._v("\n      " + _vm._s(word) + "\n    "),
              ]),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }