var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass:
        "modal__status-toggle question-label question-label--type-selfscore-complete",
      class: { "question-label--score-good": _vm.store.added },
      attrs: { for: "activity-toggle-" + _vm.store.contentPath },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.store.added,
            expression: "store.added",
          },
        ],
        staticClass: "invisible",
        attrs: {
          type: "checkbox",
          id: "activity-toggle-" + _vm.store.contentPath,
          name: "activity-toggle-" + _vm.store.contentPath,
        },
        domProps: {
          checked: Array.isArray(_vm.store.added)
            ? _vm._i(_vm.store.added, null) > -1
            : _vm.store.added,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.store.added,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.store, "added", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.store,
                      "added",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.store, "added", $$c)
              }
            },
            function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.onChange.apply(null, arguments)
            },
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.store.added,
              expression: "store.added",
            },
          ],
          attrs: {
            "data-test": "lesson-topic-modal-activity-toggle-" + _vm.index,
          },
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t(
                "cockpit.activities.card.modal.added-to-my-activities-label"
              )
            )
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }