<template>
  <div class="learn-lines-navigation pt-2">

    <ul class="learn-lines-navigation__list learn-lines-navigation__list--level-1">
      <li :data-test="'learn-lines-navigation-list-level-1-' + index"
          class="learn-lines-navigation__item"
          :class="[
            'learn-lines-navigation__item--' + (++index),
            { 'learn-lines-navigation__item--opened' : node.opened }
          ]"
          v-for="(node, index) in rootNodes">

        <Header :node="node" v-on:open="openNode" v-on:close="closeNode"/>

        <div class="learn-lines-navigation__list-wrapper">
          <tabs
            :first-level-index="index"
            class="px-6 flexbox"
            tabs-additional-class="learn-lines-navigation__list learn-lines-navigation__list--level-2 mt-3"
            identifier="level2"
          >
            <tab
              v-for="(levelOneChild, childIndex) in node.children"
              class="flexbox flexbox--column mt-1"
              :uid="childIndex"
              :name="levelOneChild.title"
              :active="index === 0"
              :key="'tabs-' + index + '-tab-' + childIndex"
            >
              <ul class="learn-lines-navigation__list learn-lines-navigation__list--level-3">
                <li v-for="(goalNode, indexLevel3) in _getLessonGoals(levelOneChild)" :data-test="'learn-lines-navigation-list-level-3-' + indexLevel3" class="learn-lines-navigation__item">
                  <a class="learn-lines-navigation__item-link" @click="onGoalClick(goalNode)">{{ goalNode.title }}</a>
                  <p class="ml-auto mr-3 mb-0" v-if="levelOneChild.activitiesCompleted"><span :title="checkedActivitiesNumberLabel">{{ levelOneChild.activitiesCompleted }}</span><span :title="targetActivitiesNumberLabel" v-if="levelOneChild.activities">/{{ levelOneChild.activities }}</span></p>
                  <donut class="ml-auto mr-0" size="small" v-if="levelOneChild.activitiesCompleted" :progress="((levelOneChild.activitiesCompleted / levelOneChild.activities) * 100) || 0"></donut>
                </li>
              </ul>
            </tab>

          </tabs>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Routing from '../../util/routing'
  import Tabs from '../tabs/Tabs'
  import Tab from '../tabs/Tab'
  import Header from './Header.vue'

  export default {
    data () {
      const rootNodes = []
      for (const child of this.learnLineRoot.children) {
        for (const subChild of child.children) {
          rootNodes.push(subChild)
        }
      }
      return {
        rootNodes: rootNodes
      }
    },

    components: {
      Tabs,
      Tab,
      Header
    },

    props: {
      learnLineRoot: Object,
      streamCode: String,
      checkedActivitiesNumberLabel: String,
      targetActivitiesNumberLabel: String,
      searchActivityRoute: String
    },

    methods: {
      _getLessonGoals (domain) {
        if (domain.children.length === 0) {
          return domain
        }
        return domain.children.map(x => this._getLessonGoals(x)).flat()
      },

      openNode (node) {
        Vue.set(node, 'opened', true)
      },

      closeNode (node) {
        Vue.set(node, 'opened', false)
      },

      onGoalClick (node) {
        document.location = Routing.generate(this.searchActivityRoute, {
          streamCode: this.streamCode,
          lessonGoal: node.id
        })
      }
    }
  }
</script>
