'use strict';

class Dropdown {
  constructor(element, trigger, beforeOpen) {
    this.element = element;
    this.trigger = trigger;
    this.beforeOpen = beforeOpen;

    this.trigger.addEventListener('click', (event) => {
      event.stopPropagation();
      this.toggle();
    });
    this.trigger.addEventListener('touchstart', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.toggle();
    }, {passive: false})

    this.close();
  }

  toggle() {
    if (this.isOpen()) {
      return this.close();
    }
    this.open();
  }

  contains(element) {
    return this.element.contains(element);
  }

  isOpen() {
    return this.element.classList.contains('is-active');
  }

  open() {
    this.beforeOpen(this.element);
    this.element.classList.add('is-active');
    this.trigger.classList.add('is-active');
  }

  close() {
    this.element.classList.remove('is-active');
    this.trigger.classList.remove('is-active');
  }
}

export default class NavbarManager {
  constructor () {
    this.dropdowns = [];

    const dropdownElements = document.querySelectorAll('.js-navbar-dropdown');
    dropdownElements.forEach((element) => {
      this.dropdowns.push(new Dropdown(element, element.querySelector('.js-navbar-dropdown-trigger'), this.closeAllBut.bind(this)));
    });

    const navbarBurgerElements = document.querySelectorAll('.navbar-burger');
    navbarBurgerElements.forEach((element) => {
      this.dropdowns.push(new Dropdown(document.getElementById(element.dataset.target), element, this.closeAllBut.bind(this)));
    });

    document.addEventListener('click', () => {
      for (const dropdown of this.dropdowns) {
        dropdown.close();
      }
    })
  }

  closeAllBut(element) {
    for (const dropdown of this.dropdowns) {
      if (dropdown.contains(element)) {
        continue;
      }
      dropdown.close();
    }
  }
}
