<template>
  <div class="progress-bar">
    <div class="progress-bar__tube">
      <span class="progress-bar__sand" :style="{ 'maxWidth' : percentage + '%' }"></span>
    </div>
    <div class="progress-bar__timer">
      {{ countdown }}
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        secondsTotal: 0,
        secondsRemaining: 0,
        forceQuitEnabled: true
      }
    },

    computed: {

      secondsLabel () {
        return String(this.secondsRemaining % 60).padStart(2, '0')
      },

      minutesLabel () {
        return Math.ceil(this.secondsRemaining / 60)
      },

      countdown () {
        if (this.secondsRemaining < 60) {
          return `0:${this.secondsLabel}`
        }
        return `${this.minutesLabel}:00`
      },

      percentage () {
        return 100 - (100 / this.secondsTotal * this.secondsRemaining)
      }
    },

    props: {
      maxTimeInMinutes: {
        default: 5,
        type: Number
      },
      submitFormId: {
        default: '',
        type: String
      },
      timeRemainingInSeconds: Number
    },

    watch: {
      secondsRemaining (value) {
        if (value <= 0) {
          this.forceQuitCurrentStudentSession()
        }
      }
    },

    mounted () {
      this.secondsTotal = this.maxTimeInMinutes * 60
      this.secondsRemaining = this.timeRemainingInSeconds

      window.addEventListener('beforeunload', () => {
        this.forceQuitEnabled = false;
      })

      setInterval(() => {
        if (this.secondsRemaining > 0) {
          this.secondsRemaining--
        }
      }, 1000)
    },

    methods: {
      forceQuitCurrentStudentSession () {
        if (this.forceQuitEnabled) {
          document.body.style.pointerEvents = 'none'
          document.getElementById(this.submitFormId).submit()
        }
      }
    }
  }
</script>
