export default class ActivityStore {
  constructor (added, completed, cleanup, token, contentPath, groupId) {
    this.added = added
    this.completed = completed
    this.cleanup = cleanup
    this.token = token
    this.contentPath = contentPath
    this.groupId = groupId
  }
}
