<template>
  <div>
    <a v-for="learningGoal in learningGoals" class="flexbox flex-align-items--center p-1 -mx-1 learning-goal" :href="getLink(learningGoal)">
      <div class="flex">{{ learningGoal.learningGoal }}</div>
      <div class="flexbox flex-wrap--wrap learning-goal__lessons ml-1">
        <div v-for="(lesson, index) in learningGoal.lessons" class="lesson-navigation__item lesson-navigation__item--inversed lesson-navigation__item--small" :class="lessonClass(learningGoal.lessons, index)">
          <div class="lesson-navigation__item-no-link">{{ lesson.number }}</div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    learningGoals: Array,
    link: String
  },
  methods: {
    lessonClass (lessons, index) {
      if (index === 0 && lessons.length === 1) {
        return ''
      } else if (index === 0) {
        return 'lesson-navigation__item--type-rounded-left'
      } else if (index === (lessons.length - 1)) {
        return 'lesson-navigation__item--type-rounded-right'
      } else {
        return 'lesson-navigation__item--type-squared'
      }
    },

    getLink (learningGoal) {
      return this.link + '?learningGoal=' + learningGoal.learningGoalPath
    }
  }
}
</script>
