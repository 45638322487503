<template>
  <div class="education-level-selector education-level-selector--centered">
    <div class="button-group button-group--cockpit">
      <my-content-education-level
        v-for="(educationLevel, index) in educationLevels"
        :key="educationLevel.educationLevel"
        :education-level="educationLevel"
        :initial-education-level="initialEducationLevel"
        :selected-education-level="selectedEducationLevel"
        @click.native="selectEducationLevel(educationLevel)"
      ></my-content-education-level>
    </div>
    <div class="education-level-selector-reset">
      <button
        v-if="withReset && initialEducationLevel !== selectedEducationLevel"
        class="button button--tiny ml-1"
        @click="$emit('reset-education-level')"
      >
        <i class="icon-replay"></i>
      </button>
    </div>
  </div>
</template>

<script>

import MyContentEducationLevel from './MyContentEducationLevel'

export default {
  components: { MyContentEducationLevel },
  props: {
    educationLevels: Array,
    initialEducationLevel: Object,
    selectedEducationLevel: Object,
    withReset: Boolean
  },
  methods: {
    selectEducationLevel (educationLevel) {
      this.$emit('select-education-level', educationLevel)
    }
  }
}

</script>
