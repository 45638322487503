<template>
  <div>
   <button class="button button--small button--primary button--cockpit" @click="open = true">{{ $t('cockpit.progress.select_learning_goal_title') }}</button>
    <learning-goals-for-block-modal v-if="open" :stream-code="streamCode" :blocks="blocks" :block-content-path="blockContentPath" @close="open = false">
    </learning-goals-for-block-modal>
  </div>
</template>

<script>
import LearningGoalsForBlockModal from './LearningGoalsForBlockModal'
export default {
  components: { LearningGoalsForBlockModal },
  props: {
    streamCode: String,
    blockContentPath: String,
    blocks: Array
  },
  data() {
    return {
      open: false
    }
  }
}
</script>

