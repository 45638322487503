var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.target !== "" || _vm.isSelected || _vm.renderHidden
    ? _c(
        "div",
        {
          ref: "tabContent",
          staticClass: "tab-content",
          class: { "is-hidden": !_vm.isSelected || _vm.target !== "" },
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }