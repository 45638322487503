var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { attrs: { "data-test": "resend-form-row-" + _vm.componentIndex } },
    [
      _c("td", [_vm._v(_vm._s(_vm.test.title))]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.test.resultCount))]),
      _vm._v(" "),
      _c("td", [
        _c(
          "button",
          {
            staticClass: "button button--small button--primary button--cockpit",
            class: { "is-loading": _vm.loading },
            attrs: {
              disabled: _vm.disabled,
              "data-test": "resend-form-resend-button-" + _vm.componentIndex,
            },
            on: { click: _vm.resend },
          },
          [_c("i", { staticClass: "icon-send" })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }