var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "lesson-navigation__item",
      class: [
        _vm.typeClasses,
        {
          "lesson-navigation__item--completed": _vm.lesson.completed,
          "lesson-navigation__item--active": _vm.lesson.active,
        },
      ],
      attrs: {
        "data-test":
          "lesson-navigation-" +
          _vm.type +
          "-" +
          _vm.blockNumber +
          "-" +
          _vm.lesson.number,
      },
    },
    [
      _c(
        "a",
        {
          staticClass:
            "lesson-navigation__item-link lesson-navigation__item-link-day",
          attrs: { href: _vm.lessonLink, title: _vm.lesson.label },
        },
        [_vm._v(_vm._s(_vm.lesson.number))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }