const breakpointPixels = {
  'fullhd': 1344,
  'widescreen': 1152,
  'desktop': 960,
  'tablet': 768,
  'mobile-landscape': 500,
  'mobile': -1
};

const breakpointNames = ['fullhd', 'widescreen', 'desktop', 'tablet', 'mobile-landscape', 'mobile'];

const getBreakpointName = (width) => {
  for(const breakpointName of breakpointNames) {
    if (width > breakpointPixels[breakpointName]) {
      return breakpointName;
    }
  }
};

export default class BreakPoints {
  static getClass(width) {
    return 'is-breakpoint-' + getBreakpointName(width)
  }
  static isMobile(width) {
    const name = getBreakpointName(width)
    return 'mobile' === name || 'mobile-landscape' === name
  }
}
