<template>
  <focus-trap>
    <div class="fixed inset-0 items-center flex items-center z-50 p-5"
         :class="{'bg-black bg-opacity-50': backdrop,
         'cursor-pointer': dismissViaBackdrop}"
         @click.self="backdropClicked"
         role="dialog"
         aria-modal="true"
         :aria-label="title">

      <div class="bg-white m-auto rounded-lg shadow-xl cursor-auto relative flex flex-col" ref="modalContent" :class="contentClasses" style="max-height: min(600px, 100%)">
        <template v-if="type.isEmpty() || type.isEmptyCondensed()">
          <slot></slot>
        </template>
        <div v-else class="overflow-auto" :class="{'p-16 pb-4': type.isDefault(), 'p-4': type.isCondensed()}">
          <div :class="{'text-center': type.isDefault(), 'text-left': type.isCondensed()}">
            <div class="w-28 mx-auto mb-12" v-if="icon" v-html="icon"></div>
            <h5 v-if="title">{{ title }}</h5>
            <slot></slot>
          </div>
          <div class="space-x-4 flex"
               :class="{'mt-12 justify-center': type.isDefault(), 'mt-4 justify-end': type.isCondensed()}">
            <component v-for="button in buttons"
                       :is="getButtonComponent(button.type)"
                       :key="button.label"
                       :label="button.label"
                       @click="() => button.handler()"></component>
          </div>
        </div>

        <button v-if="closeButton" class="absolute top-4 right-4 flex p-1 cursor-pointer" @click="close" tabindex="0" aria-label="Sluiten">
          <inline-icon icon="close"></inline-icon>
        </button>
      </div>
    </div>
  </focus-trap>
</template>

<script>
// Een modal beheert niet zijn eigen visibility. Dit doe je van buiten middels een <Modal v-if="isVisible" @close="isVisible=false"..>

import { FocusTrap } from 'focus-trap-vue'

import ButtonPrimary from '../buttons/ButtonPrimary'
import ButtonSecondary from '../buttons/ButtonSecondary'
import ButtonTertiary from '../buttons/ButtonTertiary'
import InlineIcon from '../InlineIcon'
const closeIcon = require('@Icons/close.svg')

import disableBodyScroll from '../../util/disable-body-scroll'
import { enableBodyScroll } from 'body-scroll-lock';

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
    ButtonTertiary,
    InlineIcon,
    FocusTrap
  },
  data() {
    return {
      closeIcon
    }
  },
  props: {
    buttons: Array,
    closeButton: {
      type: Boolean,
      default: true
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    dismissViaBackdrop: {
      type: Boolean,
      default: true
    },
    cancelCallback: Function,
    type: ModalType,
    title: String,
    icon: String
  },
  computed: {
    contentClasses() {
      return {
        'w-full': true,
        'sm:w-128': this.type.isDefault() || this.type.isCondensed() || this.type.isEmptyCondensed(),
        'sm:w-160 md:w-208': this.type.isEmpty(),
      }
    }
  },
  methods: {
    getButtonComponent (type) {
      if (type === 'primary') {
        return ButtonPrimary
      }
      if (type === 'secondary') {
        return ButtonSecondary
      }
      return ButtonTertiary
    },
    backdropClicked () {
      if (this.dismissViaBackdrop) {
        this.close()
      }
    },
    close () {
      this.$emit('close')
    },
    onKeyDown (evt) {
      if (evt.key === 'Escape') {
        this.close()
      }
    }
  },
  mounted () {
    window.addEventListener('keydown', this.onKeyDown)
    this.$nextTick().then(() => {
      disableBodyScroll(this.$refs.modalContent)
    })
  },
  beforeDestroy() {
    enableBodyScroll(this.$refs.modalContent)
  },
  destroyed () {
    window.removeEventListener('keydown', this.onKeyDown)
  }
}

const TYPE_DEFAULT = 'default'
const TYPE_CONDENSED = 'condensed'
const TYPE_EMPTY = 'empty'
const TYPE_EMPTY_CONDENSED = 'empty-condensed'

export class ModalType {
  constructor (type) {
    this.type = type
  }

  isDefault () {
    return this.type === TYPE_DEFAULT
  }

  isCondensed () {
    return this.type === TYPE_CONDENSED
  }

  isEmpty () {
    return this.type === TYPE_EMPTY
  }

  isEmptyCondensed () {
    return this.type === TYPE_EMPTY_CONDENSED
  }

  static default () {
    return new ModalType(TYPE_DEFAULT)
  }

  static condensed () {
    return new ModalType(TYPE_CONDENSED)
  }

  static empty () {
    return new ModalType(TYPE_EMPTY)
  }

  static emptyCondensed () {
    return new ModalType(TYPE_EMPTY_CONDENSED)
  }
}
</script>
