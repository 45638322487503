'use strict'

import {routePost} from '../../../util/request'

function mapDataToRequestBody (requestBody, token, learningGoalPath, contentReferences) {
  return Object.assign({ _token: token, learningGoal: learningGoalPath, contentReferences: contentReferences }, requestBody)
}

export default class MyContentService {
  /**
   * @param streamCode {string}
   * @param token {string}
   * @param learningGoalPath {string}
   */
  constructor (streamCode, token, learningGoalPath, contentReferences) {
    this.streamCode = streamCode
    this.token = token
    this.learningGoalPath = learningGoalPath
    this.contentReferences = contentReferences
  }

  async assignMyContentCollection (myContent) {
    return await routePost('cockpit_assign_my_content', {
      streamCode: this.streamCode
    }, mapDataToRequestBody({ myContent }, this.token, this.learningGoalPath, this.contentReferences))
  }

  async assignMyContent (singleMyContent, learningGoalPath, contentReferences) {
    const myContent = [singleMyContent]

    return await routePost('cockpit_assign_my_content', {
      streamCode: this.streamCode
    }, mapDataToRequestBody({ myContent }, this.token, learningGoalPath, contentReferences))
  }

  async assignMyContentForUserItemResult (myContent) {
    return await routePost('cockpit_assign_my_content_user_item_result', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(myContent, this.token, this.learningGoalPath, this.contentReferences))
  }

  async withdrawMyContent (myContent) {
    return await routePost('cockpit_withdraw_my_content', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(myContent, this.token, this.learningGoalPath, this.contentReferences))
  }

  async deleteActiveLearningGoal (myContent) {
    return await routePost('cockpit_delete_my_content', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(myContent, this.token, this.learningGoalPath, this.contentReferences))
  }

  async archiveMyContent (myContent) {
    return await routePost('cockpit_archive_my_content', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(myContent, this.token, this.learningGoalPath, this.contentReferences))
  }

  async restoreMyContent (myContent) {
    return await routePost('cockpit_restore_my_content', {
      streamCode: this.streamCode
    }, mapDataToRequestBody(myContent, this.token, this.learningGoalPath, this.contentReferences))
  }
}
