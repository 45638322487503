<template>
  <button class="button-group__button button-group__button--outline button button--tiny"
    :class="buttonClass"
    :disabled="educationLevel.state.isOpen()"
  >{{ educationLevel.educationLevel }}</button>
</template>

<script>

export default {
  props: {
    educationLevel: Object,
    initialEducationLevel: Object,
    selectedEducationLevel: Object
  },
  computed: {
    buttonClass() {
      if (this.educationLevel.state.isOpen()) {
        if (this.educationLevel === this.initialEducationLevel) {
          return 'button-group__button--selected'
        } else {
          return 'button-group__button--teacher-selected'
        }
      }

      if (this.educationLevel === this.initialEducationLevel) {
        if (this.educationLevel === this.selectedEducationLevel) {
          return 'button-group__button--selected'
        } else {
          return 'button-group__button--teacher-deselected'
        }
      }

      if (this.educationLevel === this.selectedEducationLevel) {
        return 'button-group__button--teacher-selected'
      }

      return ''
    }
  }
}
</script>
