var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tabs",
    {
      attrs: {
        identifier: "blocks",
        "first-level-index": "1",
        "tabs-additional-class": "tabs--cockpit cockpit-tabs--blocks",
        "page-wrapper-class":
          "tab-pages--cockpit-content-lesson-navigation mt-3",
        selector: "lesson-navigation",
      },
    },
    _vm._l(_vm.blocks, function (block, index) {
      return _c(
        "tab",
        {
          key: index,
          attrs: {
            name: block.aggregatedTitle,
            active: block.active,
            uid: index,
          },
        },
        [
          _c("block-item", {
            attrs: {
              block: block,
              "select-week": _vm.selectWeek,
              "route-service": _vm.routeService,
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }