var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass:
        "table table--full-width table--condensed cockpit-results cockpit-results--hover",
    },
    [
      _c("thead", [
        _c(
          "tr",
          { staticClass: "cockpit-results--heading" },
          [
            _c("th"),
            _vm._v(" "),
            _vm._l(_vm.questions, function (question) {
              return _c("th", { staticClass: "text-align-center" }, [
                _vm._v(_vm._s(question.label)),
              ])
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.users, function (user) {
          return _c(
            "tr",
            [
              _c("td", { staticClass: "cockpit-results--name" }, [
                _vm._v(_vm._s(user.fullName)),
              ]),
              _vm._v(" "),
              _vm._l(_vm.questions, function (question) {
                return _c("td", { staticClass: "text-align-center" }, [
                  _vm._v(
                    _vm._s(
                      _vm.store.getStoredPoints(question.id, user.userReference)
                    ) + "\n    "
                  ),
                ])
              }),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }