var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", {
    staticClass: "button-group__button button-group__button--outline button",
    class: _vm.buttonClasses,
    attrs: { disabled: _vm.isDisabled },
    domProps: { innerHTML: _vm._s(_vm.option.value) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }