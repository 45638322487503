var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "card",
        {
          attrs: {
            "container-class": _vm.containerClass,
            "trigger-class": _vm.triggerClass,
            completed: _vm.initiallyCompleted,
            index: _vm.index,
          },
          on: { click: _vm.onClick },
        },
        [_vm._t("card")],
        2
      ),
      _vm._v(" "),
      _vm.opened
        ? _c(
            "modal",
            {
              ref: "modal",
              attrs: {
                title: _vm.title,
                index: _vm.index,
                meta: _vm.meta,
                "meta-leerjaar-label": _vm.metaLeerjaarLabel,
                "view-event-url": _vm.viewEventUrl,
              },
              on: { close: _vm.close },
            },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }