var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isRoot
        ? _c("div", { staticClass: "flexbox" }, [
            _c("a", {
              staticClass: "ml-1",
              class: _vm.iconClass,
              on: { click: _vm.toggleOpen },
            }),
            _c("a", {
              staticClass: "dropdown__item flex whitespace-nowrap",
              class: _vm.nodeClass,
              domProps: { innerHTML: _vm._s(_vm.node.title) },
              on: { click: _vm.onClick },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.node.children.length > 0 && _vm.open
        ? _vm._l(_vm.node.children, function (child) {
            return _c("tree-node", {
              key: child.id,
              class: { "ml-1": !_vm.isRoot },
              attrs: {
                node: child,
                "is-root": false,
                "select-leafs-only": _vm.selectLeafsOnly,
                "selected-branch": _vm.selectedBranch,
              },
              on: { "select-node": _vm.selectNode },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }