import {
  EventBus,
  TOOL_USE_EVENT
} from './common/EventBus'

class GoogleAnalyticsService {
  constructor () {
    EventBus.$on(TOOL_USE_EVENT, (href, toolType, action = 'clicked') => {
      if (toolType) {
        gtag('event', action, {
          event_category: toolType
        });
      }
    })
  }
}

export class GoogleAnalyticsServiceFactory {
  static createGoogleAnalyticsService (configuration) {
    return new GoogleAnalyticsService(configuration.brinCode, configuration.isTeacher)
  }
}
