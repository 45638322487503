<template>
  <a href="#" data-test="lesson-status-toggle" class="lesson__status-toggle">
    <input type="checkbox" name="lesson-status" id="lesson-status" class="lesson-status-toggle__input"
           v-model="completed" v-on:change="onChange">
    <label data-test="lesson-status-color" for="lesson-status" class="lesson__status-background"></label>
    <span class="lesson__status-icon icon-check"></span>
  </a>
</template>

<script>
  import axios from 'axios'
  import Routing from '../util/routing'
  import { ACTION_EVENT, EventBus } from "../common/EventBus";
  import { ACTION_EVENT_LESSON_CHECKED, ACTION_EVENT_LESSON_UNCHECKED } from "../common/ThasActionEventActionType";

  export default {
    name: 'Checkmark',
    props: {
      initialCompleted: {
        type: Boolean,
        default: false
      },
      groupId: [String, Number],
      lessonId: String,
      token: String
    },
    data () {
      return {
        completed: this.initialCompleted
      }
    },
    methods: {
      onChange () {
        const context = {
          streamCode: msp.streamCode,
          contentPath: this.lessonId,
          groupId: this.groupId
        }

        EventBus.$emit(ACTION_EVENT, window.location.href, this.getActionType(), context)

        const url = Routing.generate(
          'cockpit_group_lesson',
          {
            streamCode: msp.streamCode,
            contentPath: this.lessonId,
            groupId: this.groupId
          }
        )

        axios({
          method: 'post',
          url: url,
          data: {
            completed: this.completed,
            _token: this.token
          }
        })
      },
      getActionType() {
        if (this.completed) {
          return ACTION_EVENT_LESSON_CHECKED
        }

        return ACTION_EVENT_LESSON_UNCHECKED;
      }
    }
  }
</script>
