<template>
  <group-dropdown
    :groups="groups"
    :selected-group-id="selectedGroupId"
    :show-teachers="showTeachers"
    select-group-title="Selecteer een groep"
    :show-all-groups="false"
    settings-title="Instellingen"
    show-teachers-title="Toon docent(en)"
    manual-groups-title="Jouw groepen"
    static-groups-title="Alle groepen"
    v-on:group-selected="changeGroupAndShowTeachers"
    v-on:show-teachers-changed="changeGroupAndShowTeachers"
  ></group-dropdown>
</template>

<script>
  import GroupDropdown from '../components/GroupDropdown'
  import SelectedGroupSettingService from '../components/settings/SelectedGroupSettingService'

  export default {
    components: {
      GroupDropdown,
    },
    props: {
      groups: Array,
      showAllGroups: Boolean,
      selectedGroupId: String,
      showTeachers: Boolean,
      saveSettingToken: String
    },
    created () {
      this.$options.selectedGroupSettingService = new SelectedGroupSettingService(this.saveSettingToken)
    },
    methods: {
      async changeGroupAndShowTeachers (group, showTeachers) {
        await this.$options.selectedGroupSettingService.setGroupAndShowTeachers(group, showTeachers)
        window.location.reload();
      }
    }
  }
</script>
