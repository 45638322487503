var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "date-time-input" }, [
    _vm.pickDate
      ? _c(
          "span",
          { class: { "mr-3": _vm.pickTime } },
          [
            _c("datepicker", {
              attrs: {
                id: _vm.id,
                "input-class": _vm.dateInputClass,
                "calendar-class": _vm.calendarClass,
                format: "dd-MM-yyyy",
                "disabled-dates": _vm.disabledDates,
              },
              on: { input: _vm.onInput },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.pickTime
      ? _c("span", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.time,
                expression: "time",
              },
            ],
            staticClass: "date-time-input__time",
            class: _vm.inputClass,
            attrs: { type: "text", id: _vm.id + "_time" },
            domProps: { value: _vm.time },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.time = $event.target.value
                },
                _vm.onInput,
              ],
            },
          }),
          _vm._v(" "),
          _c("small", [_vm._v("HH:MM")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }