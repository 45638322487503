<template>
  <StickyTable :sticky-heads="1" :sticky-columns="showEducationLevelColumn ? 3 : 2" class="table table--full-width cockpit-results--hover" data-test="test-results-table">
    <thead>
    <tr class="cockpit-results--heading vertical-align-middle">
      <th></th>
      <th v-if="showEducationLevelColumn" v-html="$t('generic.term.testlevel')"></th>
      <th>{{ $t('generic.term.score') }}</th>
      <th v-for="question in test.questions" class="text-align-center whitespace-nowrap">{{ question.number }}</th>
      <th style="width:100%">&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <template v-for="user in users">
      <test-result
        :user="user"
        :questions="test.questions"
        :show-education-level-column="showEducationLevelColumn"
        :education-level="getUserEducationLevel(user.userReference)"
        :result="getUserResults(user.userReference)"
        :stream-code="streamCode"
        :edition-url="editionUrl"
        :content-path="test.contentPath"
        :group-id="groupId"
      ></test-result>
    </template>
    </tbody>
  </StickyTable>
</template>

<script>
import TestResult from './TestResult'
import StickyTable from '../../components/StickyTable'
import UsersEducationLevels from '../../util/UsersEducationLevels'
import EducationLevels from '../../util/EducationLevels'

const _hasMultipleLearningGoals = test => {
  const types = {}

  if (test.hasOwnProperty('learningGoals') && typeof test.learningGoals === 'object' && test.learningGoals !== null && !Array.isArray(test.learningGoals)) {
    Object.keys(test.learningGoals).forEach(key => {
      types[test.learningGoals[key].type] = true
    })
  }

  return Object.keys(types).length > 1
}

export default {
  components: {
    TestResult,
    StickyTable
  },
  props: {
    users: Array,
    test: Object,
    educationLevelData: Object,
    groupEducationLevels: Object,
    streamCode: String,
    editionUrl: String,
    groupId: String
  },
  data() {
    const educationLevels = EducationLevels.fromData(this.educationLevelData)

    return {
      showEducationLevelColumn: educationLevels.enabled && _hasMultipleLearningGoals(this.test),
      educationLevels: educationLevels,
      usersEducationLevels: UsersEducationLevels.fromData(this.groupEducationLevels)
    }
  },
  methods: {
    getUserEducationLevel(userReference) {
      return this.usersEducationLevels.getUserEducationLevel(userReference, this.test.contentPath, false)
    },
    getUserResults(userReference) {
      if (this.test.userResults.hasOwnProperty(userReference)) {
        return this.test.userResults[userReference]
      }
      return {}
    }
  }
}
</script>
