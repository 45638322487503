'use strict'

import ScoreCategories from '../../util/ScoreCategories'
import CompactRouteAdvice from '../../util/CompactRouteAdvice'
import AbsoluteLinkService from '../../AbsoluteLinkService'
import ContentPathResolver from '../../ContentPathResolver'

const _mapBlocks = blocks => {
  return blocks.reduce((carry, block, index) => {
    carry[block.contentPath] = block
    return carry
  }, {})
}

const _blockHasResults = (block, results) => {
  for (const test of block.tests) {
    if (Object.keys(results[test.contentPath]).length > 0) {
      return true
    }
  }
  return false
}

const _mapTests = (tests, blocks, results) => {
  const blockObjects = Object.values(tests.reduce((carry, test, index) => {
    const blockId = test.contentPath.split('/')[0]
    if (!carry.hasOwnProperty(blockId)) {
      let block = blocks[blockId]
      block.tests = []
      carry[blockId] = block
    }
    carry[blockId].tests.push(test)
    return carry
  }, {}))

  for (const block of blockObjects) {
    block.hasResults = _blockHasResults(block, results)
    block.tests = block.tests.sort((testA, testB) => {
      return testA.type.localeCompare(testB.type)
    })
  }

  return blockObjects.sort((blockA, blockB) => {
    return blockA.position > blockB.position ? -1 : 1
  })
}

const _mapTestResults = (tests, folioTests) => {
  let results = tests.reduce((carry, test, index) => {
    carry[test.contentPath] = {}
    Object.values(test.userResults).forEach(result => {
      result.type = 'digital'
      carry[test.contentPath][result.student.userReference] = result
    })
    return carry
  }, {})

  folioTests.forEach(folioTest => {
    Object.values(folioTest.userResults).forEach(folioResult => {
      folioResult.type = 'folio'
      const digitalTestResult = results[folioTest.contentPath][folioResult.student.userReference]

      // If there is no digital result or the folio score is higher than the digital score, we use the folioResult
      // And we store the 'lesser' result as 'otherResult'
      if (undefined === digitalTestResult) {
        results[folioTest.contentPath][folioResult.student.userReference] = folioResult
      } else if (folioResult.averageScore.score > digitalTestResult.averageScore.score) {
        results[folioTest.contentPath][folioResult.student.userReference] = folioResult
        results[folioTest.contentPath][folioResult.student.userReference].otherResult = digitalTestResult
      } else {
        results[folioTest.contentPath][folioResult.student.userReference].otherResult = folioResult
      }
    })
  })

  return results
}

const _mapHasTestResults = (tests, folioTests) => {
  let hasResults = tests.reduce((carry, test, index) => {
    carry[test.contentPath] = []
    Object.keys(test.userResultCount).forEach(userReference => {
        carry[test.contentPath].push(userReference)
    })
    return carry
  }, {})

  folioTests.forEach(folioTest => {
    Object.keys(folioTest.userResultCount).forEach(userReference => {
      hasResults[folioTest.contentPath].push(userReference)
    })
  })

  return hasResults
}

const _mapTestsData = blocks => {
  let contentPaths = []

  blocks.forEach (block => {
    if (block.hasResults) {
      block.tests.forEach((test, index) => {
        contentPaths.push({
          hasDivider: 0 === index,
          contentPath: test.contentPath
        })
      })
    }
  })

  return contentPaths
}

const _mapUserAverageScores = (users, blocks, results, scoreCategories) => {
  let scores = []

  users.forEach(user => {
    scores[user.userReference] = _getLastTwoAverageScore(user.userReference, blocks, results, scoreCategories)
  })

  return scores
}

const _getLastTwoAverageScore = (userReference, blocks, results, scoreCategories) => {
  const scores = _getScoresForLastTwoAverage(userReference, blocks, results)

  if (scores.length > 0) {
    const averageScore = Math.round(scores.reduce((carry, score) => carry + score, 0) / scores.length)

    return {
      isEmpty: false,
      score: averageScore,
      scoreCategoryName: scoreCategories.getScoreCategory(averageScore).name
    }
  }

  return {
    isEmpty: true,
    score: 0,
    scoreCategoryName: 'none'
  }
}

const _getScoresForLastTwoAverage = (userReference, blocks, results) => {
  let scores = []

  const index = _firstBlockWithScoreIndex(userReference, blocks, results)

  if (-1 === index) {
    return scores
  }

  scores.push(_getBlockTestsScore(blocks[index], userReference, results))
  if (index < blocks.length - 1) {
    scores.push(_getBlockTestsScore(blocks[index + 1], userReference, results))
  }

  return scores.filter(score => null !== score)
}

const _getBlockTestsScore = (block, userReference, results) => {
  if (block.tests.length > 1) {
    const controlExamWithResult = block.tests.find(test => 'control-exam' === test.type && undefined !== results[test.contentPath][userReference])
    if (undefined !== controlExamWithResult) {
      return _getBlockAverageTestsScore(block, userReference, results, controlExamWithResult.type)
    }
  }

  const firstTestWithResult = block.tests.find(test => undefined !== results[test.contentPath][userReference])
  if (undefined !== firstTestWithResult) {
    return _getBlockAverageTestsScore(block, userReference, results, firstTestWithResult.type)
  }

  return null
}

const _getBlockAverageTestsScore = (block, userReference, results, type) => {
  let scores = []

  for (const test of block.tests) {
    if ((null === type || type === test.type) && undefined !== results[test.contentPath][userReference]) {
      scores.push(results[test.contentPath][userReference].averageScore.score)
    }
  }

  return Math.round(scores.reduce((carry, score) => carry + score, 0) / scores.length)
}

const _firstBlockWithScoreIndex = (userReference, blocks, results) => {
  for (const index in blocks) {
    const testsWithUserResults = blocks[index].tests.filter(test => undefined !== results[test.contentPath][userReference])
    if (testsWithUserResults.length > 0) {
      return Number(index)
    }
  }

  return -1
}

export default class AllTestResultsStore {
  constructor(users, tests, folioTests, blocks, scoreCategoriesData, translator, editionUrl, streamCode, groupId) {
    this.isLoading = false
    this.results = _mapTestResults(tests, folioTests)
    this.hasTestResults = _mapHasTestResults(tests, folioTests)
    this.blocks = _mapTests(tests, _mapBlocks(blocks), this.results)
    this.testsData = _mapTestsData(this.blocks)
    this.scoreCategories = ScoreCategories.fromData(scoreCategoriesData)
    this.userAverageScores = _mapUserAverageScores(users, this.blocks, this.results, this.scoreCategories)
    this.translator = translator
    this.absoluteLinkService = new AbsoluteLinkService(editionUrl)
    this.streamCode = streamCode
    this.groupId = groupId
  }

  getLastTwoAverageScore(userReference) {
    return this.userAverageScores[userReference]
  }

  userHasTestResults(userReference, contentPath) {
    return this.hasTestResults.hasOwnProperty(contentPath) && this.hasTestResults[contentPath].indexOf(userReference) >= 0
  }

  getEmptyScoreValue(userReference, contentPath) {
    if (this.userHasTestResults(userReference, contentPath)) {
      return this.translator('generic.term.not_applicable_short')
    }
    return '-'
  }

  getScore(userReference, contentPath) {
    const result = this.results[contentPath][userReference]

    if (undefined === result) {
      return {
        isEmpty: true,
        score: 0,
        scoreCategoryName: 'none'
      }
    }

    return result.averageScore
  }

  getLink(userReference, contentPath, secondary) {
    const result = secondary ? this.results[contentPath][userReference]['otherResult'] : this.results[contentPath][userReference]

    if (undefined === result || 'folio' === result.type) {
      return ''
    }

    const contentPathResolver = new ContentPathResolver(contentPath)

    return this.absoluteLinkService.editionLink(
      'edition_result_group_paragraph',
      {
        streamCode: this.streamCode,
        contentPath: contentPathResolver.paragraphPath(),
        userReference: userReference,
        itemId: contentPathResolver.itemId(),
        groupId: this.groupId
      }
    )
  }

  getOtherScore(userReference, contentPath) {
    const result = this.results[contentPath][userReference]

    if (undefined !== result && result.otherResult) {
      return result.otherResult.averageScore
    }

    return null
  }

  hasMultipleTestsPerBlock() {
    for (const block of this.blocks) {
      if (block.tests.length > 1) {
        return true
      }
    }

    return false
  }

  getBlocksWithResults() {
    return this.blocks.filter(block => block.hasResults)
  }

  getBlockHeaders(forPriorKnowledge) {
    return this.blocks
      .filter(block => block.hasResults)
      .map((block, index) => {
      return {
        hasDivider: !forPriorKnowledge || index > 0,
        span: block.tests.length + (true === forPriorKnowledge ? 1 : 0),
        classes: ['whitespace-nowrap'],
        label: block.aggregatedTitle
      }
    })
  }

  getTestHeaders(forPriorKnowledge) {
    let headers = []

    this.blocks.filter(block => block.hasResults).forEach ((block, blockIndex) => {
      if (true === forPriorKnowledge) {
        headers.push({
          hasDivider: blockIndex > 0,
          span: 1,
          classes: [],
          label: this.translator('generic.term.advice')
        })
      }
      if (true === forPriorKnowledge || block.tests.length > 1) {
        block.tests.forEach((test, index) => {
          const label = this.translator('cockpit.unit.title')
          const regex = new RegExp(`^${label} \\d+ -`, 'g');
          const title = test.title.replace(regex, '');
          headers.push({
            hasDivider: 0 === index && !forPriorKnowledge,
            span: 1,
            classes: [],
            label: title
          })
        })
      } else {
        headers.push({
          hasDivider: true,
          span: 1,
          classes: ['whitespace-nowrap'],
          label: block.aggregatedTitle
        })
      }
    })

    return headers
  }

  hasResults() {
    for (const block of this.blocks) {
      if (block.hasResults) {
        return true
      }
    }

    return false
  }

  getCompactRouteAdvice(userReference, block) {
    const scores = []

    for (const test of block.tests) {
      if (undefined !== this.results[test.contentPath][userReference]) {
        scores.push(this.results[test.contentPath][userReference].averageScore.score)
      }
    }

    return CompactRouteAdvice.forScores(scores, this.translator)
  }
}
