import ScoreCategory from "./ScoreCategory";

export default class ScoreCategories {
  /**
   * @param {ScoreCategory[]} scoreCategories
   */
  constructor(scoreCategories) {
    this.scoreCategories = scoreCategories
  }

  getScoreCategory(score) {
    return this.scoreCategories.find(category => score >= category.from && score < category.to)
  }

  static fromData(data) {
    return new ScoreCategories(
      data.map(categoryData => ScoreCategory.fromData(categoryData))
    )
  }
}
