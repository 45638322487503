'use strict'

import { post, routePost } from '../util/request'
import Routing from '../util/routing'

function mapDataToRequestBody (testData, token) {
  return Object.assign({ _token: token }, testData)
}

export default class FolioTestService {
  /**
   * @param streamCode {string}
   * @param token {string}
   */
  constructor (streamCode, token) {
    this.streamCode = streamCode
    this.token = token
  }

  getViewUwlrUrl(testId) {
    return Routing.generate('cockpit_test_admin_view_folio_results_uwlr', {
      streamCode: this.streamCode,
      testId: testId
    })
  }

  async saveTestResults (groupId, testId, testResults) {
    const url = Routing.generate('cockpit_folio_test_save_result', {
      streamCode: this.streamCode,
      testId: testId
    })
    return await post(url, mapDataToRequestBody({ testResults }, this.token))
  }

  async deleteTestResults (groupId, testId, userReference) {
    const url = Routing.generate('cockpit_folio_test_delete_result', {
      streamCode: this.streamCode,
      testId: testId
    })

    return await post(url, mapDataToRequestBody({ userReference: userReference }, this.token))
  }

  async resendFolioResults (testId, userReferences, submittedAt) {
    return await routePost('cockpit_test_admin_resend_folio_results', {
      streamCode: this.streamCode,
      testId: testId
    }, { _token: this.token, userReferences: userReferences, submittedAt: submittedAt })
  }
}
