<template>
  <div :id="identifier">
    <ul class="tabs" :class="[tabsAdditionalClass, { 'tabs--bordered' : bordered, 'tabs--disabled' : disabled }]">
      <li v-for="(tab, index) in tabs" class="tabs__item" :data-test="'learn-lines-navigation-list-level-2-' + firstLevelIndex + '-' + index">
        <a @click="selectTab(tab)"
           class="tabs__link"
           :class="tabClasses(tab)"
        >
          <span :class="{'tabs__truncated-content': truncate}">{{ tab.name }}</span>
          <span v-if="'' !== tab.icon" class="tabs__link-icon"><i :class="'icon-' + tab.icon"></i></span>
          <slot name="tab-item-inner" :subdomain="tab"></slot>
        </a>
      </li>
    </ul>
    <div class="tab-pages" :class="[pageWrapperClass, { 'tab-pages--animated': animated }]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: []
    }
  },

  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    firstLevelIndex: Number,
    identifier: String,
    pageWrapperClass: String,
    tabsAdditionalClass: String,
    selector: String,
    truncate: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.tabs = this.getTabs()
  },

  methods: {
    getTabs() {
      return this.$children.filter(child => 'Tab' === child.$options.componentName)
    },
    selectTab(selectedTab) {
      for (let tab of this.tabs) {
        tab.isSelected = (selectedTab.uid === tab.uid)
      }
    },

    tabClasses(tab) {
      return {
        'tabs__link--active': tab.isSelected,
        'tabs__link--with-icon': '' !== tab.icon
      }
    }
  }
}
</script>
