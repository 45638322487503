<template>
  <div :class="{'has-loading-bar': isLoading}">
    <warning v-if="error" :message="error"/>
    <success v-if="successMessage" :message="successMessage"/>
    <StickyTable :sticky-heads="1" :sticky-columns="stickyColumns" class="table table--full-width cockpit-results--hover">
      <thead>
        <tr class="cockpit-results--heading">
          <th v-if="streamHasAssignmentsExtra && hasAssignmentsExtra"><input type="checkbox" :checked="myContentAllChecked" @change="checkAllMyContent" :disabled="myContentAllCheckboxDisabled"></th>
          <th></th>
          <th v-if="streamHasAssignmentsExtra" class="whitespace-nowrap">{{ $t('cockpit.my_content.education_level') }}</th>
          <th v-for="lesson in lessons" class="text-align-center whitespace-nowrap">{{ $t('cockpit.lesson.title') }} {{ lesson.number }} {{ lesson.title }}</th>
          <th v-if="streamHasAssignmentsExtra" class="text-align-center whitespace-nowrap">{{ $t('cockpit.my_content.total_my_content') }}</th>
        </tr>
      </thead>
      <tbody>
        <learning-goal-result
          v-for="result in results"
          :key="result.student.userReference"
          :result="result"
          :lessons="lessons"
          :learning-goals="learningGoals"
          :learning-goal-path="learningGoalWithLessons.learningGoalPath"
          :show-education-level-marker="showEducationLevelMarker"
          :has-assignments-extra="hasAssignmentsExtra"
          :stream-has-assignments-extra="streamHasAssignmentsExtra"
          @education-level-selected="onEducationLevelSelected(result, $event)"
          @education-level-deselected="onEducationLevelDeselected(result)"
          @education-level-unchecked="onEducationLevelUnchecked(result)"
          @education-level-checked="onEducationLevelChecked(result)"
          :assign-my-content-checked="assignMyContent[result.student.userReference] ? assignMyContent[result.student.userReference].checked : false"
          :assign-item-reference="assignMyContent[result.student.userReference] ? assignMyContent[result.student.userReference].itemReference : null"
          :number-of-open-my-content="myContentGroupedByUser[result.student.userReference] ? myContentGroupedByUser[result.student.userReference].numberOfOpenMyContent : 0"
          :assigned-content="myContentGroupedByUser[result.student.userReference] ? myContentGroupedByUser[result.student.userReference].contentReferences : {}"
          :stream-code="streamCode"
          :group-id="groupId"
          :edition-url="editionUrl"
        ></learning-goal-result>
      </tbody>
    </StickyTable>

    <button v-if="streamHasAssignmentsExtra && hasAssignmentsExtra" class="button button--primary button--cockpit" :disabled="numberOfStudents === 0 || isLoading" @click="submitassignMyContent">
      {{ $transChoice('cockpit.my_content.assign_to_students', this.numberOfStudents, {numberOfStudents: this.numberOfStudents}) }}
    </button>
  </div>
</template>

<script>

import Score from '../../components/Score'
import StickyTable from '../../components/StickyTable'
import LearningGoalResult from './LearningGoalResult'
import MyContentService from './my-content/MyContentService'
import Warning from '../../components/notification/Warning'
import Success from '../../components/notification/Success'
import { ACTION_EVENT, EventBus } from "../../common/EventBus";
import { ACTION_ASSIGN_MY_CONTENT } from "../../common/ThasActionEventActionType";

export default {
  components: { LearningGoalResult, Score, Warning, Success, StickyTable },

  props: {
    learningGoalWithLessons: Object,
    results: Array,
    paragraphs: Array,
    showEducationLevelMarker: Boolean,
    learningGoals: Object,
    myContent: Array,
    myContentToken: String,
    streamCode: String,
    streamHasAssignmentsExtra: Boolean,
    editionUrl: String,
    groupId: String
  },
  myContentService: null,
  data() {
    const myContentGroupedByUser = {}
    for (const content of this.myContent) {
      if (myContentGroupedByUser[content.userReference] === undefined) {
        myContentGroupedByUser[content.userReference] = {
          numberOfOpenMyContent: 0,
          contentReferences: {}
        }
      }
      myContentGroupedByUser[content.userReference].contentReferences[content.contentReference] = content
      if (content.state === 'open') {
        myContentGroupedByUser[content.userReference].numberOfOpenMyContent++
      }
    }

    return {
      lessons: this.paragraphs.filter((paragraph) => {
        return this.learningGoalWithLessons.lessonContentPaths.indexOf(paragraph.contentPath) !== -1
      }).reverse(),

      assignMyContent: {},

      myContentGroupedByUser: myContentGroupedByUser,

      isLoading: false,
      error: null,
      successMessage: null
    }
  },
  mounted () {
    this.$options.myContentService = new MyContentService(
      this.streamCode,
      this.myContentToken,
      this.learningGoalWithLessons.learningGoalPath,
      this.learningGoals.viewModels.map(viewModel => viewModel.uniqueContentReference)
    )
  },
  computed: {
    numberOfStudents() {
      let number = 0
      for (const key in this.assignMyContent) {
        if (this.assignMyContent.hasOwnProperty(key) && this.assignMyContent[key].checked) {
          number++
        }
      }
      return number
    },
    hasAssignmentsExtra() {
      return null !== this.learningGoals && this.learningGoals.viewModels.length > 0
    },
    myContentAllChecked() {
      let checked = false
      for (const key in this.assignMyContent) {
        if (this.assignMyContent.hasOwnProperty(key)) {
          if (this.assignMyContent[key].checked) {
            checked = true
          } else {
            return false
          }
        }
      }
      return checked
    },
    myContentAllCheckboxDisabled() {
      for (const key in this.assignMyContent) {
        if (this.assignMyContent.hasOwnProperty(key)) {
          return false
        }
      }
      return true
    },
    stickyColumns() {
      const hasAssignmentsExtra = this.streamHasAssignmentsExtra && this.hasAssignmentsExtra;
      return hasAssignmentsExtra ? 2 : 1;
    }
  },

  methods: {
    onEducationLevelSelected(result, educationLevel) {
      this.$set(this.assignMyContent, result.student.userReference, {
        itemReference: educationLevel.item.uniqueContentReference,
        checked: !educationLevel.state.isOpen(),
        educationLevel: educationLevel.educationLevel
      })
    },
    onEducationLevelDeselected(result) {
      this.$delete(this.assignMyContent, result.student.userReference)
    },
    onEducationLevelChecked(result) {
      const studentContent = this.assignMyContent[result.student.userReference]
      if (studentContent) {
        studentContent.checked = true
      }
    },
    onEducationLevelUnchecked(result) {
      const studentContent = this.assignMyContent[result.student.userReference]
      if (studentContent) {
        studentContent.checked = false
      }
    },
    checkAllMyContent() {
      let check = !this.myContentAllChecked
      for (const key in this.assignMyContent) {
        if (this.assignMyContent.hasOwnProperty(key)) {
          this.assignMyContent[key].checked = check
        }
      }
    },
    async submitassignMyContent() {
      this.isLoading = true
      this.error = null
      this.successMessage = null

      let payload = []
      for (const userReference in this.assignMyContent) {
        if (this.assignMyContent.hasOwnProperty(userReference) && this.assignMyContent[userReference].checked) {
          payload.push({
            userReference: userReference,
            contentReference: this.assignMyContent[userReference].itemReference,
            educationLevel: this.assignMyContent[userReference].educationLevel
          })
        }
      }

      EventBus.$emit(ACTION_EVENT, window.location.href, ACTION_ASSIGN_MY_CONTENT, {
        'numberOfStudents': this.numberOfStudents,
        'learningGoal': this.learningGoalWithLessons.learningGoal
      })

      try {
        await this.$options.myContentService.assignMyContentCollection(payload)
        document.location.reload()
      } catch (error) {
        if (error.response !== undefined && error.response.status === 400) {
          this.error = error.response.data.errors.join(', ')
        } else {
          console.error(error)
        }
        this.isLoading = false
      }
    }
  }
}
</script>
