<template>
  <ul class="lesson-navigation__week-component">
    <li class="lesson-navigation__item lesson-navigation__item--0 lesson-navigation__item--week-overview"
        :class="[ { 'lesson-navigation__item--active' : week.active } ]">
      <a v-if="selectWeek" :href="weekLink" class="lesson-navigation__item-link lesson-navigation__item-link-week"
         :title="weekTitle">{{ weekTitle }}</a>
      <div v-else class="lesson-navigation__item-no-link lesson-navigation__item-no-link-week" :title="weekTitle">{{ weekTitle }}</div>
    </li>

    <lesson-item
      v-for="(lesson, index) in week.lessons"
      :lesson="lesson"
      :previous-lesson="week.lessons[index - 1]"
      :next-lesson="week.lessons[index + 1]"
      :key="index"
      :route-service="routeService"
      :block-number="blockNumber"
      type="all-weeks"
    ></lesson-item>

  </ul>
</template>

<script>
  import LessonItem from './LessonItem'
  import ContentPath from '../../util/ContentPath'
  import CockpitRouteService from '../../CockpitRouteService'

  export default {
    components: {
      LessonItem
    },
    props: {
      blockNumber: String,
      week: Object,
      selectWeek: Boolean,
      routeService: CockpitRouteService
    },
    computed: {
      weekTitle() {
        return this.week.number + ' ' + (this.week.title !== '' ? ' ' + this.week.title : '')
      },
    },
    data() {
      return {
        weekLink: this.routeService.weekLink(new ContentPath(this.week.contentPath))
      }
    }
  }
</script>
