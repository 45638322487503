<template>
  <div>
    <activity-card
      v-if="!(store.cleanup && !store.added)"
      :container-class="containerClass"
      :trigger-class="triggerClass"
      :store="store"
      :index="index"
      @click="onClick"
    >
      <slot name="card"></slot>
    </activity-card>

    <activity-modal
      ref="modal"
      v-if="opened"
      :title="title"
      :image="image"
      :meta="meta"
      :meta-leerjaar-label="metaLeerjaarLabel"
      :view-event-url="viewEventUrl"
      :store="store"
      :index="index"
      @close="close"
    >
      <slot name="content"></slot>
    </activity-modal>
  </div>
</template>

<script>
import ActivityCard from '../card/ActivityCard'
import ActivityModal from './ActivityModal'
import ActivityStore from '../ActivityStore'

import mixins from '../../util/mixins'

export default {
  mixins: [mixins],

  data () {
    const url = window.location.href.split('?')[0]

    return {
      viewEventUrl: `${url}?viewCard=${this.contentPath}`,
      opened: false,
      store: new ActivityStore(
        this.initiallyAdded,
        this.initiallyCompleted,
        this.cleanupInstantly,
        this.token,
        this.contentPath,
        this.groupId
      )
    }
  },

  watch: {
    'store.added': function(status) {
      if (!this.store.cleanup) {
        return
      }

      const categoryContainer = this.$el.closest('.lesson__category')
      const emptyClass = 'lesson__category--empty'

      if (status) {
        categoryContainer.classList.toggle(emptyClass, false)
      } else {
        const cards = categoryContainer.querySelectorAll('.lesson__topic')
        if (cards.length - 1 === 0) {
          categoryContainer.classList.toggle(emptyClass, true)
        }
      }
    }
  },

  components: {
    ActivityCard,
    ActivityModal
  },

  props: {
    index: String,
    contentPath: String,
    groupId: String,
    cleanupInstantly: Boolean,
    initiallyAdded: Boolean,
    initiallyCompleted: Boolean,
    token: String,
    title: String,
    image: String,
    containerClass: {
      type: String,
      default: ''
    },
    triggerClass: {
      type: String,
      default: ''
    },
    meta: Object,
    metaLeerjaarLabel: String,
    addedToMyActivitiesLabel: String,
    completedStatusLabelChecked: String,
    completedStatusLabelUnchecked: String
  },

  computed: {
    leerjaar () {
      if (this.meta === undefined) {
        return ''
      }
      return (Array.isArray(this.meta.leerjaar) && this.meta.leerjaar.length > 1) ? `${this.meta.leerjaar[0]} & ${this.meta.leerjaar[1]}` : this.meta.leerjaar[0]
    }
  },

  methods: {
    close () {
      this.opened = false
    },

    onClick () {
      this.opened = true
    }
  }
}
</script>
