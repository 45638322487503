<template>
  <div class="modal modal--card is-active" ref="modal">
    <div class="modal-background"></div>

    <article class="modal__inner" v-on-clickaway="close">
      <header class="modal__header"
              :class="[{ 'modal__header--no-bg' : !image }]"
              :style="image ? 'background-image: url(' + image + ');' : ''">
        <div class="modal__header-inner">
          <p class="modal__pretitle" v-if="meta !== undefined">
            <template v-if="meta.trefwoorden">{{ meta.trefwoorden[0] }}</template>
            <template v-if="meta.leerjaar"> • {{ metaLeerjaarLabel }} {{ leerjaar }}</template>
          </p>
          <h4 class="modal__title">{{ title }}</h4>
          <button
            class="modal__lesson-status-button button button--light flex flex-align-items--center mr-auto mt-6"
            :class="store.completed ? 'toggled' : ''"
            @click="checkOffLesson">
            <svg class="modal__lesson-status-icon mr-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.03224 12.2465C1.03224 12.2465 11.3458 7.37428 13.7505 4.96959C16.1551 2.5649 11.6199 1.61634 8.10189 3.52679C4.58392 5.43723 5.27085 9.2908 10.2384 9.2629C11.5615 9.25547 13.5512 9.90807 12.6451 10.8387C11.7537 11.7544 9.18509 11.9736 8.10184 12.709"
                stroke="#97BE0C" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span
              :data-test="'lesson-topic-modal-finish-' + index"
              class="modal__lesson-status-label"
              v-html="$t('cockpit.activities.card.modal.completed-status-label-' + (store.completed ? 'checked' : 'unchecked'))"
            ></span>
          </button>
        </div>

        <my-activity-toggle
          v-if="store.groupId && store.groupId > 0"
          :store="store"
          :index="index"
        ></my-activity-toggle>

        <a class="modal__close" @click="close()"></a>
      </header>
      <div :data-test="'lesson-topic-modal-content-' + index" class="modal__content" ref="content">
        <slot></slot>
      </div>
    </article>
  </div>
</template>

<script>
import mixins from '../../util/mixins'
import { mixin as clickaway } from 'vue-clickaway'
import { mixin as onescape } from '../../util/on-escape'
import { setupContentElements } from '../../util/setup-content-elements'
import { ACTION_EVENT, EventBus, MODAL_CLOSE_EVENT } from '../../common/EventBus'
import disableBodyScroll from '../../util/disable-body-scroll'
import { enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.esm'
import NavigateAndViewEventProducer from '../../thas/NavigateAndViewEventProducer'
import ActivityStore from '../ActivityStore'
import MyActivityToggle from '../MyActivityToggle'

import axios from "axios";
import { ACTION_ACTIVITY_CHECKED, ACTION_ACTIVITY_UNCHECKED } from "../../common/ThasActionEventActionType";

const COMPLETE_ROUTE = 'cockpit_activity_complete'
const INCOMPLETE_ROUTE = 'cockpit_activity_incomplete'

export default {
  mixins: [mixins, clickaway, onescape],

  components: {
    MyActivityToggle
  },

  props: {
    index: String,
    title: String,
    image: String,
    meta: Object,
    store: ActivityStore,
    metaLeerjaarLabel: String,
    viewEventUrl: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      leerjaar: this.getLeerjaar()
    }
  },

  mounted() {
    disableBodyScroll(this.$el)
    setupContentElements(this.$refs.content)
  },

  methods: {
    getLeerjaar () {
      if (this.meta === undefined) {
        return ''
      }
      return (Array.isArray(this.meta.leerjaar) && this.meta.leerjaar.length > 1) ? `${this.meta.leerjaar[0]} & ${this.meta.leerjaar[1]}` : this.meta.leerjaar[0]
    },
    stopNavigateAndViewEventProducer () {
      if (this.$options.navigateAndViewEventProducer instanceof NavigateAndViewEventProducer) {
        this.$options.navigateAndViewEventProducer.stop()
        this.$options.navigateAndViewEventProducer = null
      }
    },

    startNavigateAndViewEventProducer () {
      this.stopNavigateAndViewEventProducer()
      if (this.viewEventUrl !== '') {
        this.$options.navigateAndViewEventProducer = new NavigateAndViewEventProducer(this.viewEventUrl)
      }
    },

    close () {
      this.stopNavigateAndViewEventProducer()
      enableBodyScroll(this.$el)
      EventBus.$emit(MODAL_CLOSE_EVENT)

      this.$emit('close')
    },

    checkOffLesson () {
      this.store.completed = !this.store.completed
      EventBus.$emit(ACTION_EVENT, window.location.href, this.getActionType(), {
        'completed': this.store.completed,
        'cardContentPath': this.store.contentPath
      })

      axios({
        method: 'post',
        url: this.getUrl(this.store.completed, COMPLETE_ROUTE, INCOMPLETE_ROUTE, this.store),
        data: {
          completed: this.store.completed,
          contentPath: this.store.contentPath,
          _token: this.store.token
        }
      })
    },
    getActionType() {
      if (this.store.completed) {
        return ACTION_ACTIVITY_CHECKED
      }

      return ACTION_ACTIVITY_UNCHECKED;
    }
  }
}
</script>
