'use strict'

import ScoreCategories from '../../util/ScoreCategories'
import RhvAdvices from '../../util/RhvAdvices'
import Routing from '../../util/routing'
import {post} from '../../util/request'
import UsersEducationLevels from '../../util/UsersEducationLevels'
import EducationLevels from '../../util/EducationLevels'
import {EMPTY_SCORE} from '../../components/Score'
import AbsoluteLinkService from '../../AbsoluteLinkService'
import ContentPathResolver from "../../ContentPathResolver";
import {onServerPrefetch} from "vue";

const _baseLearningGoal = {
  contentPath: 'base',
  title: '',
  type: 'default'
}

const _learningGoalTypes = [
  {key: 'default', value: 'Dit kan ik nu', selected: false},
  {key: 'repetition', value: 'Dit kan ik nog', selected: false},
  {key: 'prior-knowledge', value: 'Dit kan ik al', selected: false}
];

const _getLearningGoalTypes = learningGoals => {
  const learningGoalValues = Object.values(learningGoals)

  const types = 0 === learningGoalValues.length ?
    _learningGoalTypes.filter(type => 'default' === type.key) :
    _learningGoalTypes.filter(type => learningGoalValues.filter(goal => type.key === goal.type).length > 0)

  types[0].selected = true
  return types;
};

const _addLearningGoal = (learningGoals, learningGoalPath, learningGoal, test) => {
  if (!learningGoals.hasOwnProperty(learningGoalPath)) {
    learningGoals[learningGoalPath] = Object.assign({testTypes: []}, learningGoal)
  }
  if (-1 === learningGoals[learningGoalPath].testTypes.indexOf(test.type)) {
    learningGoals[learningGoalPath].testTypes.push(test.type)
  }
  return learningGoals
}

const _mapLearningGoals = (tests, folioTests) => {
  let learningGoals = []

  tests.forEach(test => {
    Object.entries(test.learningGoals).forEach(([learningGoalPath, learningGoal]) => {
      learningGoals = _addLearningGoal(learningGoals, learningGoalPath, learningGoal, test)
    })
  })

  folioTests.forEach(test => {
    Object.entries(test.learningGoals).forEach(([learningGoalPath, learningGoal]) => {
      learningGoals = _addLearningGoal(learningGoals, learningGoalPath, learningGoal, test)
    })
  })

  if (0 === learningGoals.length) {
    // Add the _baseLearningGoal
    tests.forEach(test => {
      Object.values(test.userResults).forEach(result => {
        if (Object.keys(result.scores).filter(learningGoalPath => 'base' === learningGoalPath).length > 0) {
          learningGoals = _addLearningGoal(learningGoals, _baseLearningGoal.contentPath, _baseLearningGoal, test)
        }
      })
    })

    folioTests.forEach(test => {
      Object.values(test.userResults).forEach(result => {
        if (Object.keys(result.scores).filter(learningGoalPath => 'base' === learningGoalPath).length > 0) {
          learningGoals = _addLearningGoal(learningGoals, _baseLearningGoal.contentPath, _baseLearningGoal, test)
        }
      })
    })
  }

  return learningGoals
}

const _selectLearningGoalsOfType = (learningGoals, learningGoalType) => {
  return Object.values(learningGoals).filter(goal => learningGoalType === goal.type)
}

const _AddKeys = (object, keys) => {
  let target = object
  for (const key of keys) {
    if (!target.hasOwnProperty(key)) {
      target[key] = {}
    }
    target = target[key]
  }
  return object
}

const _getReferenceLevels = (tests, folioTests) => {
  let levels = []
  for (const test of tests) {
    const referenceLevels = test.referenceLevels.length > 0 ? test.referenceLevels : ['any']
    for (const level of referenceLevels) {
      if (levels.indexOf(level) < 0) {
        levels.push(level)
      }
    }
  }
  for (const folioTest of folioTests) {
    const referenceLevels = folioTest.referenceLevels.length > 0 ? folioTest.referenceLevels : ['any']
    for (const level of referenceLevels) {
      if (levels.indexOf(level) < 0) {
        levels.push(level)
      }
    }
  }

  return levels
}

const _addUserTestResult = (results, result, digitalOrFolio, test, userEducationLevels) => {
  const userReference = result.student.userReference
  const referenceLevels = test.referenceLevels.length > 0 ? test.referenceLevels : ['any']
  for (const referenceLevel of referenceLevels) {
    results = _AddKeys(results, [userReference, digitalOrFolio, test.type, referenceLevel, 'scores'])
    Object.entries(result.scores).forEach(([learningGoalPath, score]) => {
      results[userReference][digitalOrFolio][test.type][referenceLevel].scores[learningGoalPath] = score
    })
    results[userReference][digitalOrFolio][test.type][referenceLevel].level = userEducationLevels.getUserEducationLevel(userReference, test.contentPath, false)
    results[userReference][digitalOrFolio][test.type][referenceLevel].testContentPath = test.contentPath
  }
  return results
}

const _mapTestResults = (tests, folioTests, userEducationLevels) => {
  let results = tests.reduce((carry, test, index) => {
    Object.values(test.userResults).forEach(result => {
      carry = _addUserTestResult(carry, result, 'digital', test, userEducationLevels)
    })
    return carry
  }, {})

  results = folioTests.reduce((carry, test, index) => {
    Object.values(test.userResults).forEach(result => {
      carry = _addUserTestResult(carry, result, 'folio', test, userEducationLevels)
    })
    return carry
  }, results)

  return results
}

const _mapRhvAdvices = (rhvAdvices) => {
  return rhvAdvices.reduce((carry, rhvAdvice, index) => {
    carry = _AddKeys(carry, [rhvAdvice.userReference, rhvAdvice.learningGoalId, rhvAdvice.source])
    const referenceLevel = null === rhvAdvice.referenceLevel ? 'any' : rhvAdvice.referenceLevel
    carry[rhvAdvice.userReference][rhvAdvice.learningGoalId][rhvAdvice.source][referenceLevel] = rhvAdvice
    return carry
  }, {})
}

const _hasRhvPrintSheets = (learningGoals, sheets) => {
  for (const learningGoal of learningGoals) {
    const id = learningGoal.contentPath.split('/').slice(-1)[0]
    if (sheets.hasOwnProperty(id)) {
      return true
    }
  }

  return false
}

export default class LearningGoalTestResultsStore {
  constructor(contentReference, users, tests, folioTests, scoreCategoriesData, rhvEnabled, rhvAdvices, rhvPrintSheets, educationLevelData, groupEducationLevels, groupId, streamCode, editionUrl, translator) {
    this.isLoading = []
    this.users = users
    this.contentReference = contentReference
    this.tests = tests
    this.streamEducationLevels = EducationLevels.fromData(educationLevelData)
    this.educationLevels = UsersEducationLevels.fromData(groupEducationLevels)
    this.results = _mapTestResults(tests, folioTests, this.educationLevels)
    this.hasResults = Object.keys(this.results).length > 0
    this.allLearningGoals = _mapLearningGoals(tests, folioTests)
    this.learningGoalTypes = _getLearningGoalTypes(this.allLearningGoals)
    this.scoreCategories = ScoreCategories.fromData(scoreCategoriesData)
    this.rhvAdvices = RhvAdvices.create(this.scoreCategories, translator)
    this.rhvEnabled = rhvEnabled
    this.rhvPrintSheets = rhvPrintSheets

    /* These properties will be set by calling this.setLearningGoalType */
    this.learningGoalType = 'default'
    this.learningGoals = []
    this.showRhv = true
    this.hasRhvPrintSheets = true
    this.setLearningGoalType(this.learningGoalTypes.find(type => type.selected).key)

    this.userRhvAdvices = _mapRhvAdvices(rhvAdvices)
    this.groupId = groupId
    this.streamCode = streamCode
    this.absoluteLinkService = new AbsoluteLinkService(editionUrl)
    this.translator = translator
    this.showEducationLevelColumn = this.streamEducationLevels.enabled && this.learningGoalTypes.length > 1
    this.referenceLevels = _getReferenceLevels(tests, folioTests)
    this.hasReferenceLevels = 'any' !== this.referenceLevels[0]
    this.stickyColumns = 2 + (this.hasReferenceLevels ? 1 : 0) + (this.showEducationLevelColumn ? 1 : 0)
  }

  setLearningGoalType(type) {
    this.learningGoalType = type
    this.showRhv = this.rhvEnabled && 'prior-knowledge' !== this.learningGoalType
    this.learningGoals = _selectLearningGoalsOfType(this.allLearningGoals, this.learningGoalType)
    this.hasRhvPrintSheets = _hasRhvPrintSheets(this.learningGoals, this.rhvPrintSheets)
  }

  hasScoreForLearningGoalIdAndTestType(userReference, learningGoalId, digitalOrFolio, testType, referenceLevel) {
    if (!this.hasScoresForTestType(userReference, digitalOrFolio, testType, referenceLevel)) {
      return false
    }
    return this.results[userReference][digitalOrFolio][testType][referenceLevel].scores.hasOwnProperty(learningGoalId)
  }

  hasScoresForTestType(userReference, digitalOrFolio, testType, referenceLevel) {
    if (!this.hasScoresFor(userReference, digitalOrFolio)) {
      return false
    }
    if (!this.results[userReference][digitalOrFolio].hasOwnProperty(testType)) {
      return false
    }
    return this.results[userReference][digitalOrFolio][testType].hasOwnProperty(referenceLevel)
  }

  hasScoresFor(userReference, digitalOrFolio) {
    if ('' === digitalOrFolio) {
      return false
    }
    if (!this.results.hasOwnProperty(userReference)) {
      return false
    }
    return this.results[userReference].hasOwnProperty(digitalOrFolio)
  }

  userHasResults(userReference) {
    return this.results.hasOwnProperty(userReference)
  }

  getAdviceEntityForLearningGoal(userReference, learningGoalPath, digitalOrFolio, referenceLevel) {
    if ('' === digitalOrFolio) {
      return null
    }
    if (!this.userRhvAdvices.hasOwnProperty(userReference)) {
      return null
    }
    if (!this.userRhvAdvices[userReference].hasOwnProperty(learningGoalPath)) {
      return null
    }
    if (!this.userRhvAdvices[userReference][learningGoalPath].hasOwnProperty(digitalOrFolio)) {
      return null
    }
    if (!this.userRhvAdvices[userReference][learningGoalPath][digitalOrFolio].hasOwnProperty(referenceLevel)) {
      return null
    }
    return this.userRhvAdvices[userReference][learningGoalPath][digitalOrFolio][referenceLevel]
  }

  getResultTypes(userReference) {
    if (!this.userHasResults(userReference)) {
      return []
    }

    let types = {}
    Object.entries(this.results[userReference]).forEach(([digitalOrFolio, data]) => {
      Object.entries(data).forEach(([type, dataForType]) => {
        Object.keys(dataForType).forEach(referenceLevel => {
          types[digitalOrFolio + '|' + referenceLevel] = {digitalOrFolio: digitalOrFolio, referenceLevel: referenceLevel}
        })
      })
    })

    return Object.values(types)
  }

  getRhvAdvice(userReference, learningGoal, digitalOrFolio, referenceLevel) {
    const storedEntity = this.getAdviceEntityForLearningGoal(userReference, learningGoal.contentPath, digitalOrFolio, referenceLevel)

    if (null !== storedEntity) {
      return this.rhvAdvices.getAdvice(storedEntity.rhvAdvice)
    }

    return this.getScoreRhvAdvice(userReference, learningGoal.contentPath, digitalOrFolio, referenceLevel)
  }

  getScoreRhvAdvice(userReference, learningGoalId, digitalOrFolio, referenceLevel) {
    const score = this.getScoreForLearningGoalIdAndTestType(userReference, learningGoalId, digitalOrFolio, 'exam', referenceLevel)
    return score.isEmpty ? null : this.rhvAdvices.getAdviceForScoreAndLearningGoalType(score.score, this.learningGoalType)
  }

  getRhvAdviceOptions(userReference, learningGoal, digitalOrFolio, referenceLevel) {
    const storedEntity = this.getAdviceEntityForLearningGoal(userReference, learningGoal.contentPath, digitalOrFolio, referenceLevel)
    const stored = null === storedEntity ? null : this.rhvAdvices.getAdvice(storedEntity.rhvAdvice)

    const score = this.getScoreForLearningGoalIdAndTestType(userReference, learningGoal.contentPath, digitalOrFolio, 'exam', referenceLevel)
    const adviced = score.isEmpty ? null : this.rhvAdvices.getAdviceForScoreAndLearningGoalType(score.score, this.learningGoalType)
    const selected = null !== stored ? stored : adviced

    const adviceObjects = this.rhvAdvices.getAdvicesForLearningGoalType(this.learningGoalType)

    let advices = adviceObjects.map(advice => {
      return {
        key: advice.identifier,
        value: advice.identifier,
        current: false,
        selected: (null !== selected && advice.identifier === selected.identifier),
        adviced: (null !== adviced && advice.identifier === adviced.identifier),
        single: 1 === adviceObjects.length
      }
    })

    return advices
  }

  getLevelsForTestType(userReference, digitalOrFolio, referenceLevel) {
    if (!this.hasScoresFor(userReference, digitalOrFolio)) {
      return [];
    }
    const scores = this.results[userReference][digitalOrFolio]
    const levels = {}

    Object.keys(scores).forEach(type => {
      if (scores[type].hasOwnProperty(referenceLevel)) {
        if (null !== scores[type][referenceLevel].level) {
          levels[scores[type][referenceLevel].level.identifier] = true
        }
      }
    })

    return Object.keys(levels)
  }

  getPathsWithScores(userReference, digitalOrFolio, referenceLevel, forType) {
    if (!this.hasScoresFor(userReference, digitalOrFolio)) {
      return [];
    }

    const scores = this.results[userReference][digitalOrFolio]
    const paths = {}

    Object.keys(scores).forEach(type => {
      if ((null === forType || type === forType) && scores[type].hasOwnProperty(referenceLevel)) {
        Object.keys(scores[type][referenceLevel].scores).forEach(path => {
          paths[path] = true
        })
      }
    })

    return Object.keys(paths)
  }

  getScoresForLearningGoalAndTestType(userReference, learningGoal, digitalOrFolio, referenceLevel) {
    const scores = {}
    if (learningGoal.testTypes.indexOf('exam') >= 0) {
      scores['exam'] = this.getScoreForLearningGoalIdAndTestType(userReference, learningGoal.contentPath, digitalOrFolio, 'exam', referenceLevel)
    }
    if (learningGoal.testTypes.indexOf('control-exam') >= 0) {
      scores['control-exam'] = this.getScoreForLearningGoalIdAndTestType(userReference, learningGoal.contentPath, digitalOrFolio, 'control-exam', referenceLevel)
    }

    return scores
  }

  getScoreForLearningGoalIdAndTestType(userReference, learningGoalId, digitalOrFolio, testType, referenceLevel) {
    if (this.hasScoreForLearningGoalIdAndTestType(userReference, learningGoalId, digitalOrFolio, testType, referenceLevel)) {
      return this.results[userReference][digitalOrFolio][testType][referenceLevel].scores[learningGoalId]
    }

    return EMPTY_SCORE
  }

  getEmptyValue(score, userReference, learningGoal, digitalOrFolio, testType, referenceLevel) {
    if (score.isEmpty && this.hasScoresForTestType(userReference, digitalOrFolio, testType, referenceLevel)) {
      return 'prior-knowledge' === learningGoal.type ? this.translator('generic.term.not_applicable_short') : '-'
    }

    return '-'
  }

  getScoreLink(score, userReference, learningGoal, digitalOrFolio, testType, referenceLevel) {
    if (score.isEmpty || 'folio' === digitalOrFolio || !this.hasScoresForTestType(userReference, digitalOrFolio, testType, referenceLevel)) {
      return ''
    }
    const result = this.results[userReference][digitalOrFolio][testType][referenceLevel]

    const contentPathResolver = new ContentPathResolver(result.testContentPath)

    return this.absoluteLinkService.editionLink(
      'edition_result_group_paragraph',
      {
        streamCode: this.streamCode,
        contentPath: contentPathResolver.paragraphPath(),
        userReference: userReference,
        itemId: contentPathResolver.itemId(),
        groupId: this.groupId
      }
    )
  }

  getGroupHeaders() {
    return this.learningGoals.map(learningGoal => {
      return {
        span: learningGoal.testTypes.length + (this.showRhv ? 1 : 0),
        label: learningGoal.title
      }
    })
  }

  getHeaders() {
    let headers = []

    this.learningGoals.forEach((learningGoal, index) => {
      if (index > 0) {
        headers.push({
          class: 'horizontal-divider--left',
          label: ''
        });
        headers.push({
          class: 'horizontal-divider--right',
          label: ''
        });
      }
      if (this.showRhv) {
        headers.push({
          class: 'text-align-left',
          label: this.translator('cockpit.progress.results.rhv')
        })
      }
      if (learningGoal.testTypes.indexOf('exam') >= 0 && learningGoal.testTypes.indexOf('control-exam') >= 0) {
        headers.push({
          class: '',
          label: this.translator('cockpit.progress.results.exam')
        });
        headers.push({
            class: '',
            label: this.translator('cockpit.progress.results.control_exam')
        })
      } else if (learningGoal.testTypes.indexOf('control-exam') >= 0) {
        headers.push({
          class: '',
          label: this.translator('cockpit.progress.results.control_exam')
        })
      } else {
        headers.push({
          class: '',
          label: this.translator('generic.term.score')
        })
      }
    })

    return headers
  }

  isLoadingFor(userReference, learningGoalId, source) {
    return this.isLoading.indexOf(userReference + learningGoalId + source) >= 0
  }

  getPrintRhvSheetsUrl() {
    return Routing.generate('cockpit_rhv_print_with_queue', { streamCode: msp.streamCode })
  }

  async updateAdvice(context, advice, options) {
    const loadingId = context.userReference + context.learningGoalId + context.source + context.referenceLevel
    this.isLoading.push(loadingId)

    const url = Routing.generate('cockpit_rhv_save', {streamCode: msp.streamCode})

    const current = this.getAdviceEntityForLearningGoal(context.userReference, context.learningGoalId, context.source, context.referenceLevel)
    const adviceFromScore = this.getScoreRhvAdvice(context.userReference, context.learningGoalId, context.source, context.referenceLevel)

    const body = Object.assign({}, context)
    body.id = null === current ? null : current.id
    body.rhvAdvice = undefined === advice || null === advice ? null : advice.value
    body.scoreRhvAdvice = undefined === adviceFromScore || null === adviceFromScore ? null : adviceFromScore.identifier

    try {
      const response = await post(url, body)
      this.updateAdviceEntityForLearningGoal(context.userReference, context.learningGoalId, context.source, context.referenceLevel, body.rhvAdvice, response.data.rhvAdviceEntityId)
    } catch (e) {
      throw new Error(e.response.data)
    } finally {
    }

    const index = this.isLoading.indexOf(loadingId);
    if (index !== -1) {
      this.isLoading.splice(index, 1);
    }
  }

  updateAdviceEntityForLearningGoal(userReference, learningGoalPath, digitalOrFolio, referenceLevel, advice, id) {
    if ('' === digitalOrFolio) {
      return null
    }
    this.userRhvAdvices = _AddKeys(this.userRhvAdvices, [userReference, learningGoalPath, digitalOrFolio])

    if (null === id) {
      this.userRhvAdvices[userReference][learningGoalPath][digitalOrFolio][referenceLevel] = null
      return
    }

    if (null !== this.getAdviceEntityForLearningGoal(userReference, learningGoalPath, digitalOrFolio, referenceLevel)) {
      this.userRhvAdvices[userReference][learningGoalPath][digitalOrFolio][referenceLevel].id = id
      this.userRhvAdvices[userReference][learningGoalPath][digitalOrFolio][referenceLevel].rhvAdvice = null === advice ? '-' : advice
      return
    }

    this.userRhvAdvices[userReference][learningGoalPath][digitalOrFolio][referenceLevel] = {
      id: id,
      rhvAdvice: null === advice ? '-' : advice,
      source: digitalOrFolio,
      learningGoalId: learningGoalPath,
      userReference: userReference,
      contentReference: this.contentReference
    }
  }

  getRhvAdviceData() {
    let data = {
      users: {},
      rhvAdvices: {}
    }

    for (const user of this.users) {
      data = this._addUserRhvAdviceData(data, user)
    }

    return data
  }

  _addUserRhvAdviceData (data, user) {
    for (const resultType of this.getResultTypes(user.userReference)) {
      data = this._addUserResultTypeRhvAdviceData(data, user, resultType.digitalOrFolio, resultType.referenceLevel)
    }

    return data
  }

  _addUserResultTypeRhvAdviceData(data, user, resultType, referenceLevel) {
    const pathsWithScores = this.getPathsWithScores(user.userReference, resultType, referenceLevel, 'exam')
    for (const learningGoal of this.learningGoals) {
      if (pathsWithScores.indexOf(learningGoal.contentPath) >= 0) {
        const adviceObject = this.getRhvAdvice(user.userReference, learningGoal, resultType, referenceLevel)
        if (null !== adviceObject && !adviceObject.isEmpty()) {
          const advice = adviceObject.identifier
          data = this._addRhvAdviceDataAdvice(data, user.userReference, resultType, learningGoal, referenceLevel, advice)
          data = this._addRhvAdviceDataUser(data, user, learningGoal, referenceLevel, advice)
        }
      }
    }

    return data
  }

  _addRhvAdviceDataAdvice(data, userReference, resultType, goal, referenceLevel, advice) {
    const goalPath = goal.contentPath
    if (data.rhvAdvices[goalPath] === undefined) {
      data.rhvAdvices[goalPath] = {
        title: goal.title,
        advices: {}
      }
    }
    if (data.rhvAdvices[goalPath].advices[advice] === undefined) {
      data.rhvAdvices[goalPath].advices[advice] = []
    }
    const pathAdvice = data.rhvAdvices[goalPath].advices[advice]
    const key = userReference + '|' + referenceLevel
    if (pathAdvice.indexOf(key + '|digital') < 0 && pathAdvice.indexOf(key + '|folio') < 0) {
      data.rhvAdvices[goalPath].advices[advice].push(key + '|' + resultType)
    }

    return data
  }

  _addRhvAdviceDataUser(data, user, goal, referenceLevel, advice) {
    const goalPath = goal.contentPath
    const userReference = user.userReference

    if (!data.users.hasOwnProperty(userReference)) {
      data.users[userReference] = {
        'fullName': user.fullName,
        'advices': {}
      }
    }
    if (!data.users[userReference]['advices'].hasOwnProperty(goalPath)) {
      data.users[userReference]['advices'][goalPath] = {}
    }
    if (!data.users[userReference]['advices'][goalPath].hasOwnProperty(referenceLevel)) {
      data.users[userReference]['advices'][goalPath][referenceLevel] = []
    }

    if (data.users[userReference]['advices'][goalPath][referenceLevel].indexOf(advice) < 0) {
      data.users[userReference]['advices'][goalPath][referenceLevel].push(advice)
    }

    return data
  }
}
