<template>
  <notification-message :notification="notification" />
</template>

<script>
  import Notification from './Notification'
  import NotificationMessage from './NotificationMessage'
  export default {
    components: {
      NotificationMessage
    },
    props: {
      message: {
        type: String
      },
    },
    computed: {
      notification() {
        return Notification.success(this.message)
      }
    }
  }
</script>
