<template>
  <div>
    <button class="button button--small button--primary button--cockpit button--cockpit-with-icon-only" @click="showEntryScreen">
      <i class="icon-statistics"></i>
    </button>
    <show-test-results-grid
      :group="group"
      v-if="editableTest !== null"
      v-on:close="editableTest = null"
      :test="test"
      :stream="stream"
      :token="token"
      :store="store"
      :score-categories-data="scoreCategoriesData"
      :modal-links="modalLinks"
    />
  </div>
</template>

<script>
import ShowTestResultsGrid from './ShowTestResultsGrid'
import FolioResultsStore from './folio-results/FolioResultsStore'

export default {
  components: { ShowTestResultsGrid },
  props: {
    group: Object,
    stream: Object,
    token: String,
    test: Object,
    store: FolioResultsStore,
    scoreCategoriesData: Array,
    modalLinks: Array
  },
  data () {
    return {
      editableTest: null
    }
  },
  methods: {
    showEntryScreen () {
      this.editableTest = {
        stream: this.stream,
        users: this.group.members.map(user => user.userReference),
        contentPath: []
      }
    }
  }
}
</script>
